import * as React from "react";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { transactionFormStore } from "@pages/transactions/TransactionForm/TransactionFormStore";
import { TransactionForm } from "@pages/transactions/TransactionForm/TransactionForm";
import { refineCloposPayTransactionFromApi, refineTransactionFromApi } from "@app/actions/transaction-actions";
import { TransactionStatusTag } from "@app/components/misc/TransactionStatusTag";
import { BrandStatus, TransactionStatus } from "@config/enums";

export const clopospayTransactionTableStore = new DataTableStore<ITransaction>({
    dataProvider: new DataProvider({
        url: endpoints.cloposPay,
        sort: ["created_at", -1],
        refiner: refineCloposPayTransactionFromApi,
        with: ["brand"],
    }),
    formStore: () => transactionFormStore,
    form: TransactionForm,
    columnsManager: {
        hiddenColumns: ["id"],
    },
    filters: [
        {
            name: "status",
            type: "dropdown",
            defaultValue: TransactionStatus.SUCCESS,
            values: {
                [TransactionStatus.SUCCESS]: "Success",
                [TransactionStatus.FAILED]: "Failed",
                [TransactionStatus.PENDING]: "Pending",
            },
        },
        {
            name: "brand_name",
            type: "input",
        },
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50,
        },
        {
            title: "Brand ID & Name",
            dataIndex: "brand",
            key: "brand",
            align: "center",
            width: 150,
            render: brand => `${brand?.name} (ID: ${brand?.id})`,
        },
        {
            title: "Venue ID",
            dataIndex: "venue_id",
            key: "venue_id",
            align: "center",
            width: 100,
        },
        {
            title: "Method",
            dataIndex: "method",
            key: "method",
            align: "center",
            width: 100,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 150,
            render: status => <TransactionStatusTag status={status} />,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center",
            width: 100,
        },
        {
            title: "Receipt Total",
            dataIndex: "receipt_total",
            key: "receipt_total",
            align: "center",
            width: 100,
        },
        {
            title: "Tip",
            dataIndex: "tip",
            key: "tip",
            align: "center",
            width: 100,
        },
        {
            title: "Sent to Brand",
            dataIndex: "sent_to_brand_at",
            key: "sent_to_brand_at",
            align: "center",
            width: 150,
        },
        {
            title: "Waiter ID",
            dataIndex: "waiter_id",
            key: "waiter_id",
            align: "center",
            width: 150,
        },
        {
            title: "Sent to Waiter",
            dataIndex: "sent_to_waiter_at",
            key: "sent_to_waiter_at",
            align: "center",
            width: 150,
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 150,
        },
        {
            title: "Updated at",
            dataIndex: "updated_at",
            key: "updated_at",
            align: "center",
            width: 150,
        },
    ],
});
