import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import React from "react";


export const venueTableStore = new DataTableStore<IVenue>({

    dataProvider: new DataProvider({
        url: endpoints.venue,
        with: ["brand"],
    }),
    // filters: [
    //     {
    //         name: "name",
    //         type: "input",

    //     },
    //     {
    //         name: "Brand",
    //         type: "dropdown-search",
    //         endpoint: "brand",
    //     }
    // ],
    columnsManager: {
        hiddenColumns: ["created_at"]
    },
    columns:[
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50
        },
        {
            title: "ID Local",
            dataIndex: "real_venue_id",
            key: "real_venue_id",
            align: "center",
            width: 50
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "left",
            width: 150,
        },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: 150,
            render: (__, venue) => <a onClick={() => brandFormStore.onEditClick(venue.brand!)}>{venue.brand?.name}</a>
        }
    ]
});
