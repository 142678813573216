import * as React from "react";
import {ErrorInfo} from "react";
import { Button } from "antd";

interface IErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    info?: ErrorInfo;
    source: string;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {

    constructor(props: any) {
        super(props);
        this.state = {hasError: false, source: props.source};
    }

    public componentDidCatch(error: Error, info: ErrorInfo): void {
        // Display fallback UI
        this.setState({
            hasError: true, error, info
        });
        console.info({source: this.state.source, error, info});
    }

    public reload = () => {
        location.reload();
    }
    
    public render(): any {
        if (this.state.hasError) {
            if (process.env.NODE_ENV === "development") {
                // You can render any custom fallback UI
                return <div className="alert alert-danger">
                    <h3 className="alert-heading">Something went wrong.</h3>
                    <Button icon="refresh"  onClick={this.reload}>Refresh</Button>
                    <p>{this.state.error && this.state.error.message}</p>
                    <pre>{this.state.error!.toString()}</pre>
                    <pre style={{maxHeight: "50%", overflow: "auto"}}>{this.state.info!.componentStack}</pre>
                </div>;
            } else {
                return <div className="container mt-4 text-center">
                    <div className="alert alert-info">
                        <h3 className="alert-heading">Error</h3>
                        <Button icon="refresh"  onClick={this.reload}>Refresh</Button>
                    </div>
                </div>;
            }
        }
        return this.props.children as JSX.Element;
    }
}
