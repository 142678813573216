import { showUserDrawer, UserDrawer } from "@app/components/layout/header/UserDrawer";
import { endSession, sessionStore } from "@app/stores/SessionStore";
import { Button, Icon, Layout, Menu } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { withRouter } from "react-router-dom";
import "./header.scss";

const LayoutHeader = Layout.Header;

interface IProps {
    setSidebarHidden: any;
    sidebarHidden: boolean;
}

export const Header = observer(withRouter((props: IWithRouterProps & IProps) => {

    const onClick = ({ key }: { key: string; }) => {
        if (key === "/logout") {
            endSession();
        } else if (key === "/user-drawer") {
            showUserDrawer(sessionStore.user);
        }
    };

    return <LayoutHeader className="main-header" {...{ theme: "light" }} >
        <div style={{ lineHeight: "normal", display: "flex", alignItems: "center" }}>
            <Button type="link" icon={props.sidebarHidden ? "menu" : "arrow-left"}
                onClick={() => {
                    props.setSidebarHidden((x: boolean) => !x);
                    localStorage.setItem("sidebarHidden", props.sidebarHidden ? "" : "1");
                }} />
        </div>
        <Menu style={{}} mode="horizontal" onClick={onClick} selectedKeys={[props.match.path]}>
            <Menu.Item key="/version">
                <span>v{process.env.REACT_APP_BUILD_NUMBER}</span>
            </Menu.Item>
            <Menu.Item key="/user-drawer">
                <Icon type="user" />
                <span>{sessionStore.username}</span>
            </Menu.Item>
            <Menu.Item key="/logout">
                <Icon type="logout" />
                <span>Logout</span>
            </Menu.Item>
        </Menu>

        <UserDrawer />
    </LayoutHeader>;
}));

Header.displayName = "Header";
