import { DataTable } from "@app/components/DataTable/DataTable";
import { UserForm } from "@app/pages/users/UserForm/UserForm";
import { observer } from "mobx-react";
import * as React from "react";
import { userTableStore } from "./userTableStore";
import { ColumnsManager } from "@app/components/DataTable/ColumnsManager";

@observer
export class UsersPage extends React.Component {

    public componentDidMount(): void {
        userTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Users</h2>
            <div>
                <DataTable store={userTableStore} bordered />
            </div>
        </div>;
    }
}
