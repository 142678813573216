
export enum BrandStatus {
    PENDING = 1,
    CREATING,
    EXPIRED,
    UPDATE,
    ACTIVE,
    DELETING,
    OUT_OF_SERVICE,
    FREEZE,
    CLOSE,
    DELETE,
}

export enum Discountable {
    Yes = 1,
    No = 0
}

export enum BrandStageStatus {
    DEFAULT,
    TRIAL,
    TEST
}

export enum TransactionStatus {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export enum BrandSaleStatus {
    NONE = "",
    CLOSED = "closed",
    TEMPORARY_CLOSED = "temporary_closed",
    OTHER_SOFTWARE = "other_software",
    SEASONAL = "seasonal",
}
