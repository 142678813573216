import * as React from "react";
import { Drawer, Input } from "antd";
import { DrawerProps } from "antd/lib/drawer";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { observer } from "mobx-react";
import { ErrorBoundary } from "../misc/ErrorBoundary";

interface IFormDrawerProps extends DrawerProps {
    store: FormDrawerStore<any>;
    autofocus?: string;
}

@observer
export class FormDrawer extends React.Component<IFormDrawerProps> {

    get drawerProps(): DrawerProps {
        const { store, ...rest } = this.props;

        return {
            width: 500,
            closable: true,
            placement: "right",
            destroyOnClose: true,
            maskClosable: true,
            visible: store.visible,
            onClose: store.hide,
            title: store.title,
            ...rest as any,
            ...store.formDrawerProps
        };
    }

    public render(): JSX.Element {
        return <ErrorBoundary>
            <Drawer {...this.drawerProps} />
        </ErrorBoundary>;
    }
}
