import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { tableStore } from "../page/tableStore";
import { observable } from "mobx";

const dummyData = (d ?: IDbConfig): IDbConfig => ({
    id: undefined,
    name: "",
    username: "",
    password: "",
    host: "",
    port: "",
    read_host: "",
    ...d
});

export class FormStore extends FormDrawerStore<IDbConfig> {
    @observable public _data = dummyData();
    public autofocus = "name";
    public dummyDataFn = dummyData;
    public apiUrl = endpoints.databases;

    get tableStore(): DataTableStore {
        return tableStore;
    }

    set tableStore(s: DataTableStore) {

    }

    public beforeShow = async (): Promise<void> => {};

    public afterSave = (d: IDbConfig) => {
        if (this.tableStore) {
            this.tableStore.dataProvider.updateLocalCopyOfData(d);
        }
    };
}

export const dbConfigFormStore = new FormStore;
