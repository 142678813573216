import { ajax } from "@app/lib/ajax";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import moment from "moment";
import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { PUBLIC_API_URL } from "@app/config/main";

if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
}

const baseOptions: Highcharts.Options = {
    title: {
        text: "Expired Brands by Month",
    },
    xAxis: {
        type: "category",
        margin: 20,
    },
    yAxis: {
        title: {
            text: "",
        },
    },
    chart: {
        zoomType: "x",
        type: "column",
    },
    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
};

export const ExpiredBrandsChart2: FC = props => {
    const [data, setData] = useState([] as any[]);

    useEffect(() => {
        ajax.get({ url: `${PUBLIC_API_URL}/admin/stats/monthly-active` })
            .then(r => setData(r.data))
            .catch(e => console.log(e));
    }, []);

    const ref = React.useRef<{ chart: Highcharts.Chart; container: any }>(null);
    // React.useEffect(() => {
    //     ref.current?.chart.reflow();
    // });

    const options: Highcharts.Options = {
        ...baseOptions,
        tooltip: {
            formatter(): string {
                return `<div>
                    ${this.point.series.name} | <strong>${this.y}</strong><br/>
                    ${this.point.name} ${this.point.options.custom ? this.point.options.custom ?.join(", ") : ""}
                </div>`;
            },
        },
        series: [
            {
                type: "line",
                name: "Effective Newcomers",
                data: data.map(d => ({ name: d._id, y: d.newBrands.length - d.stoppedBrands.length })),
                color: "violet",
                zIndex: 2,
            },
            {
                type: "line",
                name: "Newcomers",
                data: data.map(d => ({ name: d._id, y: d.newBrands.length })),
                color: "green",
                zIndex: 3,
            },
            {
                type: "column",
                name: "Left brands",
                data: data.map(d => ({ name: d._id, y: d.stoppedBrands.length, custom: d.stoppedBrands})),
            },
        ],
    };

    return (
        <div style={{ flexBasis: "30%" }} className="widget">
            <HighchartsReact highcharts={Highcharts} options={options} ref={ref} />
        </div>
    );
};
