import { API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import { Chart } from "@app/pages/dashboard-new/widgets/Chart";
import moment from "antd/node_modules/moment";
import _ from "lodash";
import { observer, useLocalStore } from "mobx-react";
import * as React from "react";
import { refineSubscription } from "../utils";

export const PaymentsChart: React.FC = observer(() => {
  
    const s = useLocalStore(() => ({
        subscriptions: [] as ITotalData[],
        async loadSubscriptionTotals(): Promise<void> {
            const start = moment().subtract(1, "year").format("YYYY-MM");
            const end = moment().format("YYYY-MM");
            const resp = await ajax.get({ url: `${API_URL}transaction/monthly?date[]=${start}&date[]=${end}` });
            s.subscriptions = _.sortBy(refineSubscription(resp), "date");
        },
        getMonthlySubData(): Highcharts.SeriesLineOptions["data"] {
            return s.subscriptions.map((d: any) => ({
                x: Date.parse(moment(d.date).format("YYYY-MM-DD")),
                y: d.count,
                custom: d.list,
                unique_brand_count: d?.unique_brand_count
            }));
        },
        getMonthlyPayerData(): Highcharts.SeriesLineOptions["data"] {
            return s.subscriptions.map((d: any) => ({
                x: Date.parse(moment(d.date).format("YYYY-MM-DD")),
                y: d.unique_brand_count,
                custom: d.list,
                unique_brand_count: d?.unique_brand_count
            }));
        },
    }));

    React.useEffect(() => {
        s.loadSubscriptionTotals();
    }, []);

    return <Chart
        title="Monthly subscription payments"
        extraOptions={{
            yAxis: [{
                title: {
                    text: ""
                },
            }, {
                title: {
                    text: ""
                },
                opposite: true,
            }],
            tooltip: {
                formatter(): string {
                    return `<div>
                                ${moment(this.x).format("LL")} | <strong>${this.y}</strong><br/>
<strong>Unique brand count:</strong> ${(this.point.options as any).unique_brand_count}<br/>
                                ${this.point.options.custom?.join(", ")}
                            </div>`;
                }
            },
        }}
        series={[{ type: "line", data: s.getMonthlySubData() },
        { type: "line", data: s.getMonthlyPayerData(), yAxis: 1, }]}
        isMonthly={true}
    />;
});
