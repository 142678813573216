import { dummyClient, refineClientFromApi } from "@app/actions/client-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { clientTableStore } from "../ClientsPage/clientTableStore";
import { observable } from "mobx";
import { ajax } from "@app/lib/ajax";

export class ClientFormStore extends FormDrawerStore<IClient> {
    public autofocus = "first_name";
    public dataName = "client";
    public dummyDataFn = dummyClient;
    public apiUrl = endpoints.client;
    @observable public partners: IPartner[] = [];
    @observable public _data = dummyClient();
    public afterSaveCallback?: (d: IClient) => void;

    get tableStore(): DataTableStore {
        return clientTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public beforeShow = async (): Promise<void> => {
        await this.loadPartners();
    };

    public async loadPartners(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.partners,
            params: {
                sort: ["id", "=", -1]
            }
        });
        this.partners = resp.data;
    }

    public afterSave = (d: IClient) => {
        d = refineClientFromApi(d);
        clientTableStore.dataProvider.updateLocalCopyOfData(d);
        if (this.afterSaveCallback) {
            this.afterSaveCallback(d);
            this.afterSaveCallback = undefined;
        }
    }
}

export const clientFormStore = new ClientFormStore;
