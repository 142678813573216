import { ColumnProps } from "antd/lib/table";

export const receipt: Array<ColumnProps<any>> = [
    { title: "ID", key: "id", dataIndex: "id" },
    {
        title: "Needs Sync", key: "needsSync", dataIndex: "needsSync", filterMultiple: false,
        filters: [{ text: "0", value: "0" }, { text: "1", value: "1" }],
        onFilter: (value, record) => record.needsSync === Number(value),
    },
    { title: "By card", key: "by_card", dataIndex: "by_card" },
    { title: "By cash", key: "by_cash", dataIndex: "by_cash" },
    { title: "User", key: "user_name", dataIndex: "user_name" },
    { title: "Table", key: "table_name", dataIndex: "table_name" },
    { title: "Hall", key: "hall_name", dataIndex: "hall_name" },
    { title: "Customer", key: "customer_name", dataIndex: "customer_name" },
    { title: "Guests", key: "guests", dataIndex: "guests" },
    { title: "Status", key: "status", dataIndex: "status" },
    { title: "Total", key: "total", dataIndex: "total" },
    { title: "Subtotal", key: "subtotal", dataIndex: "subtotal" },
    { title: "Printed", key: "printed", dataIndex: "printed" },
    { title: "Desc", key: "description", dataIndex: "description" },
    {
        title: "Closed", key: "closed_at", dataIndex: "closed_at", filterMultiple: false,
        filters: [{ text: "Closed", value: "closed" }, { text: "Open", value: "open" }],
        onFilter: (val, record) => !!record.closed_at === (val === "closed")
    },
    { title: "Service", key: "service_charge", dataIndex: "service_charge" },
    { title: "Discount Type", key: "discount_type", dataIndex: "discount_type" },
    { title: "Created", key: "created_at", dataIndex: "created_at" },
    { title: "Remaining", key: "remaining", dataIndex: "remaining" },
    { title: "Discount", key: "discount_value", dataIndex: "discount_value" },
    { title: "Discount %", key: "discount_rate", dataIndex: "discount_rate" },
    { title: "CID", key: "cid", dataIndex: "cid" },
    { title: "On Server", key: "isOnServer", dataIndex: "isOnServer" },
];

export const customer: Array<ColumnProps<any>> = [
    { title: "ID", key: "id", dataIndex: "id" },
    { title: "CID", key: "cid", dataIndex: "cid" },
    { title: "Needs Sync", key: "needsSync", dataIndex: "needsSync" },
    { title: "Name", key: "name", dataIndex: "name" },
    { title: "Discount", key: "discount", dataIndex: "discount" },
    { title: "Created", key: "created_at", dataIndex: "created_at" },
    { title: "On Server", key: "isOnServer", dataIndex: "isOnServer" },
];
export const rpColumns: Array<ColumnProps<any>> = [
    { title: "ID", key: "id", dataIndex: "id" },
    // {title: "product_id", key: "product_id", dataIndex: "product_id"},
    // {title: "receipt_id", key: "receipt_id", dataIndex: "receipt_id"},
    { title: "Needs Sync", key: "needsSync", dataIndex: "needsSync" },
    { title: "name", key: "name", dataIndex: "name" },
    { title: "count", key: "count", dataIndex: "count" },
    { title: "price", key: "price", dataIndex: "price" },
    { title: "total", key: "total", dataIndex: "total" },
    { title: "CID", key: "cid", dataIndex: "cid" },
    { title: "On Server", key: "isOnServer", dataIndex: "isOnServer" },
];
export const columns = { receipt, customer, rpColumns };
