import { PUBLIC_API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import { Chart } from "@app/pages/dashboard-new/widgets/Chart";
import moment from "antd/node_modules/moment";
import _, { xor } from "lodash";
import * as React from "react";

interface IMonthlyData {
    _id: string;
    brands: string[];
    venues: string[];
    brandsCount: number;
    venuesCount: number;
}

export const DailyOnlineBrandsChart: React.FC = () => {
    const [data, setData] = React.useState<IMonthlyData[]>([]);
    // const data = getDailyData(totals) as any[];

    React.useEffect(() => {
        ajax.get({ url: `${PUBLIC_API_URL}/admin/stats/monthly-active` }).then(r => {
            setData(r.data);
        });
    }, []);

    return (
        <Chart
            title="Monthly active brands"
            series={[
                {
                    color: "green",
                    type: "spline",
                    name: "Brands",
                    data: data?.map(d => ({ name: d._id, y: d.brandsCount })),
                },
                {
                    type: "spline",
                    name: "Venues",
                    data: data?.map(d => ({ name: d._id, y: d.venuesCount })),
                },
            ]}
            extraOptions={{
                plotOptions: {
                    spline: {
                        marker: {
                            enabled: false,
                        },
                    },
                },
                tooltip: {
                    formatter() {
                        return `${this.point.name} | ${this.series.name} <strong>${this.y}</strong>`;
                    },
                },
                xAxis: {
                    type: "category",
                    margin: 20,
                },
            }}
        />
    );
};

function getDailyData(totals: ITotalData[]): Highcharts.SeriesLineOptions["data"] {
    return Object.values(
        totals.reduce((s, c) => {
            const date = moment(c.date).format("YYYY-MM");
            if (s[date]) {
                s[date].max = _.max([s[date].max, c.count]);
                c.list.forEach(name => s[date].list.add(name));
                s[date].total = s[date].list.size;
            } else {
                s[date] = {
                    name: date,
                    max: c.count,
                    list: new Set(c.list),
                    total: c.list.length,
                };
            }
            return s;
        }, {} as any),
    );
}
