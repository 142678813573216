export enum ErrorCode {
    RECORD_NOT_EXISTS = 1001,
    DUPLICATE_ENTRY = 1003,
}

export enum ErrorType {
    IS_OFFLINE = "is_offline",
    SERVER_SIDE = "server_side",
    AUTH = "auth",
    VALIDATION = "validation",
}
