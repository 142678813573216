import { dummyPackage } from "@app/actions/package-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { observable } from "mobx";
import { packageTableStore } from "../PackagesPage/packageTableStore";

export class PackageFormStore extends FormDrawerStore<IPackage> {
    public autofocus = "first_name";
    public dataName = "package";
    public dummyDataFn = dummyPackage;
    public apiUrl = endpoints.package;
    @observable public _data = dummyPackage();
    public afterSaveCallback?: (d: IPackage) => void;

    get tableStore(): DataTableStore {
        return packageTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public afterSave = (d: IPackage) => {
        packageTableStore.dataProvider.updateLocalCopyOfData(d);
    }
}

export const packageFormStore = new PackageFormStore;
