import * as React from "react";
import { Layout, Modal, Form, Input, Icon, Button } from "antd";
import { observer } from "mobx-react";
import { attemptLogin, sessionStore } from "@app/stores/SessionStore";
import { FormEvent } from "react";

const { Header, Content, Footer, Sider } = Layout;

export const LoginLayout = observer(() => {

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        attemptLogin();
    };

    return <Layout style={{ height: "100%" }} >
        <Header></Header>
        <Content>
            <Modal visible={true}
                // cancelButtonDisabled={false}
                cancelButtonProps={{ disabled: true }}
                okButtonProps={{ loading: sessionStore.loading, htmlType: "submit" }}
                onOk={attemptLogin}
                title="Login" closable={false} okText="Login" >
                <Form onSubmit={onSubmit}>
                    <Form.Item>
                        <Input
                            placeholder="Enter username"
                            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                            value={sessionStore.payload.username}
                            onChange={e => sessionStore.payload.username = e.currentTarget.value}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            type="password" placeholder="Enter password"
                            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                            value={sessionStore.payload.password}
                            onChange={e => sessionStore.payload.password = e.currentTarget.value}
                        />
                    </Form.Item>
                    <Button htmlType="submit" style={{ display: "none" }} />
                </Form>
            </Modal>
        </Content>
        <Footer></Footer>
    </Layout>;
});

LoginLayout.displayName = "LoginLayout";
