import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { Button, Icon, Modal, Upload } from "antd";
import * as React from "react";
import { observer } from "mobx-react";

export const ImportMenuXLS = observer((): JSX.Element | null => {
    if (!brandFormStore.visibleModalSecondary) {
        return null;
    }

    return <Modal
        title={`Import Excel Menu for ${brandFormStore.data.title}`}
        okText="Import"
        visible={brandFormStore.visibleModalSecondary}
        onOk={brandFormStore.onMenuXLSSave}
        onCancel={brandFormStore.hideModalSecondary.bind(null)}
        okButtonProps={{
            loading: brandFormStore.loading
        }}
        cancelButtonProps={{
            disabled: brandFormStore.loading
        }}
        maskClosable={false}
        bodyStyle={{ maxHeight: 450 }}>
        <Upload
            beforeUpload={file => {
                brandFormStore.onExcelMenuChange(file);
                return false;
            }}
        >
            <Button>
                <Icon type="select" /> Select
            </Button>
        </Upload>
    </Modal>;
});
