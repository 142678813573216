import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { packageTableStore } from "./packageTableStore";
import { Select } from "antd";
import countries from "@app/data/countries";
@observer
export class PackagesPage extends React.Component {

    public componentDidMount(): void {
        packageTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {

        const filteredCountries = countries.filter(
            (country) => ["AZ",'SA','RU','GE','TR','UZ'].includes(country.value)
        );

        const countrySelectFilterElement = <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select country"
            optionFilterProp="children"
            defaultValue={"AZ"}
            onChange={(value:string) => {
                packageTableStore.dataProvider.loading = true;
                packageTableStore.extraData["country"] =  value;
                packageTableStore.dataProvider.loading = false;
            }}>
            <Select.Option key={"default-country-filter"} value="">No Filter</Select.Option>
            {filteredCountries.map((country) => <Select.Option key={country.value} value={country.value}>{country.name}</Select.Option>)}
        </Select>;

        return <div>
            <h2>Packages</h2>
            <div>
                <DataTable
                    extraHeaderElements={countrySelectFilterElement}
                    store={packageTableStore}
                    bordered
                />
            </div>
        </div>;
    }
}
