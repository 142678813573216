import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { tableStore } from "../page/tableStore";
import { observable } from "mobx";

const dummyData = (d ?: ITag): ITag => ({
    id: undefined,
    name: "",
    description: "",
    type: "brand",
    ...d
});

export class FormStore extends FormDrawerStore<ITag> {
    @observable public _data = dummyData();
    public autofocus = "name";
    public dummyDataFn = dummyData;
    public apiUrl = endpoints.tags;

    get tableStore(): DataTableStore {
        return tableStore;
    }

    set tableStore(s: DataTableStore) {

    }

    public beforeShow = async (): Promise<void> => {};

    public afterSave = (d: ITag) => {
        if (this.tableStore) {
            this.tableStore.dataProvider.updateLocalCopyOfData(d);
        }
    };
}

export const tagFormStore = new FormStore;
