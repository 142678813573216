import { brandTableStore } from "@app/pages/brands/BrandsPage/brandTableStore";
import { LoginButton } from "@app/pages/brands/BrandsPage/LoginButton";
import { Button, Icon, Tooltip } from "antd";
import moment from "antd/node_modules/moment";
import { isEmpty } from "lodash";
import { partial } from "ramda";
import React from "react";
import { LoadingCenter } from "./misc/LoadingCenter";
import { clientFormStore } from "@app/pages/clients/ClientForm/ClientFormStore";

interface IProps {
    title: string;
    titleStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    data: IBrand[];
}

export const SmallBrandsTable: React.FC<IProps> = ({ title, data, ...props }) => {
    return (
        <div className="widget">
            <div className="widget-title" style={props.titleStyle}>
                {title}
            </div>
            <div className="widget-body">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "right" }}>#</th>
                            <th>Brand</th>
                            {/* <th>Seller</th> */}
                            <th>Client</th>
                            <th>Expired</th>
                            {/* <th style={{ textAlign: "right" }}>Monthly</th> */}
                            <th style={{ textAlign: "right" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(brand => {
                            const clientName = `${brand.client?.last_name} ${brand.client?.first_name}`;
                            const maxLength = 10;
                            const clientNameShort = clientName.substring(0, maxLength);
                            return (
                                <tr key={brand.id}>
                                    <td style={{ textAlign: "right" }}>{brand.id}</td>
                                    <td>
                                        <Button
                                            size="small"
                                            icon="edit"
                                            onClick={partial(brandTableStore.onEditClick, [brand])}
                                        />
                                        <LoginButton id={brand.id!} stage={brand.stage} link>
                                            {brand.name}
                                        </LoginButton>

                                        {brand.left_reason_note && (
                                            <Tooltip title={brand.left_reason_note}>
                                                <Icon type="question-circle" style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                    </td>
                                    {/* <td>{SellersList.find(s => s.id === brand.seller_id)?.name}</td> */}
                                    <td>
                                        <a
                                            onClick={() => clientFormStore.onEditClick(brand.client!)}
                                            title={clientName}
                                        >
                                            {clientNameShort}
                                            {clientName.length > maxLength ? ".." : ""}
                                        </a>
                                    </td>
                                    <td>{moment(brand.subscription?.expired_at).fromNow()}</td>
                                    {/* <td style={{ textAlign: "right" }}>{brand.subscription?.period_price} ₼</td> */}
                                    <td style={{ textAlign: "right" }}>{brand.total_amount} ₼</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {isEmpty(data) ? <LoadingCenter /> : null}
            </div>
        </div>
    );
};
