import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { BrandStatusTag } from "@app/components/misc/BrandStatusTag";
import { endpoints } from "@app/config/endpoints";
import * as React from "react";
import { deleteButton, editButton, importButton } from "@app/components/DataTable/actions";
import { brandFormStore } from "../BrandForm/BrandFormStore";
import { BrandForm } from "../BrandForm/BrandForm";
import { getPackage } from "@app/actions/brand-actions";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { LoginButton } from "./LoginButton";
import moment from "moment";
import { Tag } from "antd";
import { BrandStage, BrandStageStatusOptions, BrandType } from "@app/data/brand-stages";
import { getSellerList } from "@app/lib/utils";
import { BrandSaleStatus, BrandStageStatus, BrandStatus } from "@app/config/enums";
import _ from "lodash";
import { clientFormStore } from "@app/pages/clients/ClientForm/ClientFormStore";
import countries from "@app/data/countries";
import { add, addDays, addMonths, format } from "date-fns";

const filteredCountries = countries.filter(country =>
    ["AZ", "SA", "RU", "GE", "TR", "UZ", "KZ"].includes(country.value),
);

export const brandTableStore = new DataTableStore<IBrand>({
    dataProvider: new DataProvider({
        url: endpoints.brand,
        with: ["client", "subscription.packages", "partner", "reports", "tags"],
        refiner: (d: any) => {
            const res = d;
            res.type = d.type === 1 || d.type === BrandType.RESTAURANT ? BrandType.RESTAURANT : BrandType.SHOP;
            return res;
        },
        filters: [
            {
                name: "withCount",
                filter: ["withCount", "venues"],
            },
        ],
        sort: ["id", "-1"],
    }),
    // refine: refineUserFromApi,
    formStore: () => brandFormStore,
    form: BrandForm,
    columnsManager: {
        hiddenColumns: ["created_at"],
    },
    sortOptions: {
        placeholder: "Sort",
        values: {
            "reports.last_month_amount": "Amount",
            "reports.page_view_count": "Scan",
            "subscription.expired_at": "Expired",
        },
    },
    filters: [
        {
            name: "status",
            type: "dropdown",
            values: {
                [BrandStatus.ACTIVE]: "Active",
                [BrandStatus.FREEZE]: "Freeze",
                [BrandStatus.EXPIRED]: "Expired",
                [BrandStatus.CLOSE]: "Close",
                [BrandStatus.PENDING]: "Pending",
                [BrandStatus.CREATING]: "Creating",
                [BrandStatus.DELETE]: "Deleted",
                [BrandStatus.DELETING]: "Deleting",
                [BrandStatus.OUT_OF_SERVICE]: "Out of Service",
                [BrandStatus.UPDATE]: "Update",
            },
            defaultValue: BrandStatus.ACTIVE,
        },
        {
            name: "stage",
            label: "Stage",
            type: "dropdown",
            values: {
                [BrandStage.ALPHA]: "Alpha",
                [BrandStage.BETA]: "Beta",
                [BrandStage.RC]: "RC",
                [BrandStage.PROD]: "Prod",
            },
            defaultValue: "___",
        },
        {
            name: "stage_status",
            label: "Stage Status",
            type: "dropdown",
            values: {
                [BrandStageStatus.DEFAULT]: "Default",
                [BrandStageStatus.TRIAL]: "Trail",
                [BrandStageStatus.TEST]: "Test",
            },
            defaultValue: "___",
        },
        {
            name: "seller_id",
            label: "Seller",
            type: "dropdown",
            values: () => _.mapValues(_.keyBy(getSellerList(), "id"), "name"),
            defaultValue: "___",
        },
        {
            name: "country",
            label: "Country",
            type: "dropdown",
            values: () => _.mapValues(_.keyBy(filteredCountries, "value"), "name"),
            defaultValue: "___",
        },
        {
            name: "db_config_id",
            label: "Database",
            type: "dropdown-multi",
            placeholder: "Database",
            endpoint: "dbConfigs",
            searchable: false,
            defaultValue: "___",
        },
        {
            name: "package_id",
            searchable: false,
            label: "Package",
            type: "dropdown-multi",
            placeholder: "Package",
            endpoint: "package",
            defaultValue: "___",
        },
        {
            name: "module_id",
            searchable: false,
            label: "Module",
            type: "dropdown-multi",
            placeholder: "Module",
            endpoint: "module",
            defaultValue: "___",
        },
        {
            name: "partner_id",
            label: "Partners",
            type: "dropdown-search-key-value",
            placeholder: "Search by partner",
            endpoint: "partners",
            defaultValue: "___",
        },
        {
            name: "email",
            label: "E-mail",
            type: "dropdown-search",
            endpoint: "client",
            values: {},
            defaultValue: "___",
        },
        {
            name: "tags",
            label: "Tags",
            type: "dropdown-multi",
            searchable: false,
            placeholder: "Tags",
            endpoint: "tags",
            defaultValue: "___",
        },
        {
            name: "sales_status",
            label: "Sale Status",
            type: "dropdown",
            values: _.omitBy(
                _.transform(BrandSaleStatus, (result, value, key) => {
                    result[value] = value
                        ? value
                              .split("_")
                              .map((s: string) => s[0]?.toUpperCase() + s.slice(1))
                              ?.join(" ")
                        : "";
                }),
                (value, key) => !value,
            ),
            defaultValue: "___",
        },
        {
            name: "expired_at",
            label: "Expire after",
            type: "dropdown",
            operand: ">",
            defaultValue: "___",
            values: () => {
                const date = (m: number) => {
                    const date = new Date();
                    date.setMonth(date.getMonth() + m);
                    return format(date, "yyyy-MM-dd");
                };

                return {
                    [date(1)]: "1 month",
                    [date(3)]: "3 months",
                    [date(6)]: "6 months",
                    [date(12)]: "12 months",
                };
            },
        },
        {
            name: "name",
            type: "input",
        },
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "left",
            width: 150,
            render: (name, brand: IBrand) => (
                <div>
                    <div>{name}</div>
                    <div>{brand.title}</div>
                </div>
            ),
        },
        {
            title: "Reports",
            dataIndex: "reports",
            key: "reports",
            width: 150,
            render: (reports: IBrandExtra[], brand: IBrand) => {
                const r = reports[0];
                if (!r) {
                    return null;
                }
                return (
                    <div>
                        {r.amount} {r.currency} / {r.last_month_amount} {r?.currency} <br />
                        {r.receipt_count} / {r.last_month_receipt_count} | Scan {r.page_view_count} <br />
                    </div>
                );
            },
        },
        {
            title: "Total paid",
            dataIndex: "total_amount",
            key: "total_amount",
            align: "right",
            width: 100,
            render: (__, brand) => (
                <div>
                    <div>
                        {brand.total_amount} {brand?.subscription?.currency}
                    </div>
                    {moment().diff(brand.created_at, "months")} months
                </div>
            ),
        },
        {
            title: "Stage",
            dataIndex: "stage",
            key: "stage",
            align: "center",
            width: 100,
        },
        {
            title: "Package",
            dataIndex: "subscription",
            key: "package",
            align: "center",
            width: 100,
            render: (__, brand) => {
                const p = getPackage(brand);
                return (
                    <div>
                        <div>
                            {p.title} {brand.subscription!.trial ? "(trial)" : ""}
                        </div>
                        <div>{brand.subscription!.period_price + " " + brand?.subscription?.currency}</div>
                    </div>
                );
            },
        },
        // {
        //     title: "Price",
        //     dataIndex: "subscription",
        //     key: "price",
        //     align: "center",
        //     width: 100,
        //     render: (__, brand) => {
        //         const country = CountriesAll.find(cA => cA.value === brand.country);
        //         return brand.subscription!.period_price + " " + country?.currency;
        //     }
        // },
        {
            title: "Expire",
            dataIndex: "subscription.expired_at",
            key: "expired_at",
            align: "center",
            width: 130,
            render: (d, b) => {
                const m = moment(d);
                const diff = m.diff(new Date(), "day");

                return <Tag color={diff < 0 ? "red" : diff < 3 ? "orange" : "green"}>{m.fromNow()}</Tag>;
            },
        },
        {
            title: "Period",
            dataIndex: "subscription",
            key: "period",
            align: "center",
            width: 100,
            render: (__, brand) => {
                return brand?.subscription!?.monthly_period + " months";
            },
        },
        {
            title: "Client",
            dataIndex: "client",
            key: "client",
            align: "left",
            width: 150,
            render: (client: IClient) => (
                <div>
                    <div>
                        <a onClick={() => clientFormStore.onEditClick(client)}>
                            {client.first_name} {client.last_name}
                        </a>
                    </div>
                    <div>
                        <a target="popup" href={`mailto:${client.email}`}>
                            {client.email}
                        </a>
                    </div>
                </div>
            ),
        },
        {
            title: "Partner",
            dataIndex: "partner.name",
            key: "partner_id",
            align: "center",
            width: 100,
        },
        {
            title: "Venues",
            dataIndex: "venues_count",
            key: "venues_count",
            align: "center",
            width: 100,
        },
        {
            title: "Seller",
            dataIndex: "seller_id",
            key: "seller_id",
            align: "center",
            width: 100,
            render: d => {
                const seller = _.find(getSellerList(), sL => sL.id === d);
                return seller?.name || "-";
            },
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            align: "left",
            width: 100,
        },
        {
            title: "Leaving reason",
            dataIndex: "left_reason_note",
            key: "left_reason_note",
            align: "left",
            width: 100,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 100,
            render: (__, brand) => (
                <div>
                    <BrandStatusTag status={brand.status} /> <br />
                    <Tag style={{ marginTop: 3 }}>{BrandStageStatusOptions[brand.stage_status]}</Tag>
                </div>
            ),
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            align: "center",
            width: 100,
        },
        {
            title: "Last Manager Login",
            dataIndex: "custom_attributes.last_manager_login",
            key: "custom_attributes.last_manager_login",
            align: "center",
            width: 100,
            render: d => {
                return !d ? "-" : moment(d).fromNow();
            },
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 100,
            render: str => {
                const parts = str.split(" ");
                return (
                    <div>
                        {parts[0]} <br /> {parts[1]}
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions,
        },
    ],
});

const editBtn = editButton(brandTableStore);
export const deleteBtn = deleteButton(brandTableStore);
export const importMenuBtn = importButton(brandTableStore);

function actions(data: IBrand): JSX.Element {
    return (
        <>
            {editBtn(data)}
            <LoginButton id={data.id!} stage={data.stage} />
        </>
    );
}
