import { attempt } from "@app/lib/utils";
import { ajax } from "@app/lib/ajax";
import { DEFAULT_DEVICE } from "@app/data/devices";

export function dummyTerminal(terminal?: ITerminal): ITerminal {
    return {
        name: "",
        device_id: "",
        password: "",
        device_type: DEFAULT_DEVICE,
        ...terminal,
    } as ITerminal;
}

type TerminalEvent = "refresh" | "vorlon" | "clear-cache" | "switch-stage" | "remote-script" | "send-msg";

export async function sendAction(term: ITerminal, event: TerminalEvent, payload: any = ""): Promise<void> {
    await attempt(async () => {
        await ajax.post({
            url: "/device/push",
            data: {
                event,
                payload,
                deviceId: term.deviceId || term.device_id,
                brand: term.brand,
            },
        });
    });
}
