import { DataTable } from "@app/components/DataTable/DataTable";

import { observer } from "mobx-react";
import * as React from "react";
import { tableStore } from "./tableStore";

@observer
export class TagsPage extends React.Component {

    public async componentDidMount(){
        await tableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Tags</h2>
            <div>
                <DataTable store={tableStore} bordered pagination={false}/>
            </div>
        </div>;
    }
}
