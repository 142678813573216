import React, { FC } from "react";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { LoadingCenter } from "@app/components/misc/LoadingCenter";
import { DataPair } from "@app/components/misc/DataPair";
import { observer } from "mobx-react";

export const BrandFormInfoTab: FC = observer(() => {
    const brandExtra = brandFormStore.brandExtra;
    return brandExtra?.brand_id !== brandFormStore._data.id ? (
        <LoadingCenter />
    ) : (
        <>
            <DataPair name="Turnover (last day)" value={brandExtra?.amount + " " + brandExtra?.currency} />
            <DataPair name="Turnover (last month)" value={brandExtra?.last_month_amount + " " + brandExtra?.currency} />
            <DataPair name="Last receipt date" value={brandExtra?.last_receipt_date} />
            <DataPair name="QR menu scans" value={brandExtra?.page_view_count} />
            <DataPair name="Receipt count (last day)" value={brandExtra?.receipt_count} />
            <DataPair name="Receipt count (last month)" value={brandExtra?.last_month_receipt_count} />
        </>
    );
});
