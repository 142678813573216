import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTable } from "@app/components/DataTable/DataTable";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import * as React from "react";
import { Button, Tooltip } from "antd";
import { observable } from "mobx";
import { BrandStage } from "@app/data/brand-stages";
import ReactJson from "react-json-view";
import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";

export interface IBrandDataHistory {
    _id: string
    brand: string
    venue_id: number
    stage: string
    app: string
    data_id: number
    type: string
    user_id: number
    event: string
    operation_at: string
    data: {
        id: number
        system_type: string
        channel: string
        status: {
            "1": number
        }
        name: string
        payment_method_id: any
        service_charge_rate: number
        position: number
        created_at: string
        updated_at: string
        deleted_at: any
        media: Array<any>
    }
    after: {
        service_charge_rate: number
        updated_at: string
    }
    before: {
        service_charge_rate: any
        updated_at: string
    }
    meta: Array<any>
    created_at: string
}

@observer
export class BrandDataHistoryPage extends React.Component {
    @observable public searchVal = "";

    public tableStore = new DataTableStore<IBrandDataHistory>({
        dataProvider: new DataProvider({
            url: "https://data-version-api.clopos.com/logs",
        }),
        filters: [
            {
                label: "Event",
                name: "event",
                type: "dropdown",
                values: {
                    "update": "Update",
                    "create": "Create",
                    "delete": "Delete",
                    "restore": "Restore",
                    "forceDelete": "Force Delete",
                },
                defaultValue: "___",
                // isLocal: true,
            },
            {
                label: "Stage",
                name: "stage",
                type: "dropdown",
                values: {
                    [BrandStage.ALPHA]: "Alpha",
                    [BrandStage.BETA]: "Beta",
                    [BrandStage.RC]: "RC",
                    [BrandStage.PROD]: "Prod",
                },
                defaultValue: "___",
                // isLocal: true,
            },
            {
                label: "Type",
                name: "type",
                type: "dropdown",
                defaultValue: "___",
                values: {
                    "App\\Models\\Client\\Finance\\SaleType": "SaleType",
                    "App\\Models\\Client\\Inventory\\Operation": "Operation",
                    "App\\Models\\Client\\Inventory\\Supplier": "Supplier",
                    "App\\Models\\Auth\\Terminal": "Terminal",
                    "App\\Models\\Client\\Product\\Dish": "Dish",
                    "App\\Models\\Client\\Product\\Goods": "Goods",
                    "App\\Models\\Client\\Product\\Ingredient": "Ingredient",
                    "App\\Models\\Client\\Product\\Preparation": "Preparation",
                    "App\\Models\\Client\\Product\\Modification": "Modification",
                    "App\\Models\\Client\\Product\\Product": "Product",
                    "App\\Models\\Client\\Product\\ServiceCharge": "Service",
                    "App\\Models\\Main\\Integration": "Integration",
                    "App\\Models\\Main\\Setting": "User",
                },
            },
            {
                name: "venue_id",
                type: "input",
                label: "Venue ID",
                placeholder: "Venue ID",
            },
            {
                name: "user_id",
                type: "input",
                label: "User ID",
            },
            {
                name: "data_id",
                type: "input",
                label: "Data ID",
                placeholder: "Data ID",
            },
            {
                label: "Brand",
                name: "brand",
                type: "input",
            },
        ],
        columnsManager: {
            hiddenColumns: [],
        },
        columns: [
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                align: "left",
                width: 150,
            },
            {
                title: "Venue",
                dataIndex: "venue_id",
                key: "venue_id",
                align: "left",
                width: 100,
            },
            {
                title: "Data ID",
                dataIndex: "data_id",
                key: "data_id",
                align: "left",
                width: 100,
            },
            {
                title: "Operated at",
                dataIndex: "operation_at",
                key: "operation_at",
                align: "center",
                width: 150,
            },
            {
                title: "Stage",
                dataIndex: "stage",
                key: "stage",
                align: "center",
                width: 100,
            },
            {
                title: "Event",
                dataIndex: "event",
                key: "event",
                align: "center",
                width: 100,
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                align: "center",
                width: 100,
                render: (type: string) => {
                    return (
                        <Tooltip title={type}>
                            {type.split("\\").pop()}
                        </Tooltip>
                    );
                }
            },
            {
                title: "Data",
                key: "data",
                render: (data) => {
                    return (
                        <ReactJson
                            displayObjectSize={false}
                            collapsed={true}
                            src={data}
                            displayDataTypes={false}
                            defaultValue={{}}
                        />
                    );
                }
            },
            // {
            //     title: "Actions",
            //     key: "actions",
            //     width: 300,
            //     render: (data: IBrandDataHistory) => {
            //         return (
            //             <div className="btns-column">
            //
            //             </div>
            //         );
            //     },
            // },
        ],
    });

    public componentDidMount(): void {
        this.tableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return (
            <div>
                <h2>
                    Brand data history
                </h2>
                <div>
                    <DataTable
                        store={this.tableStore}
                        bordered
                        rowKey="_id"
                    />
                </div>
            </div>
        );
    }
}
