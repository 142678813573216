import * as React from "react";
import { observer } from "mobx-react";
import { Drawer } from "antd";
import { observable, action } from "mobx";
import ReactJson from "react-json-view";
import { sessionStore } from "@app/stores/SessionStore";

const state = observable({
    visible: false,
    user: {} as IUser
});

export const showUserDrawer = action((user: IUser): void => {
    state.user = user;
    state.visible = true;
});

export const UserDrawer = observer(() => {

    return <Drawer
        title="User info"
        width={500}
        placement="right"
        closable={false}
        onClose={() => state.visible = false}
        visible={state.visible} >

        {state.user && <>
            <h2>{state.user.username}</h2>

            {/* <p>{state.user.token}</p> */}
            {/* <ReactJson src={sessionStore.user} /> */}
            <p>
                <strong>ID: </strong>
                {state.user.id}
            </p>
            <p>
                <strong>Username: </strong>
                {state.user.username}
            </p>
            <p>
                <strong>Creted at: </strong>
                {state.user.created_at}
            </p>
            <p>
                <strong>Updated at: </strong>
                {state.user.updated_at}
            </p>
            {state.user.role && <>
                <p>
                    <strong>Role: </strong>
                    {state.user.role!.title} : {state.user.role!.level}
                </p>
            </>}

        </>}

    </Drawer >;
});

UserDrawer.displayName = "UserDrawer";
