import { refineDataForApi, saveData } from "@app/lib/api-utils";
import { attempt } from "@app/lib/utils";
import { action, computed, observable, toJS } from "mobx";
import { DataTableStore } from "../DataTable/DataTableStore";
import { DrawerProps } from "antd/lib/drawer";
import _ from "lodash";
import { WrappedFormUtils } from "antd/lib/form/Form";

export abstract class FormDrawerStore<T extends IData> {

    @observable public loading = false;
    @observable public visible = false;
    @observable public _data: T;
    @observable public formDrawerProps: DrawerProps = {};
    @observable public visibleModal = false;
    @observable public visibleModalSecondary = false;

    public autofocus?: string;
    public dataName: string;
    public dummyDataFn: (d?: T) => T;
    public refineForApi = refineDataForApi;
    public apiUrl: string;
    public afterSave: (d: T) => void;
    public abstract tableStore?: DataTableStore;

    public beforeShow?: DummyFn;
    @observable public fieldsWithError: any = {};
    public formUtils?: WrappedFormUtils;

    @computed
    get title(): string {
        return (this.data.id ? "Edit " : "Create ") + this.dataName;
    }

    @action
    public onCreateClick = () => {
        this.setData(this.dummyDataFn());
        this.show();
    }

    @action
    public onEditClick = (d: T) => {
        this.setData(this.dummyDataFn(d));
        this.show();
    }

    @action
    public show = () => {
        if (this.beforeShow) {
            this.beforeShow();
        }
        this.visible = true;
        setTimeout(() => {
            if (this.autofocus) {
                const el = document.getElementById(this.autofocus);
                if (el) {
                    el.focus();
                }
            }
        }, 10);
    }

    @action
    public showModal = (d: T) => {
        this.setData(d);
        this.visibleModal = true;
    }

    @action
    public hideModal = () => {
        this.setData(undefined as any);
        this.visibleModal = false;
    }

    @action
    public showModalSecondary = (d: T) => {
        this.setData(d);
        this.visibleModalSecondary = true;
    }

    @action
    public hideModalSecondary = () => {
        this.setData(undefined as any);
        this.visibleModalSecondary = false;
    }

    @action
    public hide = () => {
        this.visible = false;
    }

    public setData(data: T): void {
        this._data = data;
    }

    @computed
    public get data(): T {
        return this._data;
    }

    public save = async (): Promise<void> => {
        attempt(async () => {
            this.loading = true;
            const data = this.refineForApi(toJS(this.data));
            const resp = await saveData({ url: this.apiUrl }, data);
            this.afterSave(resp.data);
            this.hide();
        }, (err) => {

            if (err.field && this.formUtils) {
                _.forEach(err.field, (value, name) => {
                    this.formUtils!.setFields({[name]: {errors: value.map(Error)}});
                });
                this.fieldsWithError = err.field;
            }
        }, () => {
            this.loading = false;
        });
    }
}
