import {brandFormStore} from "@pages/brands/BrandForm/BrandFormStore";
import {Form, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import {observer} from "mobx-react";

export const ImportMenuModal = observer((): JSX.Element | null => {
    if (!brandFormStore.visibleModal) {
        return null;
    }

    return <Modal
        title={`Import Menu for ${brandFormStore.data.title}`}
        okText="Save"
        visible={brandFormStore.visibleModal}
        onOk={brandFormStore.onMenuSave}
        onCancel={brandFormStore.hideModal.bind(null)}
        okButtonProps={{
            loading: brandFormStore.loading
        }}
        cancelButtonProps={{
            disabled: brandFormStore.loading
        }}
        maskClosable={false}
        bodyStyle={{maxHeight: 450}}>
        <Form.Item label="Menu">
            <TextArea
                placeholder='Example:
category,name,price,type
DÖNƏRLƏR,DANA DÖNƏR ÇÖRƏKDƏ,"2,5",DISH' autoSize={{minRows: 5, maxRows: 10}}
                onChange={brandFormStore.onMenuChange}/>
        </Form.Item>
    </Modal>;
});
