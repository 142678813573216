export default [
  {
      "name": "Andorra",
      "value": "AD",
      "currency": "EUR"
  },
  {
      "name": "United Arab Emirates",
      "value": "AE",
      "currency": "AED"
  },
  {
      "name": "Afghanistan",
      "value": "AF",
      "currency": "AFN"
  },
  {
      "name": "Antigua and Barbuda",
      "value": "AG",
      "currency": "XCD"
  },
  {
      "name": "Anguilla",
      "value": "AI",
      "currency": "XCD"
  },
  {
      "name": "Albania",
      "value": "AL",
      "currency": "ALL"
  },
  {
      "name": "Angola",
      "value": "AO",
      "currency": "AOA"
  },
  {
      "name": "Antarctica",
      "value": "AQ",
      "currency": "USD"
  },
  {
      "name": "Argentina",
      "value": "AR",
      "currency": "ARS"
  },
  {
      "name": "American Samoa",
      "value": "AS",
      "currency": "USD"
  },
  {
      "name": "Austria",
      "value": "AT",
      "currency": "EUR"
  },
  {
      "name": "Australia",
      "value": "AU",
      "currency": "AUD"
  },
  {
      "name": "Aruba",
      "value": "AW",
      "currency": "AWG"
  },
  {
      "name": "Åland",
      "value": "AX",
      "currency": "EUR"
  },
  {
      "name": "Azerbaijan",
      "value": "AZ",
      "currency": "AZN"
  },
  {
      "name": "Bosnia and Herzegovina",
      "value": "BA",
      "currency": "BAM"
  },
  {
      "name": "Barbados",
      "value": "BB",
      "currency": "BBD"
  },
  {
      "name": "Bangladesh",
      "value": "BD",
      "currency": "BDT"
  },
  {
      "name": "Belgium",
      "value": "BE",
      "currency": "EUR"
  },
  {
      "name": "Burkina Faso",
      "value": "BF",
      "currency": "XOF"
  },
  {
      "name": "Bulgaria",
      "value": "BG",
      "currency": "BGN"
  },
  {
      "name": "Bahrain",
      "value": "BH",
      "currency": "BHD"
  },
  {
      "name": "Burundi",
      "value": "BI",
      "currency": "BIF"
  },
  {
      "name": "Benin",
      "value": "BJ",
      "currency": "XOF"
  },
  {
      "name": "Saint Barthélemy",
      "value": "BL",
      "currency": "EUR"
  },
  {
      "name": "Bermuda",
      "value": "BM",
      "currency": "BMD"
  },
  {
      "name": "Brunei",
      "value": "BN",
      "currency": "BND"
  },
  {
      "name": "Bolivia",
      "value": "BO",
      "currency": "BOB"
  },
  {
      "name": "Bonaire",
      "value": "BQ",
      "currency": "USD"
  },
  {
      "name": "Brazil",
      "value": "BR",
      "currency": "BRL"
  },
  {
      "name": "Bahamas",
      "value": "BS",
      "currency": "BSD"
  },
  {
      "name": "Bhutan",
      "value": "BT",
      "currency": "BTN"
  },
  {
      "name": "Bouvet Island",
      "value": "BV",
      "currency": "NOK"
  },
  {
      "name": "Botswana",
      "value": "BW",
      "currency": "BWP"
  },
  {
      "name": "Belarus",
      "value": "BY",
      "currency": "BYR"
  },
  {
      "name": "Belize",
      "value": "BZ",
      "currency": "BZD"
  },
  {
      "name": "Canada",
      "value": "CA",
      "currency": "CAD"
  },
  {
      "name": "Cocos [Keeling] Islands",
      "value": "CC",
      "currency": "AUD"
  },
  {
      "name": "Democratic Republic of the Congo",
      "value": "CD",
      "currency": "CDF"
  },
  {
      "name": "Central African Republic",
      "value": "CF",
      "currency": "XAF"
  },
  {
      "name": "Republic of the Congo",
      "value": "CG",
      "currency": "XAF"
  },
  {
      "name": "Switzerland",
      "value": "CH",
      "currency": "CHF"
  },
  {
      "name": "Ivory Coast",
      "value": "CI",
      "currency": "XOF"
  },
  {
      "name": "Cook Islands",
      "value": "CK",
      "currency": "NZD"
  },
  {
      "name": "Chile",
      "value": "CL",
      "currency": "CLP"
  },
  {
      "name": "Cameroon",
      "value": "CM",
      "currency": "XAF"
  },
  {
      "name": "China",
      "value": "CN",
      "currency": "CNY"
  },
  {
      "name": "Colombia",
      "value": "CO",
      "currency": "COP"
  },
  {
      "name": "Costa Rica",
      "value": "CR",
      "currency": "CRC"
  },
  {
      "name": "Cuba",
      "value": "CU",
      "currency": "CUP"
  },
  {
      "name": "Cape Verde",
      "value": "CV",
      "currency": "CVE"
  },
  {
      "name": "Curacao",
      "value": "CW",
      "currency": "ANG"
  },
  {
      "name": "Christmas Island",
      "value": "CX",
      "currency": "AUD"
  },
  {
      "name": "Cyprus",
      "value": "CY",
      "currency": "EUR"
  },
  {
      "name": "Czechia",
      "value": "CZ",
      "currency": "CZK"
  },
  {
      "name": "Germany",
      "value": "DE",
      "currency": "EUR"
  },
  {
      "name": "Djibouti",
      "value": "DJ",
      "currency": "DJF"
  },
  {
      "name": "Denmark",
      "value": "DK",
      "currency": "DKK"
  },
  {
      "name": "Dominica",
      "value": "DM",
      "currency": "XCD"
  },
  {
      "name": "Dominican Republic",
      "value": "DO",
      "currency": "DOP"
  },
  {
      "name": "Algeria",
      "value": "DZ",
      "currency": "DZD"
  },
  {
      "name": "Ecuador",
      "value": "EC",
      "currency": "USD"
  },
  {
      "name": "Estonia",
      "value": "EE",
      "currency": "EUR"
  },
  {
      "name": "Egypt",
      "value": "EG",
      "currency": "EGP"
  },
  {
      "name": "Western Sahara",
      "value": "EH",
      "currency": "MAD"
  },
  {
      "name": "Eritrea",
      "value": "ER",
      "currency": "ERN"
  },
  {
      "name": "Spain",
      "value": "ES",
      "currency": "EUR"
  },
  {
      "name": "Ethiopia",
      "value": "ET",
      "currency": "ETB"
  },
  {
      "name": "Finland",
      "value": "FI",
      "currency": "EUR"
  },
  {
      "name": "Fiji",
      "value": "FJ",
      "currency": "FJD"
  },
  {
      "name": "Falkland Islands",
      "value": "FK",
      "currency": "FKP"
  },
  {
      "name": "Micronesia",
      "value": "FM",
      "currency": "USD"
  },
  {
      "name": "Faroe Islands",
      "value": "FO",
      "currency": "DKK"
  },
  {
      "name": "France",
      "value": "FR",
      "currency": "EUR"
  },
  {
      "name": "Gabon",
      "value": "GA",
      "currency": "XAF"
  },
  {
      "name": "United Kingdom",
      "value": "GB",
      "currency": "GBP"
  },
  {
      "name": "Grenada",
      "value": "GD",
      "currency": "XCD"
  },
  {
      "name": "Georgia",
      "value": "GE",
      "currency": "GEL"
  },
  {
      "name": "French Guiana",
      "value": "GF",
      "currency": "EUR"
  },
  {
      "name": "Guernsey",
      "value": "GG",
      "currency": "GBP"
  },
  {
      "name": "Ghana",
      "value": "GH",
      "currency": "GHS"
  },
  {
      "name": "Gibraltar",
      "value": "GI",
      "currency": "GIP"
  },
  {
      "name": "Greenland",
      "value": "GL",
      "currency": "DKK"
  },
  {
      "name": "Gambia",
      "value": "GM",
      "currency": "GMD"
  },
  {
      "name": "Guinea",
      "value": "GN",
      "currency": "GNF"
  },
  {
      "name": "Guadeloupe",
      "value": "GP",
      "currency": "EUR"
  },
  {
      "name": "Equatorial Guinea",
      "value": "GQ",
      "currency": "XAF"
  },
  {
      "name": "Greece",
      "value": "GR",
      "currency": "EUR"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "value": "GS",
      "currency": "GBP"
  },
  {
      "name": "Guatemala",
      "value": "GT",
      "currency": "GTQ"
  },
  {
      "name": "Guam",
      "value": "GU",
      "currency": "USD"
  },
  {
      "name": "Guinea-Bissau",
      "value": "GW",
      "currency": "XOF"
  },
  {
      "name": "Guyana",
      "value": "GY",
      "currency": "GYD"
  },
  {
      "name": "Hong Kong",
      "value": "HK",
      "currency": "HKD"
  },
  {
      "name": "Heard Island and McDonald Islands",
      "value": "HM",
      "currency": "AUD"
  },
  {
      "name": "Honduras",
      "value": "HN",
      "currency": "HNL"
  },
  {
      "name": "Croatia",
      "value": "HR",
      "currency": "HRK"
  },
  {
      "name": "Haiti",
      "value": "HT",
      "currency": "HTG"
  },
  {
      "name": "Hungary",
      "value": "HU",
      "currency": "HUF"
  },
  {
      "name": "Indonesia",
      "value": "ID",
      "currency": "IDR"
  },
  {
      "name": "Ireland",
      "value": "IE",
      "currency": "EUR"
  },
  {
      "name": "Israel",
      "value": "IL",
      "currency": "ILS"
  },
  {
      "name": "Isle of Man",
      "value": "IM",
      "currency": "GBP"
  },
  {
      "name": "India",
      "value": "IN",
      "currency": "INR"
  },
  {
      "name": "British Indian Ocean Territory",
      "value": "IO",
      "currency": "USD"
  },
  {
      "name": "Iraq",
      "value": "IQ",
      "currency": "IQD"
  },
  {
      "name": "Iran",
      "value": "IR",
      "currency": "IRR"
  },
  {
      "name": "Iceland",
      "value": "IS",
      "currency": "ISK"
  },
  {
      "name": "Italy",
      "value": "IT",
      "currency": "EUR"
  },
  {
      "name": "Jersey",
      "value": "JE",
      "currency": "GBP"
  },
  {
      "name": "Jamaica",
      "value": "JM",
      "currency": "JMD"
  },
  {
      "name": "Jordan",
      "value": "JO",
      "currency": "JOD"
  },
  {
      "name": "Japan",
      "value": "JP",
      "currency": "JPY"
  },
  {
      "name": "Kenya",
      "value": "KE",
      "currency": "KES"
  },
  {
      "name": "Kyrgyzstan",
      "value": "KG",
      "currency": "KGS"
  },
  {
      "name": "Cambodia",
      "value": "KH",
      "currency": "KHR"
  },
  {
      "name": "Kiribati",
      "value": "KI",
      "currency": "AUD"
  },
  {
      "name": "Comoros",
      "value": "KM",
      "currency": "KMF"
  },
  {
      "name": "Saint Kitts and Nevis",
      "value": "KN",
      "currency": "XCD"
  },
  {
      "name": "North Korea",
      "value": "KP",
      "currency": "KPW"
  },
  {
      "name": "South Korea",
      "value": "KR",
      "currency": "KRW"
  },
  {
      "name": "Kuwait",
      "value": "KW",
      "currency": "KWD"
  },
  {
      "name": "Cayman Islands",
      "value": "KY",
      "currency": "KYD"
  },
  {
      "name": "Kazakhstan",
      "value": "KZ",
      "currency": "KZT"
  },
  {
      "name": "Laos",
      "value": "LA",
      "currency": "LAK"
  },
  {
      "name": "Lebanon",
      "value": "LB",
      "currency": "LBP"
  },
  {
      "name": "Saint Lucia",
      "value": "LC",
      "currency": "XCD"
  },
  {
      "name": "Liechtenstein",
      "value": "LI",
      "currency": "CHF"
  },
  {
      "name": "Sri Lanka",
      "value": "LK",
      "currency": "LKR"
  },
  {
      "name": "Liberia",
      "value": "LR",
      "currency": "LRD"
  },
  {
      "name": "Lesotho",
      "value": "LS",
      "currency": "LSL"
  },
  {
      "name": "Lithuania",
      "value": "LT",
      "currency": "EUR"
  },
  {
      "name": "Luxembourg",
      "value": "LU",
      "currency": "EUR"
  },
  {
      "name": "Latvia",
      "value": "LV",
      "currency": "EUR"
  },
  {
      "name": "Libya",
      "value": "LY",
      "currency": "LYD"
  },
  {
      "name": "Morocco",
      "value": "MA",
      "currency": "MAD"
  },
  {
      "name": "Monaco",
      "value": "MC",
      "currency": "EUR"
  },
  {
      "name": "Moldova",
      "value": "MD",
      "currency": "MDL"
  },
  {
      "name": "Montenegro",
      "value": "ME",
      "currency": "EUR"
  },
  {
      "name": "Saint Martin",
      "value": "MF",
      "currency": "EUR"
  },
  {
      "name": "Madagascar",
      "value": "MG",
      "currency": "MGA"
  },
  {
      "name": "Marshall Islands",
      "value": "MH",
      "currency": "USD"
  },
  {
      "name": "Macedonia",
      "value": "MK",
      "currency": "MKD"
  },
  {
      "name": "Mali",
      "value": "ML",
      "currency": "XOF"
  },
  {
      "name": "Myanmar [Burma]",
      "value": "MM",
      "currency": "MMK"
  },
  {
      "name": "Mongolia",
      "value": "MN",
      "currency": "MNT"
  },
  {
      "name": "Macao",
      "value": "MO",
      "currency": "MOP"
  },
  {
      "name": "Northern Mariana Islands",
      "value": "MP",
      "currency": "USD"
  },
  {
      "name": "Martinique",
      "value": "MQ",
      "currency": "EUR"
  },
  {
      "name": "Mauritania",
      "value": "MR",
      "currency": "MRO"
  },
  {
      "name": "Montserrat",
      "value": "MS",
      "currency": "XCD"
  },
  {
      "name": "Malta",
      "value": "MT",
      "currency": "EUR"
  },
  {
      "name": "Mauritius",
      "value": "MU",
      "currency": "MUR"
  },
  {
      "name": "Maldives",
      "value": "MV",
      "currency": "MVR"
  },
  {
      "name": "Malawi",
      "value": "MW",
      "currency": "MWK"
  },
  {
      "name": "Mexico",
      "value": "MX",
      "currency": "MXN"
  },
  {
      "name": "Malaysia",
      "value": "MY",
      "currency": "MYR"
  },
  {
      "name": "Mozambique",
      "value": "MZ",
      "currency": "MZN"
  },
  {
      "name": "Namibia",
      "value": "NA",
      "currency": "NAD"
  },
  {
      "name": "New Caledonia",
      "value": "NC",
      "currency": "XPF"
  },
  {
      "name": "Niger",
      "value": "NE",
      "currency": "XOF"
  },
  {
      "name": "Norfolk Island",
      "value": "NF",
      "currency": "AUD"
  },
  {
      "name": "Nigeria",
      "value": "NG",
      "currency": "NGN"
  },
  {
      "name": "Nicaragua",
      "value": "NI",
      "currency": "NIO"
  },
  {
      "name": "Netherlands",
      "value": "NL",
      "currency": "EUR"
  },
  {
      "name": "Norway",
      "value": "NO",
      "currency": "NOK"
  },
  {
      "name": "Nepal",
      "value": "NP",
      "currency": "NPR"
  },
  {
      "name": "Nauru",
      "value": "NR",
      "currency": "AUD"
  },
  {
      "name": "Niue",
      "value": "NU",
      "currency": "NZD"
  },
  {
      "name": "New Zealand",
      "value": "NZ",
      "currency": "NZD"
  },
  {
      "name": "Oman",
      "value": "OM",
      "currency": "OMR"
  },
  {
      "name": "Panama",
      "value": "PA",
      "currency": "PAB"
  },
  {
      "name": "Peru",
      "value": "PE",
      "currency": "PEN"
  },
  {
      "name": "French Polynesia",
      "value": "PF",
      "currency": "XPF"
  },
  {
      "name": "Papua New Guinea",
      "value": "PG",
      "currency": "PGK"
  },
  {
      "name": "Philippines",
      "value": "PH",
      "currency": "PHP"
  },
  {
      "name": "Pakistan",
      "value": "PK",
      "currency": "PKR"
  },
  {
      "name": "Poland",
      "value": "PL",
      "currency": "PLN"
  },
  {
      "name": "Saint Pierre and Miquelon",
      "value": "PM",
      "currency": "EUR"
  },
  {
      "name": "Pitcairn Islands",
      "value": "PN",
      "currency": "NZD"
  },
  {
      "name": "Puerto Rico",
      "value": "PR",
      "currency": "USD"
  },
  {
      "name": "Palestine",
      "value": "PS",
      "currency": "ILS"
  },
  {
      "name": "Portugal",
      "value": "PT",
      "currency": "EUR"
  },
  {
      "name": "Palau",
      "value": "PW",
      "currency": "USD"
  },
  {
      "name": "Paraguay",
      "value": "PY",
      "currency": "PYG"
  },
  {
      "name": "Qatar",
      "value": "QA",
      "currency": "QAR"
  },
  {
      "name": "Réunion",
      "value": "RE",
      "currency": "EUR"
  },
  {
      "name": "Romania",
      "value": "RO",
      "currency": "RON"
  },
  {
      "name": "Serbia",
      "value": "RS",
      "currency": "RSD"
  },
  {
      "name": "Russia",
      "value": "RU",
      "currency": "RUB"
  },
  {
      "name": "Rwanda",
      "value": "RW",
      "currency": "RWF"
  },
  {
      "name": "Saudi Arabia",
      "value": "SA",
      "currency": "SAR"
  },
  {
      "name": "Solomon Islands",
      "value": "SB",
      "currency": "SBD"
  },
  {
      "name": "Seychelles",
      "value": "SC",
      "currency": "SCR"
  },
  {
      "name": "Sudan",
      "value": "SD",
      "currency": "SDG"
  },
  {
      "name": "Sweden",
      "value": "SE",
      "currency": "SEK"
  },
  {
      "name": "Singapore",
      "value": "SG",
      "currency": "SGD"
  },
  {
      "name": "Saint Helena",
      "value": "SH",
      "currency": "SHP"
  },
  {
      "name": "Slovenia",
      "value": "SI",
      "currency": "EUR"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "value": "SJ",
      "currency": "NOK"
  },
  {
      "name": "Slovakia",
      "value": "SK",
      "currency": "EUR"
  },
  {
      "name": "Sierra Leone",
      "value": "SL",
      "currency": "SLL"
  },
  {
      "name": "San Marino",
      "value": "SM",
      "currency": "EUR"
  },
  {
      "name": "Senegal",
      "value": "SN",
      "currency": "XOF"
  },
  {
      "name": "Somalia",
      "value": "SO",
      "currency": "SOS"
  },
  {
      "name": "Suriname",
      "value": "SR",
      "currency": "SRD"
  },
  {
      "name": "South Sudan",
      "value": "SS",
      "currency": "SSP"
  },
  {
      "name": "São Tomé and Príncipe",
      "value": "ST",
      "currency": "STD"
  },
  {
      "name": "El Salvador",
      "value": "SV",
      "currency": "USD"
  },
  {
      "name": "Sint Maarten",
      "value": "SX",
      "currency": "ANG"
  },
  {
      "name": "Syria",
      "value": "SY",
      "currency": "SYP"
  },
  {
      "name": "Swaziland",
      "value": "SZ",
      "currency": "SZL"
  },
  {
      "name": "Turks and Caicos Islands",
      "value": "TC",
      "currency": "USD"
  },
  {
      "name": "Chad",
      "value": "TD",
      "currency": "XAF"
  },
  {
      "name": "French Southern Territories",
      "value": "TF",
      "currency": "EUR"
  },
  {
      "name": "Togo",
      "value": "TG",
      "currency": "XOF"
  },
  {
      "name": "Thailand",
      "value": "TH",
      "currency": "THB"
  },
  {
      "name": "Tajikistan",
      "value": "TJ",
      "currency": "TJS"
  },
  {
      "name": "Tokelau",
      "value": "TK",
      "currency": "NZD"
  },
  {
      "name": "East Timor",
      "value": "TL",
      "currency": "USD"
  },
  {
      "name": "Turkmenistan",
      "value": "TM",
      "currency": "TMT"
  },
  {
      "name": "Tunisia",
      "value": "TN",
      "currency": "TND"
  },
  {
      "name": "Tonga",
      "value": "TO",
      "currency": "TOP"
  },
  {
      "name": "Turkey",
      "value": "TR",
      "currency": "TRY"
  },
  {
      "name": "Trinidad and Tobago",
      "value": "TT",
      "currency": "TTD"
  },
  {
      "name": "Tuvalu",
      "value": "TV",
      "currency": "AUD"
  },
  {
      "name": "Taiwan",
      "value": "TW",
      "currency": "TWD"
  },
  {
      "name": "Tanzania",
      "value": "TZ",
      "currency": "TZS"
  },
  {
      "name": "Ukraine",
      "value": "UA",
      "currency": "UAH"
  },
  {
      "name": "Uganda",
      "value": "UG",
      "currency": "UGX"
  },
  {
      "name": "U.S. Minor Outlying Islands",
      "value": "UM",
      "currency": "USD"
  },
  {
      "name": "United States",
      "value": "US",
      "currency": "USD"
  },
  {
      "name": "Uruguay",
      "value": "UY",
      "currency": "UYU"
  },
  {
      "name": "Uzbekistan",
      "value": "UZ",
      "currency": "UZS"
  },
  {
      "name": "Vatican City",
      "value": "VA",
      "currency": "EUR"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "value": "VC",
      "currency": "XCD"
  },
  {
      "name": "Venezuela",
      "value": "VE",
      "currency": "VEF"
  },
  {
      "name": "British Virgin Islands",
      "value": "VG",
      "currency": "USD"
  },
  {
      "name": "U.S. Virgin Islands",
      "value": "VI",
      "currency": "USD"
  },
  {
      "name": "Vietnam",
      "value": "VN",
      "currency": "VND"
  },
  {
      "name": "Vanuatu",
      "value": "VU",
      "currency": "VUV"
  },
  {
      "name": "Wallis and Futuna",
      "value": "WF",
      "currency": "XPF"
  },
  {
      "name": "Samoa",
      "value": "WS",
      "currency": "WST"
  },
  {
      "name": "Kosovo",
      "value": "XK",
      "currency": "EUR"
  },
  {
      "name": "Yemen",
      "value": "YE",
      "currency": "YER"
  },
  {
      "name": "Mayotte",
      "value": "YT",
      "currency": "EUR"
  },
  {
      "name": "South Africa",
      "value": "ZA",
      "currency": "ZAR"
  },
  {
      "name": "Zambia",
      "value": "ZM",
      "currency": "ZMW"
  },
  {
      "name": "Zimbabwe",
      "value": "ZW",
      "currency": "ZWL"
  }
]