import { Col, Row } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { BrandForm } from "../brands/BrandForm/BrandForm";
import { BrandStats } from "./widgets/BrandStats";
import { ExpiringBrands } from "./widgets/ExpiringBrands";
import { DailyOnlineBrandsChart } from "./widgets/DailyOnlineBrandsChart";
import { PaymentsChart } from "./widgets/PaymentsChart";
import { PaymentsMetricBox } from "./widgets/PaymentsMetricBox";
import { TodaysBrandsMetricBox } from "./widgets/TodaysBrandsMetricBox";
import { WeeksBrandsMetricBox } from "./widgets/WeeksBrandsMetricBox";
import { MonthlyNewBrandsList } from "./widgets/MonthlyNewBrandsList";
import { ExpiredBrandsChart2 } from "./widgets/ExpiredBrandsChart2";
import { sessionStore } from "@stores/SessionStore";

export const DashboardPage = observer(function DashboardPage(): JSX.Element {
    return (
        <div>
            <BrandForm />
            <Row gutter={[16, 16]} type="flex">
                <Col sm={24} md={12} lg={8}>
                    <TodaysBrandsMetricBox />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <WeeksBrandsMetricBox />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <PaymentsMetricBox />
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col sm={24} md={12} lg={8}>
                    <DailyOnlineBrandsChart />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <MonthlyNewBrandsList />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <ExpiredBrandsChart2 />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    {sessionStore.user.role.name === "owner" ? <PaymentsChart /> : null}
                    <br />
                    <BrandStats />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <ExpiringBrands justLeft={false} />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <ExpiringBrands justLeft={true} />
                </Col>
            </Row>
        </div>
    );
});
