import * as React from "react";
import { format, formatDistanceStrict } from "date-fns";

export const RelativeDate = ({ date }: { date: string }) => {
    const [state, setState] = React.useState(0);
    const d = new Date(date);
    const isOld = Math.abs(new Date().getTime() - d.getTime()) > 600e3;

    React.useEffect(() => {
        const interval = setInterval(() => {
            setState(s => s + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [date]);
    return (
        <div>
            <div style={{ whiteSpace: "nowrap" }}>{date && format(new Date(date), "HH:mm:ss")}</div>
            <div style={{ color: isOld ? "red" : "#19cc19" }}>{formatDistanceStrict(new Date(date), new Date())}</div>
        </div>
    );
};
