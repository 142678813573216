import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import * as React from "react";
import { dbConfigFormStore } from "../form/formStore";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { form } from "../form/form";
import { DataProvider } from "@app/components/DataTable/DataProvider";

export const tableStore = new DataTableStore<IDbConfig>({
    dataProvider: new DataProvider({
        url: endpoints.databases
    }),

    formStore: () => dbConfigFormStore,
    form,
    columnsManager: {
        hiddenColumns: [],
    },
    filters: [],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 150,
        },
        {
            title: "Host",
            dataIndex: "host",
            key: "host",
            align: "center",
            width: 300,
        },
        {
            title: "Read host",
            dataIndex: "read_host",
            key: "read_host",
            align: "center",
            width: 50,
        },
        {
            title: "Port",
            dataIndex: "port",
            key: "port",
            align: "center",
            width: 70,
        },
        {
            title: "Username",
            key: "username",
            dataIndex: "username",
            align: "center",
            width: 150,
        },
        {
            title: "Brand count",
            key: "brands_count",
            dataIndex: "brands_count",
            align: "center",
            width: 70,
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions,
        },
    ],
});

const editBtn = editButton(tableStore);
const deleteBtn = deleteButton(tableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
    </>;
}
