import * as React from "react";
import { observer } from "mobx-react";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { subscriptionFormStore } from "./SubscriptionFormStore";
import { Select, Form, Input, Button } from "antd";
import _ from "lodash";
import { packageById, packages } from "@app/data/packages";
import { FormComponentProps } from "antd/lib/form";
import { eSet } from "@app/lib/utils";
import ReactJson from "react-json-view";
import { toJS } from "mobx";

@observer
class SubscriptionFormBare extends React.Component<FormComponentProps> {

    public handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                subscriptionFormStore.save();
            }
        });
    }

    public onPackageChange(id: number): void {
        subscriptionFormStore.data.package = packageById(id);
    }

    public render(): JSX.Element {
        const subscription = subscriptionFormStore.data;
        const { getFieldDecorator } = this.props.form;

        return <FormDrawer store={subscriptionFormStore}>
            <Form layout="horizontal" onSubmit={this.handleSubmit}>

                <Form.Item label="Package">
                    <Select defaultValue={_.get(subscription, "package.id")} onChange={this.onPackageChange as any}>
                        {packages().map(pack => <Select.Option key={pack.id} value={pack.id}>
                            {pack.title}
                        </Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label="Monthly period">
                    {getFieldDecorator("monthly_period", {
                        initialValue: subscription.monthly_period,
                        rules: [{ required: true }]
                    })(
                        <Input placeholder="Monthly period" onChange={eSet(subscription, "monthly_period")} />
                    )}
                </Form.Item>

                <Form.Item label="Period price">
                    {getFieldDecorator("period_price", {
                        initialValue: subscription.period_price,
                        rules: [{ required: true }]
                    })(
                        <Input placeholder="Period price" onChange={eSet(subscription, "period_price")} />
                    )}
                </Form.Item>

                <ReactJson src={toJS(subscription)} collapsed={1} name={subscriptionFormStore.dataName} />

                <Button type="primary" loading={subscriptionFormStore.loading}
                    htmlType="submit" style={{ marginRight: 5 }}>
                    Save
                </Button>
                <Button type="default" onClick={subscriptionFormStore.hide} htmlType="button">Cancel</Button>
            </Form>
        </FormDrawer>;
    }
}

export const SubscriptionForm = Form.create()(SubscriptionFormBare);
