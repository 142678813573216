import { MetricBox } from "@app/components/MetricBox";
import { PUBLIC_API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import _ from "lodash";
import * as React from "react";

interface IItemData {
    id: boolean;
    period: string;
    brandsCount: number;
    venuesCount: number;
    terminalsCount: number;
}

interface IProps {}

export const WeeksBrandsMetricBox: React.FC<IProps> = () => {
    const [data, setData] = React.useState<IItemData[]>([]);

    React.useEffect(() => {
        ajax.get({ url: PUBLIC_API_URL + "/admin/stats/last7days" })
            .then(resp => {
                setData(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <MetricBox
            title="Last 7 days brands"
            value={data[1]?.brandsCount}
            value2={data[0]?.brandsCount}
            extra={
                <div>
                    <div>
                        Venues: {data[1]?.venuesCount} / {data[0]?.venuesCount}
                    </div>
                    <div>
                        Terminals: {data[1]?.terminalsCount} / {data[0]?.terminalsCount}
                    </div>
                </div>
            }
        />
    );
};

function getThisWeekBrands(totals: ITotalData[]): number {
    const ls = totals
        .slice(totals.length - 7)
        .map(x => x.list)
        .flat();
    return _.uniq(ls).length;
}

function getLastWeekBrands(totals: ITotalData[]): number {
    const ls = totals
        .slice(totals.length - 14, totals.length - 7)
        .map(x => x.list)
        .flat();
    return _.uniq(ls).length;
}
