import * as React from "react";
import {render} from "react-dom";
import { App } from "@app/app";
import "antd/dist/antd.css";
import "@app/assets/scss/main.scss";

render(
    <App />,
    document.getElementById("root")
);
