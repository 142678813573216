import { sendAction } from "@app/actions/terminal-actions";
import { sessionStore } from "@app/stores/SessionStore";
import { Button, Dropdown, Icon, Input, Menu, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ClickParam } from "antd/lib/menu";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

interface ITerminalActionButtonProps {
    terminal: ITerminal;
    isOnline: boolean;
}

@observer
export class TerminalActionButton extends React.Component<ITerminalActionButtonProps> {

    @observable public loading = false;
    @observable public scriptModal = false;
    @observable public modalAction = "";
    @observable public dataViewerVisible = false;
    @observable public dataViewerDataType = "receipt";
    @observable public script = "";

    public anydeskAutoConnectFeatureVersion = ["1.6.11", "1.8.1"];

    public onClick = async ({ key }: ClickParam): Promise<void> => {
        this.modalAction = key;
        if (key.startsWith("view")) {
            this.dataViewerVisible = true;
            this.dataViewerDataType = key.split("-")[1];
        } else {
            if (key === "remote-script" || key === "send-msg") {
                this.toggleScriptModal();
            } else {
                this.loading = true;
                await sendAction(this.props.terminal, key as any);
                this.loading = false;
            }
        }
    }

    public onScriptRun = async () => {
        if (!this.script || !this.modalAction) return;
        this.loading = true;
        await sendAction(this.props.terminal, this.modalAction as any, {script: this.script});
        this.script = "";
        this.loading = false;
        this.toggleScriptModal();
    }

    public toggleScriptModal = () => {
        this.scriptModal = !this.scriptModal;
    }

    public menu = (): JSX.Element => <Menu onClick={this.onClick}>
        {this.props.isOnline && <Menu.Item key="refresh"> <Icon type="reload" /> Refresh</Menu.Item>}
        <Menu.Item key="send-msg"> <Icon type="message" /> Send Message</Menu.Item>
        {this.props.isOnline && <Menu.Item key="reload-data"> <Icon type="download" /> Reload data</Menu.Item>}
        {[1,3,8,9,35].indexOf(sessionStore.user.id!) > -1 && <Menu.Item key="remote-script"> <Icon type="code" /> Remote Script</Menu.Item>}
        <Menu.Item key="switch-stage"> <Icon type="reload" /> Update Stage</Menu.Item>
        {this.props.isOnline && <Menu.Item key="clear-cache">
            <img src="/svg/swipe.svg" style={{ width: "1em", height: "1em" }} className="anticon" />
            Clear cache
        </Menu.Item>}
        {this.props.isOnline && <Menu.Item key="clear-system-cache">
            Clear System Cache
        </Menu.Item>}
        {this.props.isOnline && <Menu.Item key="clear-system-data">
            Clear System Data
        </Menu.Item>}
        {this.props.terminal.ua?.browser?.name === "Electron" &&
            <Menu.Item key="start-rc">
            Start AnyDesk
        </Menu.Item>}

        {/* <Menu.Divider /> */}
        {/* <Menu.ItemGroup title="Snapshot">
            <Menu.Item key="upload-receipt-data">
                Receipts
            </Menu.Item>
            <Menu.Item key="upload-customer-data">
                Customers
            </Menu.Item>
        </Menu.ItemGroup> */}
        
    </Menu>

    public render(): JSX.Element {
        return <>
            <Dropdown trigger={["click"]} overlay={this.menu()}>
                <Button loading={this.loading} style={{marginRight: 5}}>
                    Action
            <Icon type="down" />
                </Button>
            </Dropdown>
            <Modal visible={this.scriptModal}
                onCancel={this.toggleScriptModal}
                // cancelButtonDisabled={false}
                // cancelButtonProps={{ disabled: true }}
                okButtonProps={{ loading: this.loading }}
                onOk={this.onScriptRun}
                title={this.modalAction === "send-msg" ? "Send Message" : "RemoteScript"} closable={false} okText={this.modalAction === "send-msg" ? "send" : "run"} >
                    <TextArea value={this.script} onChange={e => this.script = e.currentTarget.value} rows={10}/>
            </Modal>
        </>;
    }
}
