import { loadPackages } from "@app/data/packages";
import { loadRoles, roleAccess } from "@app/data/roles";
import { retrieveSellersList } from "@app/lib/utils";
import { initSessionStore, sessionStore } from "@app/stores/SessionStore";
import { depot } from "@cloposcom/libs";
import { createBrowserHistory } from "history";
import { action, computed, observable } from "mobx";

export class AppStore {
    @observable public isReady = false;

    @computed public get path(): string[] {
        return [location.pathname];
    }

    public history = createBrowserHistory();
}

export const initApp = action(async () => {
    depot.setPrefix("admin_");
    try {
        await initSessionStore();
        await retrieveSellersList();
        if (sessionStore.isLoggedIn) {
            await loadAppData();
        }
    } catch (e) {
        console.log(e);
    }
    appStore.isReady = true;
});

export const loadAppData = action(async (): Promise<void> => {
    if (sessionStore.user.role.level > 1) {
        if (sessionStore.user.role.level > 3) {
            await loadRoles();
        }
        await loadPackages();
    }

    // redirect user to his first allowed page
    if (sessionStore.user.role.name !== "owner" && location.pathname === "/") {
        appStore.history.replace(roleAccess[sessionStore.user.role.name][0]);
    }
});

export const appStore = new AppStore();
