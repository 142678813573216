import { loadSellerList } from "@app/lib/utils";
import { useCallback, useEffect, useState } from "react";

export function useSellerList() {
    const [sellers, setSellers] = useState<ISeller[]>([]);

    useEffect(() => {
        loadSellerList().then(setSellers);
    }, []);

    const reloadSellers = useCallback(() => {
        loadSellerList().then(setSellers);
    }, []);

    return { sellers, reloadSellers };
}
