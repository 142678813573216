import { Spin } from "antd";
import * as React from "react";

export const LoadingCenter: React.FC = () => {
    return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: 16,
    }}>
        <Spin size="large" tip="Loading..." />
    </div>;
};
