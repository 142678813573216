import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import React from "react";


export const maintenanceDataStore = new DataTableStore<any>({

    dataProvider: new DataProvider({
        url: endpoints.maintenanceMode,
    }),
    columnsManager: {
        hiddenColumns: []
    },
    columns:[

    ]
});
