import { isNumber, isString } from "lodash";
import * as React from "react";

interface IProps {
    name: React.ReactNode | string | number;
    value: React.ReactNode;
}

export const DataPair: React.FC<IProps> = ({ name, value }) => {



    return <div>
        {isStringOrNumber(name) ? <strong>{name}:</strong> : name}
        {isStringOrNumber(value) ? <span style={{ marginLeft: 8 }}>{value}</span> : value}
    </div>;
};

function isStringOrNumber(val: any) {
    return isString(val) || isNumber(val);
}
