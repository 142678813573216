import * as React from "react";
import { Form, Input, Button, Select } from "antd";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import _ from "lodash";
import { eSet } from "@app/lib/utils";
import { dbConfigFormStore } from "@app/pages/dbconfigs/form/formStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { roles, roleById } from "@app/data/roles";
import ReactJson from "react-json-view";
import { FormComponentProps } from "antd/lib/form";

@observer
class DbConfigFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        dbConfigFormStore.formUtils = this.props.form;
    }

    public onRoleChange(id: number): void {

    }

    public onPartnerChange(id: number): void {

    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                dbConfigFormStore.save();
            }
        });

    };

    public render(): JSX.Element {
        const config = dbConfigFormStore.data;
        const { getFieldDecorator } = this.props.form;
        return <>
            <FormDrawer store={dbConfigFormStore}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>

                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            initialValue: config.name,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="database 1" onChange={eSet(config, "name")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Host">
                        {getFieldDecorator("host", {
                            initialValue: config.host ?? "",
                            rules: [{ min: 5, required: true }],
                        })(
                            <Input placeholder="mysql.clopos.com" type="host" onChange={eSet(config, "host")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Port">
                        {getFieldDecorator("port", {
                            initialValue: config.port,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="3306" type={"number"} onChange={eSet(config, "port")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Username">
                        {getFieldDecorator("username", {
                            initialValue: config.username,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="root" onChange={eSet(config, "username")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Password">
                        {getFieldDecorator("password", {
                            initialValue: config.password,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="" onChange={eSet(config, "password")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Read Host">
                        {getFieldDecorator("read_host", {
                            rules: [{ min: 5, required: false }],
                        })(
                            <Input placeholder="mysql-read.clopos.com" type="read_host"
                                   onChange={eSet(config, "read_host")} />,
                        )}
                    </Form.Item>

                    <ReactJson src={toJS(config)} collapsed={1} name={dbConfigFormStore.dataName} />

                    <Button type="primary" loading={dbConfigFormStore.loading} htmlType="submit"
                            style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={dbConfigFormStore.hide} htmlType="button">Cancel</Button>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const form = Form.create()(DbConfigFormBare);
