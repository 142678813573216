import { ajax, IAjaxConf } from "@app/lib/ajax";
import { get } from "lodash";
import { compose, isNil, not, omit, pickBy } from "ramda";
import _ from "lodash";

const FIELDS_TO_CLEAN = ["token", "created_at", "roles", "deleted_at", "updated_at"] as const;

export const cleanData = pickBy(compose(not, isNil));

export const refineDataForApi: (x: any) => any = compose(
    cleanData,
    omit(FIELDS_TO_CLEAN) as any
);

export const saveData = <T>(conf: IAjaxConf, data: T) => {
    conf.data = refineDataForApi(data);
    if (get(data, "id")) {
        conf.method = "put";
        conf.url += "/" + get(data, "id");
    } else {
        conf.method = "post";
    }
    return ajax.load(conf);
};

export const getData = <T>(conf: IAjaxConf) => {
    conf.method = "get";
    return ajax.load(conf);
};

export const deleteData = (url: string, id: number | string | IData) => {
    url += "/" + (_.isNumber(id) || _.isString(id) ? id : id.id);
    return ajax.delete({ url });
};

export const actionByName = (url: string, id: number | string | IData, actionName: string, params?: any) => {
    url += "/" + (_.isNumber(id) || _.isString(id) ? id : id.id) + "/action/" + actionName;
    return ajax.get({ url, params });
};

export const getDataById = async (url: string, id: number, _with: string[] = []): Promise<IData> => {
    const resp = await ajax.get({ url: `${url}/${id}`, params: { with: _with } });
    return resp.data;
};
