import { Spin } from "antd";
import React, { FC } from "react";

export const MetricBox: FC<{
    title: string;
    value: React.ReactNode;
    title2?: string;
    value2?: React.ReactNode;
    extra?: React.ReactNode;
}> = props => {

    return <div className="widget" style={{ minWidth: 15, flexBasis: "30%" }}>
        <div style={{ fontSize: 12, color: "#333" }}>
            {props.title}
            {props.value2 ? <span style={{color: "#aaa"}}> / Previous</span> : null}
        </div>

        <div style={{ fontSize: 22, color: "#333" }}>
            {props.value || <Spin />}
            {props.value2 ? <span style={{ color: "#aaa" }}> / {props.value2 || <Spin />}</span> : null}
        </div>

        {props.extra && <div>
           {props.extra}
        </div>}
    </div>;
};
