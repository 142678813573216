import { PUBLIC_API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import React, { useEffect } from "react";
import { FC } from "react";
import { partial } from "ramda";
import { brandTableStore } from "@app/pages/brands/BrandsPage/brandTableStore";

interface IItemData {
    month: number;
    year: number;
    newBrands: string[];
}

export const MonthlyNewBrandsList: FC = () => {
    const [data, setData] = React.useState<IItemData[]>([]);

    useEffect(() => {
        ajax.get({ url: `${PUBLIC_API_URL}/admin/stats/monthly-new` })
            .then(r => {
                setData(r.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <div className="widget">
            <div className="widget-title">
                <h4>Monthly New Brands</h4>
            </div>
            <div className="widget-body" style={{ height: 359, overflow: "auto" }}>
                {data.map(item => {
                    return (
                        <div key={item.year + "-" + item.month} className="month-group" style={{ marginBottom: 16 }}>
                            <div style={{ borderBottom: "1px solid #ddd", color: "cornflowerblue", fontSize: 16 }}>
                                {new Date(`${item.month}/01/2000`).toLocaleString("default", { month: "long" })} &nbsp;
                                {item.year} ({item.newBrands.length})
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {item.newBrands
                                    .filter(brand => brand)
                                    .map(brand => {
                                        return (
                                            <span key={brand} className="hover-underline" style={{ marginRight: 5 }}>
                                                {brand},
                                            </span>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
