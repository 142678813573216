import * as React from "react";
import { Dropdown, Menu, Button, Icon, Checkbox } from "antd";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { DataTableStore } from "./DataTableStore";
import { ClickParam } from "antd/lib/menu";
import { ColumnProps } from "antd/lib/table";
import { clone } from "ramda";
import { depot } from "@cloposcom/libs";

interface IColumnsManager<T extends IData> {
    store: DataTableStore<T>;
    defaultHiddenColumns?: Array<keyof T>;
}

@observer
export class ColumnsManager<T extends IData> extends React.Component<IColumnsManager<T>> {
    @observable public visible = false;
    @observable public hiddenColumns: string[] = [];
    @observable public allColumns: Array<ColumnProps<T>> = [];

    constructor(props: IColumnsManager<T>) {
        super(props);
        this.allColumns = props.store.columns;
        this.prepareHiddenColumns();
        this.reloadVisibleColumns();
    }

    get localStorageName(): string {
        return "columns_" + this.props.store.dataProvider.conf.url;
    }

    @action
    private prepareHiddenColumns(): void {
        const savedVersion = depot.getItem(this.localStorageName);
        this.hiddenColumns = savedVersion || this.props.defaultHiddenColumns || [];
    }

    private preserveHiddenColumns(): void {
        depot.setItem(this.localStorageName, this.hiddenColumns);
    }

    public overlay(): JSX.Element {
        return (
            <Menu onClick={this.onClick}>
                {this.allColumns.map(col => (
                    <Menu.Item key={col.key}>
                        <Checkbox checked={!this.hiddenColumns.includes(col.key as any)} style={{ marginRight: 5 }} />
                        {col.title}
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

    public onClick = ({ key }: ClickParam) => {
        const i = this.hiddenColumns.indexOf(key);
        if (i > -1) {
            this.hiddenColumns.splice(i, 1);
        } else {
            this.hiddenColumns.push(key);
        }
        this.reloadVisibleColumns();
        this.preserveHiddenColumns();
    };

    @action
    public reloadVisibleColumns(): void {
        this.props.store.columns = this.allColumns.filter(c => !this.hiddenColumns.includes(c.key as string));
    }

    @action
    public onVisibleChange = (flag?: boolean) => {
        this.visible = !!flag;
    };

    public render(): JSX.Element {
        return (
            <Dropdown
                overlay={this.overlay()}
                trigger={["click"]}
                visible={this.visible}
                onVisibleChange={this.onVisibleChange}
            >
                <Button>
                    Columns <Icon type="down" />
                </Button>
            </Dropdown>
        );
    }
}
