import { DataTable } from "@app/components/DataTable/DataTable";
import { BrandForm } from "@app/pages/brands/BrandForm/BrandForm";
import { observer } from "mobx-react";
import * as React from "react";
import { venueTableStore } from "./venueTableStore";
// import { ImportMenuModal } from "@pages/venues/VenueForm/ImportMenu";
// import { ImportMenuXLS } from "@pages/venues/VenueForm/ImportMenuXLS";

@observer
export class VenuesPage extends React.Component {

    public componentDidMount(): void {
        venueTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Venues</h2>
            <BrandForm />
            <div>
                <DataTable store={venueTableStore} bordered />
            </div>
        </div>;
    }
}
