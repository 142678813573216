import { Modal, Table } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { rpColumns } from "./columns";
import { TerminalDataViewerStore } from "./TerminalDataViewerStore";
import { ViewerButton } from "./ViewerButton";

interface IProps {
    brand: string;
    deviceId: string;
}

@observer
export class TerminalDataViewer extends React.Component<IProps> {

    public store: TerminalDataViewerStore;

    constructor(props: IProps) {
        super(props);
        this.store = new TerminalDataViewerStore(props.brand, props.deviceId);
    }

    public expandedRowRender = ({ receipts_product }: any): JSX.Element => {
        return <Table dataSource={receipts_product} columns={rpColumns} rowKey="id"
            style={{ width: window.innerWidth * 0.7 }} />;
    }

    public render(): JSX.Element {

        return <>
            <ViewerButton store={this.store} />

            <Modal visible={this.store.visible} title={"Terminal Data: " + this.store.date} width="90%"
                onCancel={this.store.onClose} closable>
                <Table dataSource={toJS(this.store.data)} columns={this.store.columns}
                    scroll={this.store.scroll}
                    rowKey="id" className="components-table-demo-nested"
                    loading={this.store.loading}
                    expandedRowRender={this.store.dataType === "receipt" ? this.expandedRowRender : undefined} />
            </Modal>
        </>;
    }
}
