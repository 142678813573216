import { MetricBox } from "@app/components/MetricBox";
import { endpoints } from "@app/config/endpoints";
import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { API_URL, PUBLIC_API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import moment from "antd/node_modules/moment";
import * as React from "react";
import { useEffect, useState } from "react";

interface IProps {}

export const TodaysBrandsMetricBox: React.FC<IProps> = () => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        ajax.get({ url: PUBLIC_API_URL + "/admin/stats/daily/2" }).then(resp => {
            setData(resp.data);
        });
    }, []);

    return (
        <MetricBox
            title="Today brands"
            value={data[0]?.brandCount}
            value2={data[1]?.brandCount}
            extra={
                <div>
                    <div>
                        Venues: {data[0]?.venueCount} / {data[1]?.venueCount}
                    </div>
                    <div>
                        Terminals: {data[0]?.terminalCount} / {data[1]?.terminalCount}
                    </div>
                </div>
            }
        />
    );
};
