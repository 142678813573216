import { dummyTerminal } from "@app/actions/terminal-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { action, observable } from "mobx";
import { brandTerminalsTableStore } from "../BrandsPage/BrandTerminalsPage/brandTerminalsTableStore";
import _ from "lodash";
import { ajax } from "@app/lib/ajax";

class TerminalFormStore extends FormDrawerStore<ITerminal> {
    
    @observable public _data = dummyTerminal();
    @observable public venues: IVenue[] = [];
    public autofocus = "name";
    public dataName = "terminal";
    public dummyDataFn = dummyTerminal;
    public apiUrl = endpoints.brandTerminals;
    public brand_id?: number;

    get tableStore(): DataTableStore {
        return brandTerminalsTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public beforeShow = async (): Promise<void> => {
        await this.loadVenues();
    }

    public async loadVenues(): Promise<IVenue[]> {
        const resp = await ajax.get({
            url: `brand/${this.brand_id}/venues`,
        });
        this.venues = resp?.data || [];
        return resp?.data || [];
    }

    public afterSave = (d: ITerminal) => {
        if (this.tableStore) {
            this.tableStore.dataProvider.updateLocalCopyOfData(d);
        }
    }
}

export const terminalFormStore = new TerminalFormStore;
