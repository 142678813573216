import React, { useEffect, useState } from "react";
import { ajax } from "@app/lib/ajax";
import { endpoints } from "@app/config/endpoints";
import ReactJson from "react-json-view";
import { Button, Layout, message, Popconfirm, Popover, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import StoriesPDrawer from "./StoriesPDrawer";
import JSONFormData from "json-form-data";

const { Content } = Layout;

const StoriesPage = () => {
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [drawer, setDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [didRefresh, setDidRefresh] = useState(new Date().toISOString());
    const [elementData, setElementData] = useState<IUserStory | null>(null);

    const doRefresh = () => setDidRefresh(new Date().toISOString());

    useEffect(() => {
        (async () => {
            setTableLoading(true);
            try {
                const res = await ajax.get({
                    url: endpoints.stories,
                    params: {
                        with: ["items"]
                    }
                });
                setTableData(res?.data);
            } catch (error) {
                message.error(error.toString());
                setTableLoading(false);
            }
            setTableLoading(false);
        })();
    }, [didRefresh])

    const createAction = () => {
        setElementData(null);
        setIsEdit(false);
        setDrawer(true);
    }

    const editAction = (data: IUserStory) => {
        setElementData(data);
        setIsEdit(true);
        setDrawer(true);
    }

    const closeDrawer = () => {
        setElementData(null);
        setIsEdit(false);
        setDrawer(false);
    }

    const createRequest = async (data: IUserStory | null) => {
        const formData = JSONFormData({
            ...data,
            items: data?.items.map((iD: IUserStoryItem) => ({
                ...iD,
                value: iD?.formImage || iD?.value
            }))
        });
        await ajax.post({
            url: endpoints.stories + (isEdit ? `/${data?.id}?_method=put` : ""),
            data: formData
        })
            .then(() => {
                closeDrawer();
                doRefresh();
            })
            .catch(ex => {
                message.error(ex.toString());
                setTableLoading(false);
            });
    }

    const deleteRequest = async (dataID: number) => {
        await ajax.delete({
            url: endpoints.stories + "/" + dataID,
        }).catch(ex => {
            message.error(ex.toString());
        });
        doRefresh();
    }

    const actions = (data: IUserStory) => {
        return <>
            <Button onClick={() => editAction(data)} style={{ marginRight: 5 }}>
                Edit
            </Button>
            <Popconfirm title="Are you sure?" onConfirm={() => deleteRequest(data.id)}>
                <Button type="danger" style={{ marginRight: 5 }}> Delete </Button>
            </Popconfirm>
        </>
    }

    const columns: ColumnProps<any>[] = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: 50
        },
        {
            title: "Cover",
            dataIndex: "cover",
            key: "cover",
            align: "center",
            width: 100,
            render: (src: any, data: any) => <Popover
                placement="right"
                content={data?.items?.map((s: any) => <img
                    key={s.id}
                    src={s.value}
                    height={250}
                    style={{ padding: 3 }}
                />)}
                title="Preview"
                trigger="hover"
            >
                {src ? <img
                    src={src}
                    height={100}
                    width={80}
                /> : "No image"}
            </Popover>
        },
        {
            title: "Number of stories",
            dataIndex: "items",
            key: "items",
            align: "center",
            width: 100,
            render: (text: any, data: any, index: any) => data?.items?.length || 0
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
            align: "center",
            width: 100,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 100,
            render: (status: any) => status ? "Active" : "Inactive"
        },
        {
            title: "Expire at",
            dataIndex: "expire_at",
            key: "expire_at",
            align: "center",
            width: 100,
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions
        },
    ];

    return <div>
        <Content
            style={{
                background: '#fff',
                padding: 24,
                margin: 0,
                minHeight: 280,
            }}
        >
            <h2>Stories</h2>
            <Button icon="reload" onClick={doRefresh} />
            <Button onClick={createAction} type="primary" icon="plus"
                style={{ marginBottom: 10, marginRight: 5 }}>Create</Button>
            <Table dataSource={tableData} columns={columns} loading={tableLoading} />
            <ReactJson src={{ tableData, elementData, isEdit, drawer }} collapsed />
            <StoriesPDrawer
                elementData={elementData}
                isEdit={isEdit}
                drawer={drawer}
                createRequest={createRequest}
                closeDrawer={closeDrawer}
            />
        </Content>
    </div>
}

export default StoriesPage;