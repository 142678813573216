import * as React from "react";
import CurrencyFormat from "react-currency-format";

interface IProps {
    value: number;
}

export const Money: React.FC<IProps> = ({ value }) => {
    return <CurrencyFormat value={value} displayType="text" thousandSeparator suffix={"₼"} />;
};
