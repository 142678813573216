import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { ajax } from "@app/lib/ajax";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FC } from "react";

export const BrandStats: FC = props => {

    const [data, setData] = useState([] as any);
    const [totalCount, setTotalCount] = useState(0);
    const [all, setAll] = useState([] as any[]);

    useEffect(() => {
        const filters = [
            ["status", BrandStatus.ACTIVE],
            ["stage_status", BrandStageStatus.DEFAULT],
            ["partner_id", 1], // Only azerbaijan
        ];
        ajax.get({
            url: "/brand",
            params: {
                limit: 999,
                with: ["client", "subscription.packages", "transactions"],
                filters
            }
        }).then(resp => {
            const list = resp.data;
            setAll(list);

            const groups = _.map(
                _.groupBy(list, b => b.subscription.period_price / b.subscription.monthly_period),
                (g: any[], i) => ({
                    price: parseFloat(i),
                    count: g.length,
                    percent: (100 * g.length / list.length).toFixed(2),
                    brands: g.map(b => b.name)
                })).sort((a: any, b: any) => {
                    const aa = a.count;
                    const bb = b.count;
                    return aa > bb ? -1 : aa < bb ? 1 : 0;
                });
            setData(groups);
        });
    }, []);

    return <div className="widget">
        <div className="widget-title">
            Brand stats ({all.length}) <br />
            <small>Average {getAverage(all)}</small> |
            <small>Total {getTotal(all)}</small>
        </div>
        <div className="widget-body">
            <table className="table" >
                <thead>
                    <tr>
                        <th style={{ textAlign: "right" }}>Price</th>
                        <th style={{ textAlign: "right" }}>Count</th>
                        <th style={{ textAlign: "right" }}>Total</th>
                        <th style={{ textAlign: "right" }}>Percent</th>
                        <th>Brands</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((g: any) => {
                        return <tr key={g.price}>
                            <td style={{ textAlign: "right" }}>{g.price} ₼</td>
                            <td style={{ textAlign: "right" }}>{g.count}</td>
                            <td style={{ textAlign: "right" }}>{g.count * g.price} ₼</td>
                            <td style={{ textAlign: "right" }}>{g.percent}% </td>
                            <td>{g.brands.join(", ")}</td>
                        </tr>;
                    })}
                </tbody>

            </table>
        </div>

    </div>;
};

function getTotal(all: any[]): number {
    return all.reduce((sum, b) => sum + b.subscription.period_price / b.subscription.monthly_period, 0);
}
function getAverage(all: any[]): string {
    return (getTotal(all) / all.length).toFixed(2);
}
