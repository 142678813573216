
export const endpoints = {
    user: "/user",
    authUser: "/auth/user",
    brand: "/brand",
    brandExtra: "report/brands",
    brandLogin: "/brand/:id/login",
    brandTerminals: "/brand/:id/terminal",
    client: "/client",
    transaction: "/transaction",
    cloposPay: "/clopos-pay-transactions",
    maintenanceMode: "/server/maintenance",
    transactionDashboard: "/transaction/dashboard",
    role: "/role",
    terminals: "/device",
    stories: "/stories",
    package: "/package",
    module: "/module",
    packagePrices: "/package_prices",
    partners: "/partners",
    lastLogin: "/last?today=1",
    venue: "/venues",
    dbConfigs: "/db-configs",
    databases: "/databases",
    tags: "/tags",
};
