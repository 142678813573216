import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { deleteData, saveData } from "@app/lib/api-utils";

const url = endpoints.partners;

export const savePartner = (partner: IPartner) => saveData({ url }, partner);

export const deletePartner = (partner: IPartner | number) => deleteData(url, partner);

export const refinePartnerFromApi = (d: IPartner) => d;

export const getPartnerById = async (id: number): Promise<IPartner> => {
    const resp = await ajax.get({ url: `${url}/${id}` });
    return refinePartnerFromApi(resp.data);
};

export const dummyPartner = (partner?: IPartner): IPartner => {
    return {
        name: "",
        countries: [],
        meta: null,
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        type:"PARTNER",
        parent_id: null,
        ...partner
    };
};