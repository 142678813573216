import { observable, action, computed } from "mobx";
import { attempt } from "@app/lib/utils";
import { columns } from "./columns";
import { ColumnProps } from "antd/lib/table";

export class TerminalDataViewerStore {

    @observable public loading = false;
    @observable public visible = false;
    @observable public dataType = "receipt";
    @observable public data: any[];
    @observable public date: string;

    private static URL = "https://storage.googleapis.com/clopos-log/";

    constructor(
      public brand: string,
      public deviceId: string
    ) {}

    @action
    public show(dataType: string): void {
        this.dataType = dataType;
        this.loadData();
        this.visible = true;
    }

    @computed
    public get columns(): Array<ColumnProps<any>> {
        return (columns as any)[this.dataType];
    }

    @action
    public onClose = () => {
        this.visible = false;
        this.data = [];
    }

    @action
    public async loadData(): Promise<void> {
        this.loading = true;

        await attempt(async () => {
            const resp = await fetch(
                `${TerminalDataViewerStore.URL}${this.brand}/${this.deviceId}_${this.dataType}.json`
                // `${URL}shanti/${this.props.data}.json`
            );
            const json = await resp.json();
            this.data = json.data;
            this.date = json.date;
        });

        this.loading = false;
    }

    @computed
    get scroll(): {x: number | false} {
        if (this.dataType === "receipt") {
            return {x: 2500};
        } else {
            return {x: false};
        }

    }

}
