import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTable } from "@app/components/DataTable/DataTable";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import * as React from "react";
import { TerminalActionButton } from "./TerminalActionButton";
import { TerminalDataViewer } from "./TerminalDataViewer/TerminalDataViewer";
import { Button, Icon, Input } from "antd";
import _ from "lodash";
import { format, formatDistance, formatDistanceStrict } from "date-fns";
import { observable } from "mobx";
import { sendAction } from "@app/actions/terminal-actions";
import { BrandStage } from "@app/data/brand-stages";
import { PUBLIC_API_URL } from "@app/config/main";
import { CopyButton } from "@app/components/misc/CopyButton";
import { RelativeDate } from "@app/components/RelativeDate";

@observer
export class TerminalsPageNew extends React.Component {
    @observable public searchVal = "";
    @observable private reloadAllPending = false;

    public tableStore = new DataTableStore<ITerminal>({
        dataProvider: new DataProvider({
            url: PUBLIC_API_URL + "/admin/terminals",
        }),
        filters: [
            {
                label: "Stage",
                name: "stage",
                type: "dropdown",
                values: {
                    [BrandStage.ALPHA]: "Alpha",
                    [BrandStage.BETA]: "Beta",
                    [BrandStage.RC]: "RC",
                    [BrandStage.PROD]: "Prod",
                },
                defaultValue: "___",
                // isLocal: true,
            },
            {
                label: "Version",
                name: "v",
                type: "dropdown",
                defaultValue: "___",
                endpoint: "versions" as any,
            },
            {
                label: "OS",
                name: "ua.os.name",
                type: "dropdown",
                defaultValue: "___",
                values: {
                    Windows: "Windows",
                    "Mac OS": "Mac OS",
                    Android: "Android",
                    iOS: "iOS",
                    Linux: "Linux",
                },
            },
            {
                name: "appV",
                type: "dropdown",
                placeholder: "App Version      ",
                defaultValue: "___",
                endpoint: "app-versions" as any,
            },
            {
                name: "search",
                type: "input",
            },
        ],
        columnsManager: {
            hiddenColumns: [],
        },
        columns: [
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                align: "left",
                width: 150,
                // sorter: (a, b) => (a.brand! > b.brand! ? 1 : a.brand! < b.brand! ? -1 : 0),
                // defaultSortOrder: "ascend",
            },
            {
                title: "Last Seen",
                dataIndex: "updatedAt",
                key: "updatedAt",
                align: "center",
                width: 100,
                render: (text, t) => <RelativeDate date={text} />,
            },
            {
                title: "Venue",
                dataIndex: "venue.name",
                key: "venue",
                align: "left",
                width: 100,
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                align: "center",
                width: 100,
            },
            {
                title: "Password",
                dataIndex: "password",
                key: "password",
                align: "center",
                width: 100,
            },
            {
                title: "Device ID",
                dataIndex: "deviceId",
                key: "deviceId",
                align: "center",
                width: 120,
                render: (text, t) => {
                    return (
                        <CopyButton
                            text={text.substring(0, 3) + ".." + text.substring(text.length - 3)}
                            value={text}
                            style={{ margin: 0 }}
                        />
                    );
                },
            },
            {
                title: "Version",
                dataIndex: "v",
                key: "v",
                align: "center",
                width: 100,
                sorter: (a, b) => (a.v! > b.v! ? 1 : a.v! < b.v! ? -1 : 0),
            },
            {
                title: "Stage",
                dataIndex: "stage",
                key: "stage",
                align: "center",
                width: 100,
            },
            {
                title: "Resolution",
                dataIndex: "resolution",
                key: "resolution",
                align: "center",
                width: 100,
            },
            {
                title: "App Version",
                dataIndex: "appV",
                key: "appV",
                align: "right",
                width: 100,
                render: (text, t) => {
                    return (
                        <>
                            {text}
                            <Icon
                                theme="filled"
                                type={text ? t.ua.os.name?.toLowerCase() : "chrome"}
                                style={{ marginLeft: 3 }}
                            />
                        </>
                    );
                },
            },
            {
                title: "OS",
                dataIndex: "ua.os.name",
                key: "os",
                align: "center",
                width: 120,
                render: (name, item) => (
                    <span>
                        {name} {item.ua.os.version}
                    </span>
                ),
            },
            {
                title: "Engine",
                dataIndex: "ua.engine.name",
                key: "engine",
                align: "center",
                width: 120,
                render: (name, item) => (
                    <span>
                        {name} {item.ua.engine.version}
                    </span>
                ),
            },
            {
                title: "Browser",
                dataIndex: "ua.browser.name",
                key: "browser",
                align: "center",
                width: 120,
                render: (name, item) => (
                    <span>
                        {name} {item.ua.browser.version}
                    </span>
                ),
            },
            {
                title: "Device",
                dataIndex: "ua.device.model",
                key: "device",
                align: "center",
                width: 300,
                render: (model, item) => (
                    <span>
                        {model} {item.ua.device!.vendor}
                    </span>
                ),
            },
            {
                title: "Actions",
                key: "actions",
                width: 300,
                render: (term: ITerminal) => {
                    return (
                        <div className="btns-column">
                            <TerminalActionButton terminal={term} isOnline />
                            <TerminalDataViewer brand={term.brand!} deviceId={term.deviceId!} />
                        </div>
                    );
                },
            },
        ],
    });

    public componentDidMount(): void {
        this.tableStore.dataProvider.loadData();
    }

    private reloadAll = async () => {
        this.reloadAllPending = true;
        const chunks = _.chunk(this.tableStore.dataProvider.list, 10);
        for (const chunk of chunks) {
            await Promise.allSettled(chunk.map(t => sendAction(t, "refresh")));
        }
        this.reloadAllPending = false;
    };

    public render(): JSX.Element {
        return (
            <div>
                <h2>Todays's Terminals ({this.tableStore.dataProvider.pagination.total})</h2>
                <div>
                    <DataTable
                        store={this.tableStore}
                        bordered
                        rowKey="deviceId"
                        extraHeaderElements={
                            <>
                                <Button
                                    className="ml-1"
                                    type="danger"
                                    loading={this.reloadAllPending}
                                    onClick={this.reloadAll}
                                >
                                    Reload all
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        );
    }
}
