import * as React from "react";
import { Form, Input, Button, Select } from "antd";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { eSet, set } from "@app/lib/utils";
import { tagFormStore } from "@app/pages/tags/form/formStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import ReactJson from "react-json-view";
import { FormComponentProps } from "antd/lib/form";
import RadioGroup from "antd/lib/radio/group";
import { partial } from "lodash";

@observer
class DbConfigFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        tagFormStore.formUtils = this.props.form;
    }

    public onRoleChange(id: number): void {

    }

    public onPartnerChange(id: number): void {

    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                tagFormStore.save();
            }
        });

    };

    public render(): JSX.Element {
        const tag = tagFormStore.data;
        const { getFieldDecorator } = this.props.form;
        return <>
            <FormDrawer store={tagFormStore}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>

                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            initialValue: tag.name,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="" onChange={eSet(tag, "name")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Description">
                        {getFieldDecorator("description", {
                            initialValue: tag.description ?? "",
                            rules: [{ min: 5, required: true }],
                        })(
                            <Input placeholder="" type="text" onChange={eSet(tag, "description")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Type">
                        {getFieldDecorator("type", {
                            initialValue: tag.type,
                            rules: [{ required: true }],
                        })(
                            <Select style={{ width: "100%" }}  onChange={partial(set, tag, "type")}>
                                <Select.Option key="brand" value="brand">Brand</Select.Option>
                                <Select.Option key="package" value="package">Package</Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                    <ReactJson src={toJS(tag)} collapsed={1} name={tagFormStore.dataName} />

                    <Button type="primary" loading={tagFormStore.loading} htmlType="submit"
                            style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={tagFormStore.hide} htmlType="button">Cancel</Button>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const form = Form.create()(DbConfigFormBare);
