import { observer } from "mobx-react";
import * as React from "react";
import { maintenanceDataStore } from "@pages/maintenance/MaintenanceDataStore";
import { Button, Input, notification, Spin, Table } from "antd";
import { ajax } from "@lib/ajax";



@observer
export class MaintenanceModePage extends React.Component {

    state = {
        data: [] as string[],
        loading: true
    }

    public async componentDidMount() {
        const { data } = await maintenanceDataStore.dataProvider.getPureData();

        this.setState({
            data: data as string[],
            loading: false
        })
    }

    add = () => {
        let newData = this.state.data ?? [];
        newData.push("");
        this.setState({
            data: newData
        })
    }

    save = async () => {
        this.setState({
            loading : true
        });
        try {

            const { success,data } = await ajax.post({
                ...maintenanceDataStore.dataProvider.ajaxConfig,
                method: "POST",
                data : {
                    patterns : this.state.data.filter(item => !!item)
                },
                params : undefined
            })

            notification.success({
                message: success ? "Data saved successfully" : "Error while saving data",
            });

        } catch (error) {
            notification.error({
                message: error?.message ?? "Error while saving data",
            });
            console.log(error);
        }

        this.setState({
            loading : false
        })
    }

    remove = (index : number) => {
        let newData = this.state.data;
        newData.splice(index, 1);
        this.setState({
            data: newData
        })
    }

    change = (index : number, value : string ) => {
        let newData = this.state.data;
        newData[index] = value;
        this.setState({
            data: newData
        })
    }


    public render(): JSX.Element {

        return this.state.loading
            ? <div className="ant-table-wrapper">
                <div style={{ textAlign: "center" }}>
                    <Spin size="large" />
                </div>
            </div>
            : <div className="ant-table-wrapper">
            <div>
                <h1>Maintenance Mode</h1>
                <p>When maintenance mode is enabled, only the following referer pattern will be dont allow to access the api.</p>
            </div>
            <div style={{display:"flex","justifyContent" : "flex-end"}}>

                <Button type="ghost" className="btn btn-sm ant-btn-ghost" onClick={this.add}>
                    Add
                </Button>

                <Button type="primary" className="btn btn-sm btn-primary" onClick={this.save}>
                    Save
                </Button>
            </div>
             <table className="table table-striped">
                <thead className={"ant-table-thead"}>
                    <tr>
                        <th>Referer</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className={"ant-table-body"}>
                    {this.state.data.map((item, index) => {
                        return <tr className={"ant-table-row ant-table-row-level-0"} key={index}>
                            <td className={"ant-table-row-cell-break-word"}>
                                <Input type="text" value={item} onChange={(event) => this.change(index,event.target.value)} />
                            </td>
                            <td className={"ant-table-row-cell-break-word"}>
                              <Button type="danger" className="btn btn-sm btn-danger" onClick={() => this.remove(index)}>
                                    Remove
                                </Button>
                            </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </div>;
    }
}


export default MaintenanceModePage;
