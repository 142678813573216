import { ColumnProps } from "antd/lib/table";
import { action, computed, observable } from "mobx";
import { FormDrawerStore } from "../FormDrawer/FormDrawerStore";
import { DataProvider } from "./DataProvider";
import _ from "lodash";
import { endpoints } from "@config/endpoints";
import { DataTableFilterType, IDataTableFilter } from "./DataTableFilter";
type Endpoint = keyof typeof endpoints;

export interface IDataTableConfig<T extends IData, G extends DataProvider<T> = DataProvider<T>> {
    dataProvider: G;
    columns: Array<ColumnProps<T>>;
    form?: React.ComponentClass;
    formStore?: () => FormDrawerStore<T>;
    columnsManager?:
        | boolean
        | {
              hiddenColumns: Array<keyof T>;
          };
    sortOptions?: any;
    reloadBtn?: boolean;
    filters?: IDataTableFilter[];
    extraData?: any;
}

export class DataTableStore<T extends IData = any, G extends DataProvider<T> = DataProvider<T>> {
    @observable public columns: Array<ColumnProps<T>> = [];
    @observable public dataProvider: G;
    @observable public config: IDataTableConfig<T, G>;
    @observable public extraData: any = { country: "AZ" };

    constructor(config: IDataTableConfig<T, G>) {
        this.config = _.assign(
            {},
            {
                reloadBtn: true,
            },
            config,
        );
        this.columns = this.config.columns;
        this.dataProvider = this.config.dataProvider;
        // console.log(this.dataProvider)
    }

    @action public onEditClick = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.setData(formStore.dummyDataFn(d));
            formStore.show();
        }
    };

    @action public showModal = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.showModal(d);
        }
    };

    @action public showModalSecondary = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.showModalSecondary(d);
        }
    };

    @computed get showCreateButton(): boolean {
        return !!this.config.form && !!this.config.formStore;
    }
}
