import { SmallBrandsTable } from "@app/components/SmallBrandsTable";
import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { ajax } from "@app/lib/ajax";
import moment from "antd/node_modules/moment";
import React, { useEffect, useState } from "react";
import { FC } from "react";

export const ExpiringBrands: FC<{ justLeft: boolean }> = props => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const filters = [["stage_status", BrandStageStatus.DEFAULT]] as any[];
        if (props.justLeft) {
            filters.push(
                [
                    ["status", BrandStatus.EXPIRED],
                    ["status", BrandStatus.ACTIVE],
                ],
                ["expired_at", "<", moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")],
                ["expired_at", ">", moment().subtract(60, "days").format("YYYY-MM-DD HH:mm:ss")],
            );
        } else {
            filters.push(
                ["status", BrandStatus.ACTIVE],
                ["expired_at", ">", moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")],
                ["expired_at", "<", moment().format("YYYY-MM-DD HH:mm:ss")],
            );
        }
        ajax.get({
            url: "/brand",
            params: {
                limit: 100,
                with: ["client", "subscription.packages", "transactions"],
                filters,
            },
        }).then(resp => {
            const list = resp.data.sort((a: any, b: any) => {
                const aa = a.subscription.expired_at;
                const bb = b.subscription.expired_at;
                return aa > bb ? -1 : aa < bb ? 1 : 0;
            });

            setData(list);
        });
    }, []);

    return (
        <SmallBrandsTable
            data={data}
            title={props.justLeft ? "Expired" : "Expiring"}
            titleStyle={{ color: props.justLeft ? "red" : "gold" }}
        />
    );
};
