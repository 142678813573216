import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import Form, { FormComponentProps } from "antd/lib/form";
import React, { FC, ReactNode } from "react";
import TextArea from "antd/es/input/TextArea";
import { eSet } from "@app/lib/utils";
import { observer } from "mobx-react";

interface IProps extends FormComponentProps {
    isCreate: boolean;
    handleSubmit: (e: any) => void;
    buttons: () => ReactNode;
}

export const BrandFormNotesTab: FC<IProps> = observer(props => {
    const brand = brandFormStore.data;
    const getFieldDecorator = props.form.getFieldDecorator;

    return (
        <Form layout="horizontal" onSubmit={props.handleSubmit}>
            <Form.Item label="Note">
                {getFieldDecorator("note", {
                    initialValue: brand.note,
                    rules: [{ required: false }],
                })(<TextArea style={{ height: 120 }} onChange={eSet(brand, "note")} />)}
            </Form.Item>

            {!props.isCreate && (
                <Form.Item label="Left reason">
                    {getFieldDecorator("left_reason_note", {
                        initialValue: brand.left_reason_note,
                        rules: [{ required: false }],
                    })(<TextArea style={{ height: 120 }} onChange={eSet(brand, "left_reason_note")} />)}
                </Form.Item>
            )}
            {props.buttons()}
        </Form>
    );
});
