import { navList } from "@app/config/sidebar-menu";
import { appStore } from "@app/stores/AppStore";
import { Icon, Layout, Menu } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { useOutsideClick } from "rooks";
import "./layout.scss";
import _ from "lodash";
import { IS_MOBILE } from "@app/config/main";
import { sessionStore } from "@app/stores/SessionStore";
import { roleAccess } from "@app/data/roles";

const { Sider } = Layout;

export const Sidebar = observer(({ isHidden, hide }: any) => {
    const [showOver, setShowOver] = React.useState(false);
    const isMenuSelected = (path: string) => _.trim(path, "/") === _.trim(location.pathname, "/");
    const ref = React.useRef<any>();
    const [reNavList, setNavList] = React.useState(navList);

    React.useEffect(() => {
        if (sessionStore.user.role.name !== "owner") {
            const list = navList?.filter((r) => {

                if (r?.children) {

                    r.children = r.children.filter((c) => roleAccess[sessionStore.user.role.name].includes(c?.to!));

                    return r.children.length !== 0;
                }

                return roleAccess[sessionStore.user.role.name].includes(r?.to!);
            });
            setNavList(list);
        }
    }, [sessionStore.role]);

    useOutsideClick(ref, () => {
        if (IS_MOBILE) {
            hide(true);
        }
    });

    React.useEffect(() => {
        if (IS_MOBILE) {
            return;
        }
        const listener = (e: MouseEvent) => {
            if (e.y < 50) {
                return;
            }
            if (e.x < 40 && !showOver) {
                setShowOver(true);
            } else if (e.x > 200 && showOver) {
                setShowOver(false);
            }
        };
        document.body.addEventListener("mousemove", listener);
        return () => document.body.removeEventListener("mousemove", listener);
    }, [showOver]);

    return <Sider collapsible theme="light" style={{ left: isHidden && !showOver ? -200 : 0 }}>
        <div ref={ref}>
            <div className="logo">
                <img src="/img/clopos-logo-pos.png"
                     alt="Clopos Logo" style={{ maxWidth: "100%" }} />
            </div>
            <Menu mode="inline" defaultSelectedKeys={appStore.path}>
                {reNavList.map((navItem, i) => {

                    if (navItem.onlyShowUsers && !navItem.onlyShowUsers.includes(sessionStore.user.id!)) {
                        return null;
                    }

                    if (navItem.children && navItem.children.length > 0) {
                        return <Menu.SubMenu key={navItem.label} title={<span><Icon
                            type={navItem.icon} /><span>{navItem.label}</span></span>}>
                            {navItem.children.map((child, i) => {
                                if (child.onlyShowUsers && !child.onlyShowUsers.includes(sessionStore.user.id!)) {
                                    return null;
                                }
                                return <Menu.Item key={child.to}>
                                    <Link to={child.to}>
                                        <Icon type={child.icon} />
                                        <span>{child.label}</span>
                                    </Link>
                                </Menu.Item>;
                            })}
                        </Menu.SubMenu>;
                    } else {
                        return <Menu.Item key={navItem.to}>
                            <Link to={navItem.to}>
                                <Icon type={navItem.icon} />
                                <span>{navItem.label}</span>
                            </Link>
                        </Menu.Item>;
                    }
                })}
            </Menu>
        </div>

    </Sider>;
});

Sidebar.displayName = "Sidebar";
