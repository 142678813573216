import { Button, Drawer, Icon, Input, Select, Collapse, message } from "antd";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import uuid from "uuid";
import _ from "lodash";
import { CountriesAll } from "@app/lib/utils";
import ContentWithLabel from "@app/components/ContentWithLabel";

const { Panel } = Collapse;

type IProps = {
    elementData: any
    drawer: boolean
    closeDrawer: () => void
    createRequest: (data: IPackagePrice | null, priceID: number | null) => Promise<void>,
    deleteRequest: (priceID: number) => Promise<void>,
}

const PackagCountryPricingDrawer = ({ elementData, drawer, closeDrawer, createRequest, deleteRequest }: IProps) => {
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isAddButtonEnabled, setAddButtonEnabled] = useState<boolean>(true);
    const [data, setData] = useState<IPackagePrice[]>([]);

    const resetDrawer = () => {
        setData([]);
    }

    useEffect(() => {
        if (elementData) {
            setData(elementData?.prices || []);
        }

        return () => {
            resetDrawer();
        }
    }, [elementData, drawer])

    const submitAction = async (data: IPackagePrice | null, priceID: number | null) => {
        try {
            setSubmitLoading(true);
            await createRequest(data, priceID)
                .then(() => {
                    setSubmitLoading(false);
                    setAddButtonEnabled(true);
                })
                .catch((e) => setSubmitLoading(false));
        } catch (e) {
            message.error(e);
            setSubmitLoading(false);
        }
    }

    const deleteAction = async (priceID: number | null, key: string) => {
        if (priceID) {
            try {
                setDeleteLoading(true);
                await deleteRequest(priceID)
                    .then(() => {
                        setDeleteLoading(false);
                        setData(prev => prev.filter(p => p.id !== priceID));
                        setAddButtonEnabled(true);
                    })
                    .catch((e) => setDeleteLoading(false));
            } catch (e) {
                message.error(e);
                setDeleteLoading(false);
            }
        } else if (key) {
            setData(prev => prev.filter(p => p.key !== key));
            setAddButtonEnabled(true);
        }
    }

    const updateData = (key: string, payload: any) => {
        setData(prev => {
            return prev.map((p: IPackagePrice) => {
                return (p.key === key) ? {
                    ...p,
                    ...payload
                } : p;
            })
        })
    }

    const addCountry = () => {
        setAddButtonEnabled(false);
        setData(prev => [...prev, {
            key: uuid.v4(),
            id: null,
            package_id: elementData.id,
            price: null,
            currency: null,
            country_code: null,
            price_3_month: null,
            price_6_month: null,
            price_12_month: null,
        }])
    }

    return <Drawer
        title={"Pricing by countries"}
        width={600}
        visible={drawer}
        bodyStyle={{ paddingBottom: 80 }}
        onClose={closeDrawer}
        destroyOnClose
    >
        <div>
            {
                data?.length > 0 && (
                    <Collapse>
                        {data?.map((item: IPackagePrice) => {
                            const title = item?.country_code ? (() => {
                                const cDat = CountriesAll.find(c => c.value === item.country_code);
                                return `${cDat?.name} - ${item?.price || 0} ${item?.currency || cDat?.currency || ""}`
                            })() : "Select a country";
                            return (
                                <Panel header={title} key={item.id || item.key}>
                                    <ContentWithLabel label={"Country"}>
                                        <Select
                                            showSearch
                                            value={item.country_code as string}
                                            onChange={(country_code: string) => {
                                                updateData(item.key, { country_code });
                                                const currentC = _.find(CountriesAll, c => c.value === country_code);
                                                if(currentC) updateData(item.key, { currency: currentC?.currency });
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            {CountriesAll.map(country => (
                                                <Select.Option key={country.name} value={country.value}>
                                                    {country.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </ContentWithLabel>
                                    <ContentWithLabel label={"Currency"}>
                                        <Select
                                            showSearch
                                            value={item.currency as string}
                                            onChange={(currency: string) => updateData(item.key, { currency })}
                                            style={{ width: "100%" }}
                                        >
                                            {_.unionBy(CountriesAll, "currency").map(country => (
                                                <Select.Option key={country.name} value={country.currency}>
                                                    {country.currency}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </ContentWithLabel>
                                    <ContentWithLabel label={"Price"}>
                                        <Input placeholder="Price" value={item.price as number} type="number" onChange={e => updateData(item.key, { price: e.target.value })} />
                                    </ContentWithLabel>
                                    {/*<ContentWithLabel label={"Price for 3 month"}>*/}
                                    {/*    <Input placeholder="Price for 3 month" value={item.price_3_month as number} type="number" onChange={e => updateData(item.key, { price_3_month: e.target.value })} />*/}
                                    {/*</ContentWithLabel>*/}
                                    {/*<ContentWithLabel label={"Price for 6 month"}>*/}
                                    {/*    <Input placeholder="Price for 6 month" value={item.price_6_month as number} type="number" onChange={e => updateData(item.key, { price_6_month: e.target.value })} />*/}
                                    {/*</ContentWithLabel>*/}
                                    {/*<ContentWithLabel label={"Price for 12 month"}>*/}
                                    {/*    <Input placeholder="Price for 12 month" value={item.price_12_month as number} type="number" onChange={e => updateData(item.key, { price_12_month: e.target.value })} />*/}
                                    {/*</ContentWithLabel>*/}

                                    <div style={{ marginRight: 8, float: "right" }}>
                                        <Button type={"danger"} onClick={() => deleteAction(item?.id, item.key)} loading={deleteLoading}>Delete</Button>
                                        <Button onClick={async () => await submitAction(data.find(d => d.id === item.id) as any, item?.id)} loading={submitLoading} type="primary">Update</Button>
                                    </div>
                                </Panel>
                            )
                        })}
                    </Collapse>
                )
            }
            {isAddButtonEnabled && (
                <Button type="dashed" onClick={addCountry} style={{ marginTop: 3, width: '100%' }}>
                    <Icon type="plus" /> Add country
                </Button>
            )}
        </div>
        <ReactJson src={{ data, elementData }} collapsed style={{ marginTop: 20 }} />
        <div
            style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
                Close
            </Button>
        </div>
    </Drawer>
}

export default PackagCountryPricingDrawer;
