import * as React from "react";
import {TransactionStatus} from "@app/config/enums";
import { Tag } from "antd";

const colors: any = {
    [TransactionStatus.SUCCESS]: "green",
    [TransactionStatus.PENDING]: "orange",
    [TransactionStatus.FAILED]: "red"
};

export function TransactionStatusTag({status}: { status: TransactionStatus }): JSX.Element {
    return <Tag color={colors[TransactionStatus[status]]}>
        {status}
    </Tag>;
}
