import { deleteButton, editButton } from "@app/components/DataTable/actions";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import * as React from "react";
import { PackageForm } from "../PackageForm/PackageForm";
import { packageFormStore } from "../PackageForm/PackegFormStore";

export const packageTableStore = new DataTableStore<IPackage>({
    dataProvider: new DataProvider({
        url: endpoints.package,
        with: ["prices"]
    }),
    formStore: () => packageFormStore,
    form: PackageForm,
    columnsManager: {
        hiddenColumns: ["created_at"]
    },
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: 100
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 100
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            align: "center",
            width: 100,
            render: (basePrice, record: IPackage) => {
                const price = packageTableStore.extraData?.["country"]
                    ? record.prices?.find(p => p.country_code === packageTableStore.extraData?.["country"] ?? "AZ")
                    : null;

                return <div>{price ? (price?.price + ` ${price?.currency}`) : "-"}</div>;
            }
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 100
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions
        },
    ],
    reloadBtn: true,
});

const editBtn = editButton(packageTableStore);
const deleteBtn = deleteButton(packageTableStore);

function actions(data: any): JSX.Element {
    return <div style={{textAlign:"center"}}>
        {editBtn(data)}
        {deleteBtn(data)}
    </div>;
}
