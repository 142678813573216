
export enum TerminalDevice {
    DESKTOP,
    MOBILE,
    CALL_CENTER
}

export const Devices: Record<number, string> =  {
    0: "Desktop",
    1: "Mobile",
    2: "Call Center"
};

export const DEFAULT_DEVICE = TerminalDevice.DESKTOP;
