import { SmallBrandsTable } from "@app/components/SmallBrandsTable";
import { LoadingCenter } from "@app/components/misc/LoadingCenter";
import { PUBLIC_API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import { Col, Row } from "antd";
import { observer, useLocalStore } from "mobx-react";
import React, { useEffect } from "react";
import { BrandForm } from "../brands/BrandForm/BrandForm";
import { NotPayingBrands } from "../dashboard-new/widgets/NotPayingBrands";
import { sortBy } from "lodash/fp";
import { ClientForm } from "../clients/ClientForm/ClientForm";

export const AbsentsPage = observer(function AbsentsPage(): JSX.Element {
    const s = useLocalStore(() => ({
        isLoading: true,
        lastMonth: [] as IBrand[],
        lastWeek: [] as IBrand[],
        yesterday: [] as IBrand[],
        async init(): Promise<void> {
            s.isLoading = true;
            ajax.get({
                url: PUBLIC_API_URL + "/admin/stats/absents",
            })
                .then(resp => {
                    s.lastMonth = sortBy(["subscription.expired_at"], resp.data.last30Days);
                    s.lastWeek = sortBy(["subscription.expired_at"], resp.data.last7Days);
                    s.yesterday = sortBy(["subscription.expired_at"], resp.data.yesterday);
                })
                .finally(() => {
                    s.isLoading = false;
                });
        },
    }));

    useEffect(() => {
        s.init();
    }, []);

    if (s.isLoading) {
        return <LoadingCenter />;
    }

    return (
        <div>
            <h2>Absents</h2>
            <BrandForm />
            <ClientForm />
            <Row gutter={16}>
                <Col sm={24} md={12} lg={8}>
                    <SmallBrandsTable title={`Yesterday (${s.yesterday.length})`} data={s.yesterday} />
                    <br />
                    <NotPayingBrands />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <SmallBrandsTable title={`Last week (${s.lastWeek.length})`} data={s.lastWeek} />
                </Col>
                <Col sm={24} md={12} lg={8}>
                    <SmallBrandsTable title={`Last 30 days (${s.lastMonth.length})`} data={s.lastMonth} />
                </Col>
                {/* <Col sm={24} md={12} lg={6}>
                <h4>Past ({s.old.length})</h4>

                <div>{s.old.map(name => <a style={{ display: "block" }}
                    onClick={openEditor(name)} key={name}>
                    {name} ({brandNameMap.get(name)?.total_amount} AZN)
                </a>)}</div>
            </Col> */}
            </Row>
        </div>
    );
});
