import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { partnerTableStore } from "../PartnerPage/partnerTableStore";
import { observable } from "mobx";
import { dummyPartner, refinePartnerFromApi } from "@app/actions/partner-actions";

export class PartnerFormStore extends FormDrawerStore<IPartner> {
    public autofocus = "name";
    public dataName = "partners";
    public dummyDataFn = dummyPartner;
    public apiUrl = endpoints.partners;
    @observable public _data = dummyPartner();
    public afterSaveCallback?: (d: IPartner) => void;

    get tableStore(): DataTableStore {
        return partnerTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public afterSave = (d: IPartner) => {
        d = refinePartnerFromApi(d);
        partnerTableStore.dataProvider.updateLocalCopyOfData(d);
        if (this.afterSaveCallback) {
            this.afterSaveCallback(d);
            this.afterSaveCallback = undefined;
        }
    }
}

export const partnersFormStore = new PartnerFormStore;
