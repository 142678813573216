import { Button, Drawer, Icon, Input, InputNumber, Select, Upload, Collapse, DatePicker, message } from "antd";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { blobToBase64 } from 'base64-blob'
import uuid from "uuid";
import moment from "antd/node_modules/moment";
import _ from "lodash";
import { CountriesAll } from "@app/lib/utils";
import ContentWithLabel from "@app/components/ContentWithLabel";

const { Panel } = Collapse;

type IProps = {
    elementData: IUserStory | null
    isEdit: boolean
    drawer: boolean
    closeDrawer: () => void
    createRequest: (data: IUserStory | null) => Promise<void>
}

const StoriesPDrawer = ({ elementData, isEdit, drawer, closeDrawer, createRequest }: IProps) => {
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [storyTitle, setStoryTitle] = useState<string | any>("");
    const [storyCover, setStoryCover] = useState<string | any>("");
    const [storyDuration, setStoryDuration] = useState<number | any>(10);
    const [storyCountry, setCountry] = useState<string>("AZ");
    const [expirationDate, setExpirationDate] = useState<any>(null);
    const [storyStatus, setStoryStatus] = useState<number | boolean>(1);
    const [subStories, setSubStories] = useState<IUserStoryItem[]>([]);

    const [storyCoverUpload, setStoryCoverUpload] = useState<any>([]);

    const resetDrawer = () => {
        setStoryTitle("");
        setStoryCover("");
        setCountry("AZ");
        setStoryDuration(10);
        setStoryStatus(1);
        setSubStories([]);
        setStoryCoverUpload([]);
        setStoryCoverUpload([]);
        setExpirationDate(moment());
    }

    useEffect(() => {
        if (elementData) {
            setStoryTitle(elementData?.title);
            setStoryCover(elementData?.cover);
            setCountry(elementData?.country_code);
            setStoryDuration(elementData?.duration);
            setStoryStatus(elementData?.status);
            setSubStories(elementData?.items);
            setExpirationDate(moment(elementData?.expire_at));
            setStoryCoverUpload([]);
            if (elementData?.cover && isEdit) setStoryCoverUpload([{ uid: elementData?.cover, type: "", size: 200, name: elementData?.title, url: elementData?.cover }])
        }

        return () => {
            resetDrawer();
        }
    }, [elementData, drawer])

    const submitAction = async () => {
        try {
            setSubmitLoading(true);
            await createRequest({
                ...elementData,
                title: storyTitle,
                cover: storyCoverUpload[0],
                country_code: storyCountry,
                duration: storyDuration,
                status: storyStatus,
                expire_at: moment(expirationDate).toISOString(),
                items: subStories.map(items => _.isString(items.id) ? _.omit(items, ['id']) : items)
            } as any)
                .then(() => {
                    setSubmitLoading(false);
                    resetDrawer();
                })
                .catch((e) => setSubmitLoading(false));
        } catch (e) {
            message.error(e);
            setSubmitLoading(false);
        }
    }

    const coverImageBeforeUpload = (file: File) => {
        setStoryCoverUpload([file]);
        (async () => {
            const base64 = await blobToBase64(file);
            setStoryCover(base64);
        })()
        return false;
    }

    const subStoryImageBeforeUpload = (storyID: number, file: File) => {
        (async () => {
            let dummy = subStories;
            for (let x in dummy) {
                if (dummy[x].id === storyID) {
                    dummy[x] = {
                        ...dummy[x],
                        value: await blobToBase64(file),
                        formImage: file
                    }
                }
            }
            setSubStories(dummy);
        })();
        return false;
    }

    const subStoryDataChange = (storyID: number, payload: any) => {
        setSubStories(prev => {
            return prev.map((p: IUserStoryItem) => {
                return (p.id === storyID) ? {
                    ...p,
                    ...payload
                } : p;
            })
        })
    }

    const createStory = () => {
        setSubStories(prev => [...prev, {
            id: uuid.v4() as never,
            story_id: elementData?.id || undefined,
            swipe_text: "Yuxarı sürüşdür",
            mime_type: "image/png",
            value: "",
            formImage: null,
            action: {
                type: "url",
                value: "https://clopos.com"
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            custom_attributes: null,
        }])
    }

    const deleteStory = (storyID: number) => {
        setSubStories(prev => prev.filter(p => p.id !== storyID));
    }

    return <Drawer
        title={isEdit ? "Edit story" : "Create story"}
        width={600}
        visible={drawer}
        bodyStyle={{ paddingBottom: 80 }}
        onClose={closeDrawer}
        destroyOnClose
    >
        <div>
            <ContentWithLabel label={"Title"} required>
                <Input placeholder="Title" value={storyTitle} onChange={(e) => setStoryTitle(e.target.value)} />
            </ContentWithLabel>
            <ContentWithLabel label={"Cover"} required>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <img
                        height={100}
                        width={80}
                        src={storyCover}
                        style={{ marginBottom: 10 }}
                    />
                    <Upload
                        beforeUpload={coverImageBeforeUpload}
                        showUploadList={false}
                    >
                        <Button>
                            <Icon type="select" /> Select
                        </Button>
                    </Upload>
                </div>
            </ContentWithLabel>
            {
                subStories.length > 0 && (
                    <Collapse>
                        {subStories?.map((eD: IUserStoryItem) => {
                            return (
                                <Panel header={eD?.swipe_text} key={eD.id}>
                                    <ContentWithLabel label={"Image"} required>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <img
                                                height={100}
                                                width={80}
                                                src={eD.value}
                                                style={{ marginBottom: 10 }}
                                            />
                                            <Upload
                                                beforeUpload={(file) => subStoryImageBeforeUpload(eD.id, file)}
                                                showUploadList={false}
                                            >
                                                <Button>
                                                    <Icon type="select" /> Select
                                                </Button>
                                            </Upload>
                                        </div>
                                    </ContentWithLabel>
                                    <ContentWithLabel label={"Swipe text"}>
                                        <Input placeholder="Swipe text" value={eD.swipe_text} onChange={(e) => subStoryDataChange(eD.id, { swipe_text: e.target.value })} />
                                    </ContentWithLabel>
                                    <ContentWithLabel label={"Swipe action"}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Select defaultValue={eD?.action?.type} style={{ width: 150 }} onChange={(type: any) => subStoryDataChange(eD.id, { action: { ...eD?.action, type } })}>
                                                <Select.Option value={"url"}>Link</Select.Option>
                                                <Select.Option value={"call"}>Phone number</Select.Option>
                                                <Select.Option value={"mail"}>E-mail</Select.Option>
                                                <Select.Option value={"geo"}>Coordinate</Select.Option>
                                                <Select.Option value={"whatsapp"}>WhatsApp</Select.Option>
                                            </Select>
                                            <Input placeholder="Action value" value={eD?.action?.value} onChange={(e) => subStoryDataChange(eD.id, { action: { ...eD?.action, value: e.target.value } })} />
                                        </div>
                                        {eD?.action?.type === "geo" && (
                                            <iframe
                                                width="100%"
                                                height="250"
                                                src={`https://maps.google.com/maps?q=${eD?.action?.value}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                                frameBorder="0"
                                                scrolling="no"
                                                marginHeight={0}
                                                marginWidth={0}
                                                style={{ marginTop: 5 }}
                                            />
                                        )}
                                    </ContentWithLabel>
                                    <Button type={"danger"} onClick={() => deleteStory(eD.id)} style={{ marginRight: 8 }}>
                                        Delete
                                    </Button>
                                </Panel>
                            )
                        })}
                    </Collapse>
                )
            }
            <Button type="dashed" onClick={createStory} style={{ marginTop: 3, width: '100%' }}>
                <Icon type="plus" /> Add story
            </Button>
            <ContentWithLabel label={"Country"}>
                <Select defaultValue={storyCountry} onChange={setCountry} style={{width: "100%"}}>
                    {CountriesAll.map(country => (
                        <Select.Option key={country.name} value={country.value}>
                            {country.name}
                        </Select.Option>
                    ))}
                </Select>
            </ContentWithLabel>
            <ContentWithLabel label={"Expiration date"}>
                <DatePicker defaultValue={expirationDate} value={expirationDate} onChange={setExpirationDate} style={{width: "100%"}} />
            </ContentWithLabel>
            <ContentWithLabel label={"Duration"}>
                <InputNumber placeholder="Duration" min={1} value={storyDuration} onChange={setStoryDuration} style={{width: "100%"}} />
            </ContentWithLabel>
            <ContentWithLabel label={"Status"}>
                <Select defaultValue={storyStatus} onChange={setStoryStatus} style={{width: "100%"}}>
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={0}>Inactive</Select.Option>
                </Select>
            </ContentWithLabel>
        </div>
        <ReactJson src={{ storyTitle, storyCover, storyCoverUpload, subStories, storyCountry, storyDuration, storyStatus, expirationDate }} collapsed />
        <div
            style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
                Cancel
            </Button>
            <Button onClick={submitAction} loading={submitLoading} type="primary">
                {isEdit ? "Update" : "Create"}
            </Button>
        </div>
    </Drawer>
}

export default StoriesPDrawer;