import { Button, Dropdown, Icon, Menu, Popconfirm } from "antd";
import { partial } from "ramda";
import * as React from "react";
import { DataTableStore } from "./DataTableStore";

export const deleteButton = (store: DataTableStore) => (d: IData) =>
    <Popconfirm title="Are you sure?" onConfirm={partial(store.dataProvider.deleteItem, [d])}>
        <Button type="danger" style={{ marginRight: 5 }}> Delete </Button>
    </Popconfirm>;

export const editButton = (store: DataTableStore) => (d: IData) =>
    <Button onClick={partial(store.onEditClick, [d])} style={{ marginRight: 5 }}>
        Edit
    </Button>;

export const importButton = (store: DataTableStore) => (d: IData) => {
    const handleMenuClick = (data: any) => {
        if (data.key === "xls") {
            store.showModalSecondary(d);
        } else if (data.key === "csv") {
            store.showModal(d);
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="xls">
                <Icon type="file-excel" />
                Excel
            </Menu.Item>
            <Menu.Item key="csv">
                <Icon type="file-text" />
                CSV
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} overlayStyle={{ marginRight: 5 }}>
            <Button>
                Import Menu <Icon type="down" />
            </Button>
        </Dropdown>
    )
}
