import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTable } from "@app/components/DataTable/DataTable";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "antd";
import { observable } from "mobx";
import { BrandStage } from "@app/data/brand-stages";
import ReactJson from "react-json-view";
import { brandFormStore } from "@pages/brands/BrandForm/BrandFormStore";
import { v4 } from "uuid";

export interface IDeliveryServiceHistory {
    _id: object|string
    brand: string
    service: string
    data: any,
    created_at: string
}

@observer
export class DeliveryServiceHistoryPage extends React.Component {
    @observable public searchVal = "";

    public tableStore = new DataTableStore<IDeliveryServiceHistory>({
        dataProvider: new DataProvider({
            url: "https://delivery-api.clopos.com/api/orders",
        }),
        filters: [
            {
                label: "Service",
                name: "service",
                type: "dropdown",
                values: {
                    "wolt": "Wolt",
                    "uber_eats": "Bolt Food",
                    "yandex_eats": "Yandex Eats",
                    "getir": "Getir",
                    "yemeksepeti": "Yemeksepeti",
                    "trendyol": "Trendyol",
                },
                defaultValue: "___",
                // isLocal: true,
            },
            {
                name: "brand",
                type: "input",
            },
        ],
        columnsManager: {
            hiddenColumns: [],
        },
        columns: [
            {
                title: "Service",
                dataIndex: "service",
                key: "service",
                align: "left",
                width: 150,
            },
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                align: "left",
                width: 100,
            },
            {
                title: "Created at",
                dataIndex: "created_at",
                key: "created_at",
                align: "center",
                width: 200,
                render: (date) => {
                    return new Date(date).toLocaleString();
                }
            },
            {
                title: "Data",
                key: "data",
                render: (data) => {
                    return (
                        <ReactJson
                            displayObjectSize={false}
                            collapsed={true}
                            src={data}
                            displayDataTypes={false}
                            defaultValue={{}}
                        />
                    );
                }
            },
        ],
    });

    public componentDidMount(): void {
        this.tableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return (
            <div>
                <h2>
                    Brand data history
                </h2>
                <div>
                    <DataTable
                        store={this.tableStore}
                        bordered
                        rowKey="_id"
                    />
                </div>
            </div>
        );
    }
}
