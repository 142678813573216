import { DATETIME_FORMAT } from "@app/config/main";
import { eSet, eSetValue } from "@app/lib/utils";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { Button, Col, DatePicker, Dropdown, Form, Icon, Input, Menu, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { isNil } from "lodash";
import React, { FC, ReactNode } from "react";
import moment from "moment";
import { observer } from "mobx-react";

interface IProps extends FormComponentProps {
    isCreate: boolean;
    handleSubmit: (e: any) => void;
    buttons: () => ReactNode;
}

export const BrandFormSubscriptionTab: FC<IProps> = observer(props => {
    const brand = brandFormStore.data;
    const getFieldDecorator = props.form.getFieldDecorator;

    return (
        <Form layout="horizontal" onSubmit={props.handleSubmit}>
            {/* <legend>Subscription {brand.subscription!.trial ? "(Trial)" : ""}</legend> */}

            <Form.Item label="Package">
                {getFieldDecorator("package", {
                    initialValue: brand.subscription!.packages
                        ? brand.subscription!.packages.filter(p => p.type === "PACKAGE")[0].id
                        : undefined,
                    rules: [{ required: true }],
                })(
                    <Select
                        placeholder="Select package"
                        onChange={(dataId: any) => {
                            brandFormStore.setPackageId(dataId);
                            const packages = brand.subscription?.packages;
                            const countryPackage = packages
                                ? packages[0]?.prices?.find((p: IPackagePrice) => p?.country_code === brand.country)
                                : null;
                            // if(countryPackage?.price_3_month) eSetValue(brand.subscription, "monthly_period", countryPackage?.price_3_month);
                            if (countryPackage?.price && !brand.subscription?.period_price) {
                                eSetValue(brand.subscription, "period_price", countryPackage?.price);
                            }
                            if (countryPackage?.currency) {
                                eSetValue(brand.subscription, "currency", countryPackage?.currency);
                            }
                        }}
                    >
                        {brandFormStore.packages.map(p => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.name}
                            </Select.Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label="Modules">
                {getFieldDecorator("modules", {
                    initialValue: brand.subscription!.packages
                        ? brand.subscription!.packages.filter(p => p.type === "MODULE").map(m => m.id)
                        : undefined,
                    rules: [{ required: false }],
                })(
                    <Select
                        placeholder="Select module"
                        mode="multiple"
                        allowClear
                        onChange={brandFormStore.setModulePackageId as any}
                    >
                        {brandFormStore.modulePackages.map(p => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.title}
                            </Select.Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label="Monthly period">
                {getFieldDecorator("monthly_period", {
                    initialValue: brand.subscription!.monthly_period,
                    rules: [{ required: true }],
                })(
                    <Input
                        placeholder="Monthly period"
                        addonAfter={"month"}
                        onChange={eSet(brand.subscription, "monthly_period")}
                    />,
                )}
            </Form.Item>

            <Form.Item label="Period price">
                {getFieldDecorator("period_price", {
                    initialValue: calculatePeriodPrice(brand),
                    rules: [{ required: true }],
                })(
                    <Input
                        placeholder="Period price"
                        addonAfter={brand?.subscription?.currency || "AZN"}
                        onChange={eSet(brand.subscription, "period_price")}
                    />,
                )}
            </Form.Item>

            <Form.Item label="Expiration">
                <Row gutter={16}>
                    <Col span={18}>
                        {getFieldDecorator("expired_at", {
                            initialValue: moment(brand.subscription!.expired_at),
                            rules: [{ required: true }],
                        })(
                            <DatePicker
                                format={DATETIME_FORMAT}
                                onChange={setNewExpirationDate.bind(null, brand)}
                                style={{ width: "100%" }}
                            />,
                        )}
                    </Col>
                    <Col span={6}>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu onClick={({ key }) => addXDaysToExpiration(brand, key as any)}>
                                    <Menu.Item key={7}>7 days</Menu.Item>
                                    <Menu.Item key={15}>15 days</Menu.Item>
                                    <Menu.Item key={30}>30 days</Menu.Item>
                                    <Menu.Item key={60}>60 days</Menu.Item>
                                    <Menu.Item key={90}>90 days</Menu.Item>
                                    <Menu.Item key={180}>180 days</Menu.Item>
                                    <Menu.Item key={360}>360 days</Menu.Item>
                                </Menu>
                            }
                        >
                            <Button>
                                Add <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </Form.Item>

            {props.buttons()}
        </Form>
    );
});

function calculateUnpaidDays(brand: IBrand): number {
    const exp = moment(brand.subscription!.expired_at);
    const diff = exp.diff(moment(), "days");
    if (diff < 0) {
        const unpaidUsedDays = Math.abs(diff);
        if (unpaidUsedDays < 7) {
            return unpaidUsedDays;
        }
        return 7;
    }
    return 0;
}

function addXDaysToExpiration(brand: IBrand, x: string | number): void {
    x = parseInt(x as string);
    const unpaidDays = calculateUnpaidDays(brand);
    const daysAfterUnpaid = x - unpaidDays;

    brand.subscription!.expired_at = moment().add(daysAfterUnpaid, "days").format(DATETIME_FORMAT);
}

function setNewExpirationDate(brand: IBrand, m: any): void {
    brand.subscription!.expired_at = m.format(DATETIME_FORMAT);
}

function calculatePeriodPrice(brand: IBrand): number {
    if (!isNil(brand.subscription!.period_price) && brand.id) {
        return brand.subscription!.period_price;
    }
    const monthlyPeriod = brand.subscription!.monthly_period;
    const periodPrice = brand.subscription!.packages[0].price;
    if ((brand.subscription!.packages[0] as any)[`price_${monthlyPeriod}_month`]) {
        return (brand.subscription!.packages[0] as any)[`price_${monthlyPeriod}_month`];
    }
    return monthlyPeriod * periodPrice;
}
