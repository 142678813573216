import { loginAs } from "@app/actions/brand-actions";
import { sessionStore } from "@app/stores/SessionStore";
import { Button } from "antd";
import * as React from "react";
import { useState } from "react";

interface IProps {
    id: number;
    stage: string;
    small?: boolean;
    link?: boolean;
    style?: React.CSSProperties;
}

export const LoginButton: React.FC<IProps> = ({ id, small, link, children, ...props }) => {
    const [loading, setLoading] = useState(false);

    if (sessionStore.user.role.level <= 3) {
        return null;
    }

    const onClick = () => {
        setLoading(true);
        loginAs(id, props.stage, () => setLoading(false));
    };

    if (link) {
        return (
            <a onClick={onClick} {...props}>
                {children ?? "Login"}
            </a>
        );
    }

    return (
        <Button loading={loading} icon={small ? undefined : "login"} onClick={onClick} {...props}>
            {children ?? "Login"}
        </Button>
    );
};
