import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { brandTableStore } from "./brandTableStore";
import { ImportMenuModal } from "@pages/brands/BrandForm/ImportMenu";
import { ImportMenuXLS } from "@pages/brands/BrandForm/ImportMenuXLS";
import { ClientForm } from "@app/pages/clients/ClientForm/ClientForm";

@observer
export class BrandsPage extends React.Component {
    public componentDidMount(): void {
        brandTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return (
            <div>
                <h2>Brands ({brandTableStore.dataProvider.pagination.total})</h2>
                <div>
                    <ClientForm />
                    <DataTable store={brandTableStore} bordered />
                    <ImportMenuModal />
                    <ImportMenuXLS />
                </div>
            </div>
        );
    }
}
