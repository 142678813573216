import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import moment from "moment";

if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
}

const baseOptions: Highcharts.Options = {
    xAxis: {
        type: "datetime",
        margin: 20
    },
    yAxis: {
        title: {
            text: ""
        },
    },
    chart:{
        zoomType: "x",
    },
    legend: {
        enabled: true,
    },
    credits: {
        enabled: false,
    }
};

interface IProps {
    title: string;
    series: Highcharts.SeriesOptionsType[];
    isMonthly?: boolean;
    extraOptions?: Highcharts.Options;
}
export const Chart: React.FC<IProps> = ({ series, title, isMonthly, extraOptions }) => {
    const ref = React.useRef<{ chart: Highcharts.Chart, container: any }>(null);
    React.useEffect(() => {
        ref.current?.chart.reflow();
    });

    const options: Highcharts.Options = {
        ...baseOptions,
        tooltip: {
            formatter() {
                const format = isMonthly ? moment(this.x).format("LL") : new Date(this.x * 1000).toDateString();
                return `<div>
                    ${this.point.name ?? format} | <strong>${this.y}</strong><br/>
                    ${this.point.options.custom?.join(", ")}
                </div>`;
            }
        },
        title: {
            text: title
        },
        series,
        ...extraOptions
    };

    return <div style={{ flexBasis: "30%" }} className="widget">
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={ref}
        />
    </div>;
}
