export interface INavListItem {
    label: string;
    to?: string;
    icon: string;
    disabled?: boolean;
    type?: string;
    onlyShowUsers?: number[];
    children?: INavListItem[];
}

export const navList: INavListItem[] = [
    { label: "Dashboard", to: "/", icon: "home" },
    { label: "Absents", to: "/absents", icon: "fall" },
    { label: "Users", to: "/users", icon: "team" },
    {
        label: "Brands" , icon: "shop", children : [
            { label: "List", to: "/brands", icon: "shop" },
            { label: "Clients", to: "/clients", icon: "contacts" },
            { label: "Partners", to: "/partners", icon: "team" },
            { label: "Packages", to: "/packages", icon: "block" },
            { label: "Stories", to: "/stories", icon: "pic-left" },
            { label: "Venues", to: "/venues", icon: "shop" },
            { label: "Terminals", to: "/terminals", icon: "tablet" },
        ]
    },

    { label: "Translations", to: "/translations", icon: "global" },

    { label: "Transactions", to: "/transactions", icon: "transaction" },
    { label: "Tags", to: "/tags", icon: "tags" },
    { label: "Clopos Pay", to: "/clopos-pay-transactions", icon: "transaction" },

    {
        label: "Logs", icon: "tool", children : [
            { label: "Brand data history", to: "/logs/brand", icon: "tool" },
            { label: "Delivery service", to: "/logs/delivery-service", icon: "tool" },
        ]
    },
    {
        label: "Tools",  icon: "tool", children : [
            { label: "Maintenance Mode", to: "/tools/maintenance-mode", icon: "tool" },
            { label: "Databases", onlyShowUsers: [1, 3, 35], to: "/tools/db-configs", icon: "tool" }
        ]
    },

    // {label: "Last login", to: "/last-login", icon: "clockcircle"},
];
