import { Button, Dropdown, Icon, Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { observer } from "mobx-react";
import * as React from "react";
import { TerminalDataViewerStore } from "./TerminalDataViewerStore";

interface IProps {
  store: TerminalDataViewerStore;
}

@observer
export class ViewerButton extends React.Component<IProps> {

    public onClick = async ({ key }: ClickParam): Promise<void> => {
        this.props.store.show(key);
    }

    public menu = (): JSX.Element => <Menu onClick={this.onClick}>
        <Menu.Item key="receipt">
            Receipts
        </Menu.Item>
        <Menu.Item key="customer">
            Customers
        </Menu.Item>
    </Menu>

    public render(): JSX.Element {

        return <Dropdown trigger={["click"]} overlay={this.menu()}>
            <Button loading={this.props.store.loading}>
                Data
            <Icon type="down" />
            </Button>
        </Dropdown>;
    }
}
