import * as React from "react";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { transactionFormStore } from "@pages/transactions/TransactionForm/TransactionFormStore";
import { TransactionForm } from "@pages/transactions/TransactionForm/TransactionForm";
import { refineTransactionFromApi } from "@app/actions/transaction-actions";
import { TransactionStatusTag } from "@app/components/misc/TransactionStatusTag";
import { BrandStatus, TransactionStatus } from "@config/enums";
import { format, startOfMonth, sub, subDays } from "date-fns";
import { partnerTableStore } from "@app/pages/partners/PartnerPage/partnerTableStore";

export const transactionTableStore = new DataTableStore<ITransaction>({
    dataProvider: new DataProvider({
        url: endpoints.transaction,
        sort: ["created_at", -1],
        refiner: refineTransactionFromApi,
        with: ["brand.partner"],
        filters: [
            {
                name: "method",
                filter: ["method", "!=", "REMAIN"],
            },
            {
                name: "start-date",
                filter: ["created_at", ">=", format(startOfMonth(new Date()), "yyyy-MM-dd")],
            },
        ],
    }),
    formStore: () => transactionFormStore,
    form: TransactionForm,
    columnsManager: {
        hiddenColumns: ["id"],
    },
    filters: [
        {
            name: "status",
            type: "dropdown",
            defaultValue: TransactionStatus.SUCCESS,
            values: {
                [TransactionStatus.SUCCESS]: "Success",
                [TransactionStatus.FAILED]: "Failed",
                [TransactionStatus.PENDING]: "Pending",
            },
        },
        {
            placeholder: "Search by brand name",
            name: "brand_name",
            type: "input",
        },
        {
            name: "country",
            label: "Country",
            type: "dropdown",
            values: {
                "": "All",
                AZ: "AZ",
                UZ: "UZ",
                KZ: "KZ",
                TR: "TR",
                RU: "RU",
                GE: "GE",
            },
        },
        {
            name: "method",
            label: "Type",
            type: "dropdown",
            values: {
                "": "All",
                CASH: "Cash",
                PAYRIFF: "Payriff",
                YIGIM: "Yigim",
                STRIPE: "Stripe",
                MULTICARD: "Multicard",
                RAHMAT: "Rahmat",
            },
        },
        {
            name: "partnerId",
            label: "Partner",
            type: "dropdown-search-key-value",
            placeholder: "Partner",
            endpoint: "partners",
            defaultValue: "___",
        },
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 150,
        },
        {
            title: "Name",
            dataIndex: "brand.name",
            key: "brand.name",
            align: "center",
            width: 150,
        },
        {
            title: "Brand",
            dataIndex: "brand.title",
            key: "brand.title",
            align: "center",
            width: 150,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center",
            width: 150,
        },
        {
            title: "Type",
            dataIndex: "method",
            key: "method",
            align: "center",
            width: 150,
        },
        {
            title: "Partner / Dealer",
            dataIndex: "brand.partner.name",
            key: "partner",
            align: "center",
            width: 150,
            render: (value: string, record: ITransaction) => {
                const parent = partnerTableStore.dataProvider.list.find(
                    partner => partner.id === record.brand?.partner?.parent_id,
                );

                return (
                    <div>
                        <div>{record.brand?.partner?.name}</div>
                        {parent && <div>/ {parent.name}</div>}
                    </div>
                );
            },
        },
        {
            title: "Country",
            dataIndex: "brand.country",
            key: "country",
            align: "center",
            width: 75,
        },
        {
            title: "Reference",
            dataIndex: "reference",
            key: "reference",
            align: "center",
            width: 150,
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 150,
        },
        {
            title: "Method reference",
            dataIndex: "method_reference",
            key: "method_reference",
            align: "center",
            width: 150,
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            align: "center",
            width: 150,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 150,
            render: status => <TransactionStatusTag status={status} />,
        },
    ],
});
