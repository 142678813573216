import React from "react";

const ContentWithLabel = ({ label, required, children, style }: { label: string, required?: boolean, children: any, style?: any }) => {
    return (
        <div className="ant-row ant-form-item" style={style}>
            <div className="ant-col ant-form-item-label">
                <label className={required ? "ant-form-item-required" : ""} title={label}>{label}</label>
            </div>
            <div className="ant-col ant-form-item-control-wrapper">
                {children}
            </div>
        </div>
    )
}

export default ContentWithLabel;