import { SmallBrandsTable } from "@app/components/SmallBrandsTable";
import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { ajax } from "@app/lib/ajax";
import React, { useEffect, useState } from "react";

export const NotPayingBrands: React.FC = ({}) => {

    const [data, setData] = useState<IBrand[]>([]);

    useEffect(() => {
        
        const filters = [
            ["status", BrandStatus.ACTIVE],
            ["stage_status", BrandStageStatus.DEFAULT],
            ["total_amount", 0],
            ["partner_id", 1], // Only azerbaijan
        ];
        ajax.get<IBrand[]>({
            url: "/brand",
            params: {
                limit: 999,
                with: ["client", "subscription.packages", "transactions"],
                filters
            }
        }).then(resp => {
            // const list = resp.data.filter(hasNoValidTransaction);

            setData(resp.data);
        });
    }, []);


    return <SmallBrandsTable title={`Not Paying brands (${data.length})`}
        data={data} />
}

function hasNoValidTransaction(b:IBrand){
    return b.transactions?.filter(t => t.status === "SUCCESS" && t.amount > 0).length === 0
}