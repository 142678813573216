import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { ClientForm } from "../ClientForm/ClientForm";
import { clientTableStore } from "./clientTableStore";

@observer
export class ClientsPage extends React.Component {

    public componentDidMount(): void {
        clientTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Clients</h2>
            <div>
                <DataTable store={clientTableStore} bordered />
            </div>
        </div>;
    }
}
