import { copyToClipboard } from "@app/lib/utils";
import { Button, Icon } from "antd";
import React from "react";

export const CopyButton: React.FC<{ text: string; style?: React.CSSProperties; value?: string }> = ({
    text,
    style,
    value,
}) => {
    const [copied, setCopied] = React.useState(false);
    const textToCopy = value ?? text;
    const onClick = () => {
        copyToClipboard(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Button onClick={onClick} style={style}>
            {copied ? <span style={{ color: "green" }}>copied</span> : text}
            <Icon type="copy" />
        </Button>
    );
};
