import * as React from "react";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { observable } from "mobx";
import { Button, Icon, Input, Tooltip } from "antd";
import { clientFormStore } from "../ClientForm/ClientFormStore";
import { ClientForm } from "../ClientForm/ClientForm";
import { DataProvider } from "@app/components/DataTable/DataProvider";

const state = observable({
    searchText: "",
});

const handleSearch = (field: string, selectedKeys: any, confirm: any) => () => {
    confirm();
    state.searchText = selectedKeys[0];
    clientTableStore.dataProvider.addFilter([field, selectedKeys[0]], field);
};

const handleReset = (field: string, clearFilters: any) => () => {
    clearFilters();
    state.searchText = "";
    clientTableStore.dataProvider.removeFilter(field);
};

export const clientTableStore = new DataTableStore<IClient>({
    dataProvider: new DataProvider({
        url: endpoints.client,
    }),
    formStore: () => clientFormStore,
    form: ClientForm,
    columnsManager: {
        hiddenColumns: ["created_at"]
    },
    filters: [
        {
            name: "email",
            label: "E-mail",
            type: "dropdown-search",
            endpoint: "client",
            values: {},
            defaultValue: "___"
        }
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50
        },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            align: "center",
            width: 300,
            render: renderEmail,
            // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
            //     return <div style={{padding: 10, background: "#fff", boxShadow: "0 0 3px #666", borderRadius: 5}}>
            //         <Input
            //             placeholder="Search e-mail"
            //             value={selectedKeys[0]}
            //             onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            //             onPressEnter={handleSearch("email", selectedKeys, confirm)}
            //             style={{marginBottom: 5}}
            //         />
            //         <Button type="primary" onClick={handleSearch("email", selectedKeys, confirm)}
            //             style={{marginRight: 5}}>Search</Button>
            //         <Button onClick={handleReset("email", clearFilters)}>Reset</Button>
            //     </div>;
            // }
        },
        {
            title: "First name",
            dataIndex: "first_name",
            key: "first_name",
            align: "center",
            width: 100
        },
        {
            title: "Last name",
            dataIndex: "last_name",
            key: "last_name",
            align: "center",
            width: 100
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            align: "center",
            width: 100,
            // render: (status) => <ClientStatusTag status={status} />
        },
        {
            title: "Last login",
            dataIndex: "login_at",
            key: "login_at",
            align: "center",
            width: 100
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 100
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions
        },
    ]
});

const editBtn = editButton(clientTableStore);
const deleteBtn = deleteButton(clientTableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
    </>;
}

function renderEmail(email: string, client: IClient): JSX.Element {
    const props = client.email_verified_at ? {
        type: "check-circle",
        title: "Email is verified",
        style: { color: "green", marginLeft: 10 }
    } : {
            type: "close-circle",
            title: "Email is not verified",
            style: { color: "red", marginLeft: 10 }
        };
    return <span>
        {email}
        <Tooltip title={props.title}>
            <Icon {...props} />
        </Tooltip>
    </span>;
}
