import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { Button, Drawer, Input, message, Popconfirm, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { ajax } from "@lib/ajax";
import { LoadingCenter } from "@app/components/misc/LoadingCenter";
import { observer } from "mobx-react";

interface IVenueItem {
    isDeleted: boolean;
    deleteDisabled: boolean;
    brandId: number | undefined;
    venue: IVenue;
    onDelete: (id: number) => void;
    refreshVenues: () => void;
}

const IntegrationsDrawer = observer(
    ({
        venue,
        integrations,
        onClose,
        subscribe,
        unSubscribe,
        loading,
    }: {
        venue: IVenue;
        integrations: IIntegration[] | null;
        onClose: () => void;
        subscribe: (moduleId: number) => void;
        unSubscribe: (moduleId: number) => void;
        loading?: boolean;
    }) => {
        const modulesHasIntegration = brandFormStore.data.subscription?.packages.filter(
            p => p.type === "MODULE" && p.integration_name,
        );

        const integrationItemStyle: React.CSSProperties = {
            borderBottom: "1px solid rgb(0 0 0 / 17%)",
            display: "flex",
            justifyContent: "space-between",
            padding: "8px",
        };

        return (
            <>
                <Drawer
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            Integrations for {venue.name}
                            <Button onClick={onClose}>Close</Button>
                        </div>
                    }
                    onClose={onClose}
                    visible={true}
                    placement="right"
                    width={500}
                    closable={false}
                >
                    {loading ? (
                        <LoadingCenter />
                    ) : (
                        modulesHasIntegration?.map(m => (
                            <div key={m.id} style={integrationItemStyle}>
                                <div>{m.title}</div>
                                <Switch
                                    checked={integrations?.some(i => i.service_name === m.integration_name)}
                                    onChange={checked => (checked ? subscribe(m.id) : unSubscribe(m.id))}
                                />
                            </div>
                        ))
                    )}
                </Drawer>
            </>
        );
    },
);

const VenueItem = ({ brandId, venue, isDeleted, deleteDisabled, onDelete, refreshVenues }: IVenueItem) => {
    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdt, setIsLoadingUpdt] = useState(false);
    const [venueStatus, setVenueStatus] = useState(venue.status);
    const [venueName, setVenueName] = useState(venue.name);
    const [isIntegrationDrawerOpen, setIntegrationDrawerOpen] = useState(false);

    const [integrations, setIntegrations] = useState<IIntegration[] | null>(null);

    const fetchIntegrations = async () => {
        const response = await ajax.get<IIntegration[]>({
            url: `/brand/${brandId}/venues/${venue.id}/integrations`,
        });
        setIntegrations(response.data);
    };

    const handleSubscribe = async (moduleId: number, type: "subscribe" | "unSubscribe") => {
        setIsLoading(true);
        try {
            const response = await ajax.get({
                url: `/brand/${brandId}/venues/${venue.id}/${moduleId}/${type}`,
            });

            if (response.success) {
                await fetchIntegrations();
            } else {
                message.error(response.message || "Something went wrong");
            }
        } catch (e) {
            message.error(e?.response?.message || "Something went wrong");
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!integrations) {
            fetchIntegrations();
        }
    }, []);

    const handleDelete = async () => {
        setIsLoading(true);
        await brandFormStore
            .deleteVenue(brandId, venue.id)
            .then(res => {
                message.success(res?.message || "Deleted. It will take a few minutes to complete the process.");
                onDelete(venue.id);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const handleUpdate = async () => {
        setIsLoadingUpdt(true);
        await brandFormStore
            .updateVenue(brandId, venue.id, venueName, venueStatus === true ? 1 : 0)
            .then(res => {
                message.success(res?.message || "Updated. It will take a few minutes to complete the process.");
                setIsLoadingUpdt(false);
                setEditMode(false);
                refreshVenues();
            })
            .catch(() => setIsLoadingUpdt(false));
    };
    const style: React.CSSProperties = {
        display: "flex",
        gap: 10,
        padding: 5,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: isDeleted ? "not-allowed" : "unset",
        backgroundColor: isDeleted ? "#f5f5f5" : "unset",
    };

    return (
        <>
            {isIntegrationDrawerOpen ? (
                <IntegrationsDrawer
                    venue={venue}
                    integrations={integrations}
                    onClose={() => setIntegrationDrawerOpen(false)}
                    subscribe={moduleId => handleSubscribe(moduleId, "subscribe")}
                    loading={isLoading}
                    unSubscribe={moduleId => handleSubscribe(moduleId, "unSubscribe")}
                />
            ) : null}
            <div style={style}>
                {!isEditMode && (
                    <label>
                        (ID: <b>{venue.real_venue_id}</b>) {venue.name}
                    </label>
                )}
                {isEditMode && (
                    <>
                        <Input
                            disabled={isDeleted}
                            placeholder={venue.name}
                            value={venueName}
                            onChange={e => setVenueName(e.target.value)}
                        />
                        <Switch checked={venueStatus} onChange={checked => setVenueStatus(checked)} />
                    </>
                )}
                <div style={{ display: "flex", gap: 10 }}>
                    {!isEditMode && (
                        <Button
                            disabled={isDeleted}
                            type="dashed"
                            style={{ margin: 0 }}
                            onClick={() => setEditMode(true)}
                        >
                            Edit
                        </Button>
                    )}
                    {!isEditMode && (
                        <Button
                            disabled={isDeleted}
                            type="dashed"
                            style={{ margin: 0 }}
                            onClick={() => setIntegrationDrawerOpen(true)}
                        >
                            Integrations
                        </Button>
                    )}
                    {isEditMode && (
                        <Button
                            disabled={isDeleted}
                            loading={isLoadingUpdt}
                            type="primary"
                            style={{ margin: 0 }}
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    )}
                    {!deleteDisabled && (
                        <Popconfirm disabled={isDeleted} title="Are you sure?" onConfirm={handleDelete}>
                            <Button disabled={isDeleted} loading={isLoading} type="danger" style={{ margin: 0 }}>
                                Delete
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
        </>
    );
};

export default observer(VenueItem);
