import _ from "lodash";
import {PaginationProps} from "antd/lib/pagination";
import {action, observable, toJS} from "mobx";
import {attempt} from "@app/lib/utils";
import {ajax, IAjaxConf} from "@app/lib/ajax";
import {actionByName, deleteData} from "@app/lib/api-utils";

export interface IDataProviderConf<T extends IData> {
    url: string;
    refiner?: TypeAccepterType<T>;
    dataPath?: string;
    pagination?: PaginationProps;
    with?: string[];
    token?: string;
    method?: "get" | "post";
    sort?: any[];
    filters?: IFilter[];
}

interface IFilter {
    filter: SearchRequestFilter | SearchRequestFilter[];
    name: string;
}

const DEFAULT_PAGE_SIZE = 50;

export class DataProvider<T extends IData> {

    @observable public loading = false;
    @observable public list: T[] = [];
    @observable private originalList: T[] = [];
    @observable public params: any = {};
    @observable public pagination: PaginationProps = {
        pageSize: DEFAULT_PAGE_SIZE,
        pageSizeOptions: ["20","50", "100", "500", "1000"],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        // hideOnSinglePage: true,
        onChange: this.onPageChange.bind(this),
        onShowSizeChange: this.onPageSizeChange.bind(this),
    };

    @observable public filters = [];
    /*
       Stores indexes of filters defined by their filterName.
       It is required because there can be several filters for the same field
     */
    private filtersMap: { [index: string]: SearchRequestFilter | SearchRequestFilter[] } = {};

    public filterByFn(fn: (d: T) => boolean): void {
        if (this.originalList.length === 0) {
            this.originalList = toJS(this.list);
        }
        this.list = this.originalList.filter(fn);
    }

    public resetFilterByFn(): void {
        this.list = this.originalList;
    }

    public conf: IDataProviderConf<T> = {
        url: "",
        dataPath: "data",
        with: [],
    };

    constructor(conf: IDataProviderConf<T>) {
        _.assign(this.conf, conf);
        this.pagination = conf.pagination || this.pagination;
        this.params.with = conf.with || [];
        this.params.sort = conf.sort || [];
        conf.filters?.map(f => {
            this.addFilter(f.filter, f.name);
        });
    }

    public setConfig(name: keyof IDataProviderConf<T>, value: any): void {
        this.list = [];
        this.conf[name] = value;
    }

    private getData(resp: IApiResponse): any {
        return (resp as any)[this.conf.dataPath!];
    }

    @action public deleteItem = async (d: T) => {
        await deleteData(this.conf.url, d.id!);
        this.loadData();
    }
    @action public clearAction = async (d: T, actionName: string, actionParams?: any) => {
        const stage = actionParams ? actionParams : undefined;
        return await actionByName(this.conf.url, d.id!, actionName, stage);
        // this.loadData();
    }


    @action
    public getPureData = async (): Promise<any> => {
        return await ajax.load(this.ajaxConfig);
    };



    @action
    public loadData = async (): Promise<void> => {
        return attempt(
            async () => {
                this.loading = true;
                const resp = await ajax.load(this.ajaxConfig);
                this.pagination!.total = resp.total;
                this.list = this.conf.refiner ? this.getData(resp).map(this.conf.refiner) : this.getData(resp);
            },
            undefined,
            () => {
                this.loading = false;
            }
        );
    };

    get ajaxConfig(): IAjaxConf {
        const method = this.conf.method || "get";
        // const dataKey = method == "get" ? "params" : "data";
        return {
            url: this.conf.url,
            method,
            params: {
                ...this.params,
                limit: this.pagination!.pageSize || DEFAULT_PAGE_SIZE,
                page: this.pagination!.current,
            },
            headers: this.ajaxHeaders,
        };
    }

    get ajaxHeaders(): any {
        return this.conf.token ? {
            Authorization: `Bearer ${this.conf.token}`
        } : {};
    }

    @action
    public updateLocalCopyOfData = (d: T, assign: boolean = false) => {
        const i = this.list.findIndex(u => u.id === d.id);
        if (i > -1) {
            if (assign) {
                Object.assign(this.list[i], d);
            } else {
                this.list[i] = d;
            }
        } else {
            this.loadData();
        }
    };

    public onPageChange(page: number, limit?: number): void {
        this.pagination!.current = page;
        this.pagination!.pageSize = limit;
        this.loadData();
    }

    public onPageSizeChange(current: number, size: number): void {
        this.pagination!.current = current;
        this.pagination!.pageSize = size;
        this.loadData();
    }

    /**
     *
     * @param {SearchRequestFilter} filter
     * @param {string} filterName - Each filter in DataProvider must have a filterName different from field name
     */
    public addFilter(filter: SearchRequestFilter | SearchRequestFilter[], filterName: string): void {
        this.filtersMap[filterName] = filter;
        this.params.filters = _.values(this.filtersMap);
    }

    public getFilter(name: string): SearchRequestFilter | SearchRequestFilter[] {
        return this.filtersMap[name];
    }

    /**
     *
     * @param filterName
     */
    public removeFilter(filterName: string): void {
        delete this.filtersMap[filterName];
        this.params.filters = _.values(this.filtersMap);
    }

    public removeAllFilters(): void {
        this.params.filters = [];
        this.filtersMap = {};
    }
}
