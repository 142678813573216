import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { transactionTableStore } from "@pages/transactions/TransactionsPage/transactionTableStore";
import moment from "moment";
import { MonthlyTransactionsChart } from "@app/pages/dashboard-new/widgets/MonthlyTransactionsChart";
import { partnerTableStore } from "@app/pages/partners/PartnerPage/partnerTableStore";

@observer
export class TransactionsPage extends React.Component {
    public componentDidMount(): void {
        // noinspection JSIgnoredPromiseFromCall
        transactionTableStore.dataProvider.loadData();
        partnerTableStore.dataProvider.loadData();
    }

    public get calculateLast30DaysTotalRevenue(): number {
        const totalAmount = transactionTableStore.dataProvider.list
            .filter(t => moment().diff(moment(t.created_at), "days") <= 30)
            .reduce((total, trans) => trans.amount + total, 0);
        return parseFloat(totalAmount.toString());
    }

    public render(): JSX.Element {
        return (
            <div>
                <MonthlyTransactionsChart />
                <br />
                <h2>Transactions</h2>
                <div>
                    <DataTable store={transactionTableStore} bordered />
                </div>
            </div>
        );
    }
}
