import { MetricBox } from "@app/components/MetricBox";
import { Money } from "@app/components/Money";
import { API_URL } from "@app/config/main";
import { ajax } from "@app/lib/ajax";
import moment from "antd/node_modules/moment";
import _, { divide } from "lodash";
import { toJS } from "mobx";
import { useLocalStore } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { refineSubscription } from "../utils";


export const PaymentsMetricBox: React.FC = () => {

    const [data, setData] = useState<ITotalData[]>([]);

    useEffect(() => {
        const start = moment().subtract(1, "month").format("YYYY-MM");
        const end = moment().format("YYYY-MM");
        ajax.get({ url: `${API_URL}transaction/monthly?date[]=${start}&date[]=${end}` }).then(resp => {
            setData(_.sortBy(refineSubscription(resp), "date"));
        });
    }, []);

    return <MetricBox title="This month"
        value={<Money value={data[1]?.count} />}
        value2={<Money value={data[0]?.count} />}
        extra={<div>
            <span>Brands: </span>
            <span>{data[1]?.unique_brand_count} / </span>
            <span>{data[0]?.unique_brand_count}</span>
        </div>}
    />;
};
