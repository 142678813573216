import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import * as React from "react";
import { tagFormStore } from "../form/formStore";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { form } from "../form/form";
import { DataProvider } from "@app/components/DataTable/DataProvider";

export const tableStore = new DataTableStore<ITag>({
    dataProvider: new DataProvider({
        url: endpoints.tags,
    }),

    formStore: () => tagFormStore,
    form,
    columnsManager: {
        hiddenColumns: [],
    },
    filters: [
        //type filter
        {
            name: "type",
            searchable: false,
            label: "Type",
            type: "dropdown",
            isLocal: true,
            defaultValue: "___",
            values: {
                "brand": "Brand",
                "package": "Package",
            }
        }
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 50,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 150,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            align: "center",
            width: 300,
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            align: "center",
            width: 300,
        },
        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
            align: "center",
            width: 300,
        },

        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions,
        },
    ],
});

const editBtn = editButton(tableStore);
const deleteBtn = deleteButton(tableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
    </>;
}
