import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { eSet } from "@app/lib/utils";
import { Button, Form, Input, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactJson from "react-json-view";
import { packageFormStore } from "./PackegFormStore";
import PackagCountryPricingDrawer from "./PackagCountryPricingDrawer";
import { ajax } from "@app/lib/ajax";
import { endpoints } from "@app/config/endpoints";
import { packageTableStore } from "../PackagesPage/packageTableStore";

@observer
class PackageFormBare extends React.Component<FormComponentProps, { drawer: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {
            drawer: false
        }
    }

    public componentDidMount(): void {
        packageFormStore.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                packageFormStore.save();
            }
        });
    }

    public createPackagePriceRequest = async (data: IPackagePrice | null, priceID: number | null) => {
        const pack = packageFormStore.data;
        await ajax.post({
            url: endpoints.packagePrices + (priceID ? `/${priceID}?_method=put` : ""),
            data
        })
            .then(async () => {
                await packageTableStore.dataProvider.loadData();
                const updatedData = (packageTableStore.dataProvider.list as any[]).find((d: IPackagePrice) => d.id === pack.id);
                packageFormStore.setData(packageFormStore.dummyDataFn(updatedData));
            })
            .catch(ex => {
                message.error(ex.toString());
            });
    }

    public deletePackagePriceRequest = async (priceID: number) => {
        const pack = packageFormStore.data;
        await ajax.delete({
            url: endpoints.packagePrices + `/${priceID}`,
        })
            .then(async () => {
                await packageTableStore.dataProvider.loadData();
                const updatedData = (packageTableStore.dataProvider.list as any[]).find((d: IPackagePrice) => d.id === pack.id);
                packageFormStore.setData(packageFormStore.dummyDataFn(updatedData));
            })
            .catch(ex => {
                message.error(ex.toString());
            });
    }

    public render(): JSX.Element {
        const pack = packageFormStore.data;
        const { getFieldDecorator } = this.props.form;
        return <>
            <FormDrawer store={packageFormStore}>
                <PackagCountryPricingDrawer
                    drawer={this.state.drawer}
                    elementData={pack}
                    closeDrawer={() => this.setState({ drawer: false })}
                    createRequest={this.createPackagePriceRequest}
                    deleteRequest={this.deletePackagePriceRequest}
                />

                <Form layout="vertical" onSubmit={this.handleSubmit} >
                    <Form.Item label="Name" >
                        {getFieldDecorator("name", {
                            initialValue: pack.name,
                            rules: [{ required: true }]
                        })(
                            <Input placeholder="name" onChange={eSet(pack, "name")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Title" >
                        {getFieldDecorator("title", {
                            initialValue: pack.title,
                            rules: [{ required: true }]
                        })(
                            <Input placeholder="Title" onChange={eSet(pack, "title")} />
                        )}
                    </Form.Item>

                    {/*<Form.Item label="Price" >*/}
                    {/*    {getFieldDecorator("price", {*/}
                    {/*        initialValue: pack.price,*/}
                    {/*        rules: [{ required: true }]*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="price" onChange={eSet(pack, "price")} />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item label="Price for 3 month" >*/}
                    {/*    {getFieldDecorator("price_3_month", {*/}
                    {/*        initialValue: pack.price_3_month,*/}
                    {/*        rules: [{ required: true }]*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="Price for 3 month" type="number" onChange={eSet(pack, "price_3_month")} />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item label="Price for 6 month" >*/}
                    {/*    {getFieldDecorator("price_6_month", {*/}
                    {/*        initialValue: pack.price_6_month,*/}
                    {/*        rules: [{ required: true }]*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="Price for 6 month" type="number" onChange={eSet(pack, "price_6_month")} />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item label="Price for 12 month" >*/}
                    {/*    {getFieldDecorator("price_12_month", {*/}
                    {/*        initialValue: pack.price_12_month,*/}
                    {/*        rules: [{ required: true }]*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="Price for 12 month" type="number" onChange={eSet(pack, "price_12_month")} />*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    <Form.Item label="Pricing by countries" >
                        <Button disabled={!pack?.id} type="default" onClick={() => this.setState({ drawer: true })} htmlType="button">Customize ({pack?.prices?.length || 0})</Button>
                    </Form.Item>

                    <Button type="primary" loading={packageFormStore.loading}
                        htmlType="submit" style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={packageFormStore.hide} htmlType="button">Cancel</Button>

                    <ReactJson src={toJS(pack)} collapsed={1} name={packageFormStore.dataName} />
                </Form>
            </FormDrawer>
        </>;
    }
}

export const PackageForm = Form.create()(PackageFormBare);
