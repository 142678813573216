import {brandFormStore} from "@pages/brands/BrandForm/BrandFormStore";
import {Button, Input, Select} from "antd";
import * as React from "react";
import {observer} from "mobx-react";

export const DeleteMenuOption = observer((): JSX.Element | null => {
    return <Input.Group compact>
        <Select
            mode="multiple"
            style={{width: "80%"}}
            placeholder="Select Product type to remove"
            onChange={brandFormStore.setDeletingProductType}>
            {brandFormStore.productTypes.map(c => <Select.Option key={c} value={c}>
                {c}
            </Select.Option>)}
        </Select>
        <Button type="danger" icon="delete" loading={brandFormStore.loading}
                disabled={!brandFormStore.selectedProductTypes.length}
                onClick={brandFormStore.onDeleteProduct}/>
    </Input.Group>;
});
