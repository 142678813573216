import { Actions } from "@app/config/main";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { DeleteMenuOption } from "@app/pages/brands/BrandForm/DeleteMenuOption";
import { deleteBtn, importMenuBtn } from "@app/pages/brands/BrandsPage/brandTableStore";
import { sessionStore } from "@app/stores/SessionStore";
import { Button, Input, Modal, Popconfirm, Select, Spin } from "antd";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { FC, ReactNode } from "react";

export const BrandFormActionsTab: FC = observer(props => {
    const brand = brandFormStore.data;

    const disableActionBtn =
        !brandFormStore.actionName ||
        (brandFormStore.actionName === "AnbarTest" && !brandFormStore.actionStageName) ||
        (brandFormStore.actionName === "fillMenu" && !brandFormStore.actionVenueId);
    const isStockTest = brandFormStore.actionName && brandFormStore.actionName === "AnbarTest";
    const isCloneMenuAction = brandFormStore.actionName && brandFormStore.actionName === "fillMenu";

    return (
        <>
            <div>
                {/* <Divider orientation="left">Brand actions</Divider> */}
                <div>
                    <Input.Group compact>
                        {/*{["clearInventory", "clearSale", "clearFinance"].includes(*/}
                        {/*    brandFormStore.actionName,*/}
                        {/*) ? (*/}
                        {/*    <DatePicker*/}
                        {/*        allowClear*/}
                        {/*        placeholder={"Until date"}*/}
                        {/*        onChange={value => {*/}
                        {/*            brandFormStore.actionSelectUntilDate =*/}
                        {/*                value?.format("YYYY-MM-DD");*/}
                        {/*        }}*/}
                        {/*        format={"YYYY-MM-DD"}*/}
                        {/*        name={"until"}*/}
                        {/*    />*/}
                        {/*) : null}*/}
                        {!isCloneMenuAction && (
                            <Select
                                style={{ width: 150 }}
                                placeholder="Select a venue"
                                onChange={brandFormStore.onActionVenueSelect}
                            >
                                {brandFormStore.venues.map(c => (
                                    <Select.Option key={c.id} value={c.id}>
                                        {c.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                        <Select
                            style={{ width: 150 }}
                            placeholder="Select an action"
                            onChange={brandFormStore.onActionChange}
                        >
                            {_.map(Actions, (keys: string, value: string) => (
                                <Select.Option key={value} value={value}>
                                    {keys}
                                </Select.Option>
                            ))}
                            {brand.id === 218 && <Select.Option value="AnbarTest">Anbar Test</Select.Option>}
                        </Select>
                        {isStockTest && (
                            <Select
                                style={{ width: 100 }}
                                placeholder="Select a stage"
                                onChange={brandFormStore.onActionStageChanged}
                            >
                                <Select.Option value="alpha">Alpha</Select.Option>
                                <Select.Option value="beta">Beta</Select.Option>
                                <Select.Option value="rc">RC</Select.Option>
                                <Select.Option value="prod">Prod</Select.Option>
                            </Select>
                        )}
                        {isCloneMenuAction && (
                            <Select
                                style={{ width: 200 }}
                                placeholder="Select a Restaurant"
                                showSearch
                                optionFilterProp="children"
                                notFoundContent={brandFormStore.fetching ? <Spin size="small" /> : null}
                                onSearch={brandFormStore.onBrandSearch}
                                filterOption={false}
                                onChange={brandFormStore.onActionVenueChanged}
                            >
                                {brandFormStore.brands.map(c => (
                                    <Select.Option key={c.id} value={c.id}>
                                        {c.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                        <Popconfirm title="Are you sure?" onConfirm={() => onRun(brand)} disabled={disableActionBtn}>
                            <Button type="danger" disabled={disableActionBtn}>
                                run
                            </Button>
                        </Popconfirm>
                    </Input.Group>
                </div>
                <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                    <DeleteMenuOption />
                </div>
                <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>{importMenuBtn(brand)}</div>
            </div>
            {sessionStore.role?.level !== 3 && (
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                    }}
                >
                    {deleteBtn(brand)}
                </div>
            )}
        </>
    );
});

async function onRun(brand: IBrand): Promise<void> {
    const actionName = brandFormStore.actionName;
    const brandTitle = brand.title;
    let modal;
    let requestResp: any = {
        icon: "loading",
        title: `Running stock test for ${brandTitle}`,
        content: `Please wait until test has been finished`,
        okButtonProps: {
            disabled: true,
        },
    };
    if (actionName === "AnbarTest") {
        modal = Modal.info({
            width: 800,
            onOk: () => {},
            ...requestResp,
        });
    }
    try {
        const resp = await brandFormStore.onActionRun(brand);
        requestResp = {
            icon: "check",
            title: `Result of the test for ${brandTitle}`,
            content: (
                <div>
                    <pre>{resp.data.output}</pre>
                </div>
            ),
            okButtonProps: {
                disabled: false,
            },
        };
        if (actionName === "clone") {
            setTimeout(() => {
                window.open("https://clone.clopos.com");
            }, 5000);
        }
    } catch (e) {
        requestResp = {
            icon: "close",
            title: `Test has failed for ${brandTitle}`,
            content: `${e.msg || e.message}`,
            okButtonProps: {
                disabled: false,
            },
        };
    }
    if (modal) {
        modal.update(requestResp);
    }
}
