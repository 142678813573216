import { Button, Divider, Drawer } from "antd";
import React, { useState } from "react";
import Spreadsheet from "react-spreadsheet";
import * as XLSX from 'xlsx';

function ExcelRenderer(file: any, callback: any) {
    return new Promise(function (resolve) {
        var reader = new FileReader();
        var rABS = !!reader.readAsBinaryString;
        reader.onload = function (e: any) {
            var bstr = e.target.result;
            var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

            var wsname = wb.SheetNames[0];
            var ws = wb.Sheets[wsname];

            var json = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var cols = make_cols(ws["!ref"]);

            var data = { rows: json, cols: cols };

            resolve(data);
            return callback(null, data);
        };
        if (file && rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    });
}

function make_cols(refstr: any) {
    var o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
        o[i] = { name: XLSX.utils.encode_col(i), key: i };
    }
    return o;
}

const ExcelDrawer = ({ setImportDrawer, importDrawer, langs, submitExcelTranslations, loading }: any) => {
    const [excelData, setExcelData] = useState<any>([]);
    const [currentLang, setCurrentLang] = useState<any>(null);

    const fileHandler = (event: any) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err: any, resp: any) => {
            if (err) {
                setCurrentLang(null);
            } else {
                let lName = null;
                let refined: any[] = [];
                for (let x in resp.rows) {
                    const d = resp.rows[x];
                    if (d?.length !== 0) {
                        if (d[0] === "lang") {
                            lName = langs.find((ll: any) => ll.name === d[1]?.toLowerCase());
                        } else {
                            refined.push([{ value: d[0] }, { value: d[1] }])
                        }
                    }
                }
                setCurrentLang(lName);
                setExcelData(refined)
            }
        });
    }

    return (
        <div>
            <Drawer
                title={'Import translation'}
                placement="right"
                onClose={() => {
                    setImportDrawer(false)
                    setCurrentLang(null);
                    setExcelData([])
                }}
                visible={importDrawer}
                destroyOnClose
                width={1000}
            >
                <div style={{ display: "grid", marginBottom: 50 }}>
                    <input type="file" onChange={fileHandler} />
                    <Divider />
                    {excelData?.length !== 0 && <div>
                        <p>Language: <b>{currentLang?.title}</b></p>
                        <Spreadsheet data={excelData} onChange={setExcelData} />
                    </div>}
                </div>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button disabled={currentLang === null} loading={loading} onClick={() => submitExcelTranslations(excelData, currentLang)} type="primary">
                        Submit
                    </Button>
                </div>
            </Drawer>
        </div>
    )
}

export default ExcelDrawer;