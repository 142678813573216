import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { partnerTableStore } from "./partnerTableStore";

@observer
export class PartnerPage extends React.Component {

    public componentDidMount(): void {
        partnerTableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Partners</h2>
            <div>
                <DataTable store={partnerTableStore} bordered />
            </div>
        </div>;
    }
}
