import { terminalsApiUrl, getBrandById } from "@app/actions/brand-actions";
import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { brandTerminalsTableStore as store } from "./brandTerminalsTableStore";
import { terminalFormStore } from "../../TerminalForm/TerminalFormStore";

interface IParams {
    brandId: string;
}

interface IProps extends IPageProps<IParams> {

}

@observer
export class BrandTerminalsPage extends React.Component<IProps> {

    constructor(props: IProps) {
        super(props);

        store.dataProvider.setConfig("url", terminalsApiUrl(props.match.params.brandId));
        terminalFormStore.apiUrl = terminalsApiUrl(props.match.params.brandId);
        terminalFormStore.brand_id = parseInt(props.match.params.brandId);
    }

    public async componentDidMount(): Promise<void> {
        store.dataProvider.loading = true;
        const brand = await getBrandById(this.props.match.params.brandId as any);
        store.dataProvider.setConfig("refiner", (term: ITerminal) => {
            term.brand = brand.name;
            if (term.devices_setting) {
                term.devices_setting = JSON.parse(term.devices_setting);
            }
            return term;
        });
        store.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return <div>
            <h2>Terminals</h2>
            <div>
                <DataTable store={store} bordered />
            </div>
        </div>;
    }
}
