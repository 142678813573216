import React, { ReactNode } from "react";
import { Col, Form, Input, Row, Select, Spin, Tabs } from "antd";
import { FC } from "react";
import { FormComponentProps } from "antd/lib/form";
import { sessionStore } from "@app/stores/SessionStore";
import { CountriesAll, eSet, eSetValue, getEnumLabels, set } from "@app/lib/utils";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { partial } from "lodash";
import _ from "lodash";
import { BrandStage, BrandStageStatusOptions, BrandType } from "@app/data/brand-stages";
import { BrandSaleStatus, BrandStatus, Discountable } from "@app/config/enums";
import { useSellerList } from "@app/hooks/useSellerList";
import { observer } from "mobx-react";
import { usePartners } from "@app/hooks/usePartners";

interface IProps extends FormComponentProps {
    isCreate: boolean;
    handleSubmit: (e: any) => void;
    buttons: () => ReactNode;
}

export const BrandFormGeneralTab: FC<IProps> = observer(props => {
    const brand = brandFormStore.data;
    const getFieldDecorator = props.form.getFieldDecorator;
    const { sellers } = useSellerList();
    const { partners } = usePartners();

    return (
        <Form layout="horizontal" onSubmit={props.handleSubmit}>
            <div>
                {/* <Divider orientation="left">Brand details</Divider> */}
                {/* name */}
                <Form.Item label="Subdomain">
                    {getFieldDecorator("name", {
                        initialValue: brand.name,
                        rules: [
                            {
                                required: (sessionStore.role?.level || 0) > 3 && !_.isNil(brandFormStore._data.id),
                            },
                        ],
                    })(
                        <Input
                            addonAfter={".clopos.com"}
                            disabled={(sessionStore.role?.level || 0) <= 3 && !_.isNil(brandFormStore._data.id)}
                            placeholder="Subdomain"
                            onChange={eSet(brand, "name")}
                        />,
                    )}
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Title">
                            {getFieldDecorator("title", {
                                initialValue: brand.title,
                                rules: [{ required: true }],
                            })(<Input placeholder="Title" onChange={eSet(brand, "title")} />)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Password">
                            <Input.Password placeholder="input password" readOnly value={brand.client?.last_password} />
                        </Form.Item>
                    </Col>
                </Row>

                {/* client_id */}
                <Form.Item label={clientLabel()}>
                    {getFieldDecorator("client_id", {
                        initialValue: brand.client?.email,
                        rules: [{ required: true }],
                    })(
                        <Select
                            placeholder="Select client"
                            showSearch
                            onChange={brandFormStore.setClientId as any}
                            optionFilterProp="children"
                            notFoundContent={brandFormStore.fetching ? <Spin size="small" /> : null}
                            style={{ width: "100%" }}
                            onSearch={brandFormStore.onFetchClient}
                            filterOption={false}
                        >
                            {brandFormStore.clients.map(c => (
                                <Select.Option key={c.id} value={c.id}>
                                    {c.email}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>

                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Stage">
                            {getFieldDecorator("stage", {
                                initialValue: brand.stage,
                                rules: [{ required: true }],
                            })(
                                <Select onChange={_.partial(set, brand, "stage")}>
                                    {_.map(BrandStage, stage => (
                                        <Select.Option value={stage} key={stage}>
                                            {stage}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Stage Status">
                            {getFieldDecorator("stage_status", {
                                initialValue: brand.stage_status,
                                rules: [{ required: false }],
                            })(
                                <Select onChange={partial(set, brand, "stage_status")}>
                                    {BrandStageStatusOptions.map((stage, id) => (
                                        <Select.Option value={id} key={id}>
                                            {stage}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {!props.isCreate && (
                            <Form.Item label="Status">
                                {getFieldDecorator("status", {
                                    initialValue: brand.status,
                                    rules: [{ required: false }],
                                })(
                                    <Select onChange={partial(set, brand, "status")}>
                                        {_.map(getEnumLabels(BrandStatus), status => (
                                            <Select.Option value={BrandStatus[status as any]} key={status}>
                                                {status}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        )}
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Discountable">
                            {getFieldDecorator("discountable", {
                                initialValue: brand.discountable === true ? 1 : 0,
                                rules: [{ required: false }],
                            })(
                                <Select onChange={partial(set, brand, "discountable")}>
                                    {_.map(getEnumLabels(Discountable), status => (
                                        <Select.Option value={Discountable[status as any]} key={status}>
                                            {status}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Sale Status">
                            {getFieldDecorator("sales_status", {
                                initialValue: brand.sales_status,
                                rules: [{ required: false }],
                                normalize: (value: any) => {
                                    if (value === undefined) brand.sales_status = "";
                                    return value || null;
                                },
                            })(
                                <Select onChange={partial(set, brand, "sales_status")} allowClear={true}>
                                    {_.map(Object.keys(BrandSaleStatus) as Array<keyof typeof BrandSaleStatus>, key => (
                                        <Select.Option value={BrandSaleStatus[key]} key={key}>
                                            {BrandSaleStatus[key].replace("_", " ")}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Type">
                            {getFieldDecorator("type", {
                                initialValue: brand.type,
                                rules: [{ required: true }],
                            })(
                                <Select onChange={partial(set, brand, "type")}>
                                    {_.map(BrandType, type => (
                                        <Select.Option value={type} key={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Database">
                            {getFieldDecorator("db_config_id", {
                                initialValue: brand?.db_config_id || null,
                                rules: [{ required: false }],
                            })(
                                <Select onChange={partial(set, brand, "db_config_id")}>
                                    {_.map(brandFormStore.db_configs || [], config => (
                                        <Select.Option value={config.id} key={config.id}>
                                            {config.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Country">
                            <Select
                                showSearch
                                defaultValue={_.get(brand, "country")}
                                onChange={(value: any) => {
                                    set(brand, "country")(value);
                                    const country = CountriesAll.find(cA => cA.value === value);
                                    eSetValue(brand.subscription, "currency", country?.currency);
                                }}
                            >
                                {CountriesAll.map(country => (
                                    <Select.Option key={country.name} value={country.value}>
                                        {country.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Partner"}>
                            {getFieldDecorator("partner_id", {
                                initialValue: brand.partner_id,
                                rules: [{ required: true }],
                            })(
                                <Select
                                    placeholder="Select partner"
                                    showSearch
                                    onChange={brandFormStore.setPartnerId as any}
                                >
                                    {partners.map(c => (
                                        <Select.Option key={c.id} value={c.id}>
                                            {c.name} {c.parent ? `(P: ${c.parent.name})` : ""}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label={"Seller"}>
                            {getFieldDecorator("seller_id", {
                                initialValue: brand.seller_id,
                                rules: [{ required: false }],
                            })(
                                <Select
                                    placeholder="Select seller"
                                    showSearch
                                    onChange={brandFormStore.setSellerId as any}
                                >
                                    {sellers.map((c: any) => (
                                        <Select.Option key={c.id} value={c.id}>
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tags">
                            {getFieldDecorator("tags", {
                                initialValue: brand!.tags ? brand.tags.map(m => m.id) : undefined,
                                rules: [{ required: false }],
                            })(
                                <Select
                                    placeholder="Select tags"
                                    mode="multiple"
                                    allowClear
                                    onChange={brandFormStore.setTags as any}
                                >
                                    {brandFormStore.tags.map(tag => (
                                        <Select.Option key={tag.id} value={tag.id}>
                                            {tag.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {props.buttons()}
            </div>
        </Form>
    );
});

function clientLabel(): ReactNode {
    return (
        <>
            <span>Client</span>
            <a style={{ float: "right", marginLeft: 20 }} onClick={brandFormStore.onCreateClientClick}>
                Create New
            </a>
        </>
    );
}
